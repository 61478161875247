import React, { useState } from 'react';
import { motion } from 'framer-motion'; 

function Modal({ show, onClose, onSave }) {
  const [formData, setFormData] = useState({
    fullName: '',
    gender: '',
    dateOfBirth: '',
    mobileNumber: '',
    emailId: '',
    addressForCommunication: '',
    permanentAddress: '',
    profilePhoto: '',
    aadharCardNumber: '',
    nationality: '',
    languagesKnown: '',
    preferredJobLocation: '',
    service: '',
    agniveerScheme: false,
    dateOfJoining: '',
    dateOfRetirement: '',
    totalYrsOfService: '',
    lastRankInService: '',
    highestQualification: '',
    streamBranch: '',
    schoolCollegeUniversity: '',
    trainingsCertifications: '', // Assuming this is an array of strings
    preReleaseCourse: false,
    prcDetails: '',
    industrySuitability: '',
    industrySkills: '', // Assuming this is an array of strings
    primarySkills: '', // Assuming this is an array of strings
    secondarySkills: '', // Assuming this is an array of strings
    experience1: '',
    experience2: '',
    experience3: '',
    socialMediaProfiles: '',
    resume: '', 
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSave(formData); 
  };

  const modalVariants = {
    hidden: { opacity: 0, y: "-100vh", transition: { duration: 0.5 } },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <>
      {show && (
        <motion.div 
          initial="hidden"
          animate="visible"
          exit="hidden" 
          variants={modalVariants}
          className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
          <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-lg overflow-y-auto" style={{ maxHeight: '80vh' }}> 
            <div className="p-6">
              <h2 className="text-2xl font-bold mb-4">Add Veteran Data</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="fullName" className="block text-gray-700 text-sm font-bold mb-2">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="gender" className="block text-gray-700 text-sm font-bold mb-2">
                    Gender
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="">Select Gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="dateOfBirth" className="block text-gray-700 text-sm font-bold mb-2">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="mobileNumber" className="block text-gray-700 text-sm font-bold mb-2">
                    Mobile Number
                  </label>
                  <input
                    type="tel"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="emailId" className="block text-gray-700 text-sm font-bold mb-2">
                    Email Id
                  </label>
                  <input
                    type="email"
                    id="emailId"
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="addressForCommunication" className="block text-gray-700 text-sm font-bold mb-2">
                    Address for Communication
                  </label>
                  <textarea
                    id="addressForCommunication"
                    name="addressForCommunication"
                    value={formData.addressForCommunication}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="permanentAddress" className="block text-gray-700 text-sm font-bold mb-2">
                    Permanent Address
                  </label>
                  <textarea
                    id="permanentAddress"
                    name="permanentAddress"
                    value={formData.permanentAddress}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="profilePhoto" className="block text-gray-700 text-sm font-bold mb-2">
                    Profile Photo URL
                  </label>
                  <input
                    type="text"
                    id="profilePhoto"
                    name="profilePhoto"
                    value={formData.profilePhoto}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="aadharCardNumber" className="block text-gray-700 text-sm font-bold mb-2">
                    Aadhar Card Number
                  </label>
                  <input
                    type="text"
                    id="aadharCardNumber"
                    name="aadharCardNumber"
                    value={formData.aadharCardNumber}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="nationality" className="block text-gray-700 text-sm font-bold mb-2">
                    Nationality
                  </label>
                  <input
                    type="text"
                    id="nationality"
                    name="nationality"
                    value={formData.nationality}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="languagesKnown" className="block text-gray-700 text-sm font-bold mb-2">
                    Languages Known
                  </label>
                  <input
                    type="text"
                    id="languagesKnown"
                    name="languagesKnown"
                    value={formData.languagesKnown}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="preferredJobLocation" className="block text-gray-700 text-sm font-bold mb-2">
                    Preferred Job Location
                  </label>
                  <input
                    type="text"
                    id="preferredJobLocation"
                    name="preferredJobLocation"
                    value={formData.preferredJobLocation}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="service" className="block text-gray-700 text-sm font-bold mb-2">
                    Service
                  </label>
                  <select
                    id="service"
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="">Select Service</option>
                    <option value="Indian Air Force">Indian Air Force</option>
                    <option value="Indian Navy">Indian Navy</option>
                    <option value="Indian Army">Indian Army</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="agniveerScheme" className="block text-gray-700 text-sm font-bold mb-2">
                    Agniveer Scheme?
                  </label>
                  <select
                    id="agniveerScheme"
                    name="agniveerScheme"
                    value={formData.agniveerScheme}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="">Select Yes or No</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    
                  </select>
                  
                </div>
                <div className="mb-4">
                  <label htmlFor="dateOfJoining" className="block text-gray-700 text-sm font-bold mb-2">
                    Date of Joining
                  </label>
                  <input
                    type="date"
                    placeholder="dd-mm-yyyy" 
                    id="dateOfJoining"
                    name="dateOfJoining"
                    value={formData.dateOfJoining}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="dateOfRetirement" className="block text-gray-700 text-sm font-bold mb-2">
                    Date of Retirement
                  </label>
                  <input
                    type="date"
                    id="dateOfRetirement"
                    name="dateOfRetirement"
                    value={formData.dateOfRetirement}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="totalYrsOfService" className="block text-gray-700 text-sm font-bold mb-2">
                    Total Years of Service
                  </label>
                  <input
                    type="number"
                    id="totalYrsOfService"
                    name="totalYrsOfService"
                    value={formData.totalYrsOfService}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="lastRankInService" className="block text-gray-700 text-sm font-bold mb-2">
                    Last Rank in Service
                  </label>
                  <input
                    type="text"
                    id="lastRankInService"
                    name="lastRankInService"
                    value={formData.lastRankInService}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="highestQualification" className="block text-gray-700 text-sm font-bold mb-2">
                    Highest Qualification
                  </label>
                  <input
                    type="text"
                    id="highestQualification"
                    name="highestQualification"
                    value={formData.highestQualification}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="streamBranch" className="block text-gray-700 text-sm font-bold mb-2">
                    Stream / Branch
                  </label>
                  <input
                    type="text"
                    id="streamBranch"
                    name="streamBranch"
                    value={formData.streamBranch}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="schoolCollegeUniversity" className="block text-gray-700 text-sm font-bold mb-2">
                    School / College / University
                  </label>
                  <input
                    type="text"
                    id="schoolCollegeUniversity"
                    name="schoolCollegeUniversity"
                    value={formData.schoolCollegeUniversity}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="trainingsCertifications" className="block text-gray-700 text-sm font-bold mb-2">
                    Trainings & Certifications while in Service
                  </label>
                  <textarea
                    id="trainingsCertifications"
                    name="trainingsCertifications"
                    value={formData.trainingsCertifications}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="preReleaseCourse" className="block text-gray-700 text-sm font-bold mb-2">
                    Pre-Release Course (PRC) completed
                  </label>
                  <select
                     id="preReleaseCourse"
                    name="preReleaseCourse"
                    value={formData.preReleaseCourse}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="">Select Yes or No</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    
                  </select>
                  
                </div>
                <div className="mb-4">
                  <label htmlFor="prcDetails" className="block text-gray-700 text-sm font-bold mb-2">
                    PRC Details
                  </label>
                  <textarea
                    id="prcDetails"
                    name="prcDetails"
                    value={formData.prcDetails}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="industrySuitability" className="block text-gray-700 text-sm font-bold mb-2">
                    Industry Suitability
                  </label>
                  <select
                    id="industrySuitability"
                    name="industrySuitability"
                    value={formData.industrySuitability}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="">Select Industry Suitability</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Health Care">Health Care</option>
                    <option value="Information Technology">Information Technology</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="industrySkills" className="block text-gray-700 text-sm font-bold mb-2">
                    Industry Skills
                  </label>
                  <textarea
                    id="industrySkills"
                    name="industrySkills"
                    value={formData.industrySkills}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="primarySkills" className="block text-gray-700 text-sm font-bold mb-2">
                    Primary Skills
                  </label>
                  <textarea
                    id="primarySkills"
                    name="primarySkills"
                    value={formData.primarySkills}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="secondarySkills" className="block text-gray-700 text-sm font-bold mb-2">
                    Secondary Skills
                  </label>
                  <textarea
                    id="secondarySkills"
                    name="secondarySkills"
                    value={formData.secondarySkills}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="experience1" className="block text-gray-700 text-sm font-bold mb-2">
                    Experience 1
                  </label>
                  <textarea
                    id="experience1"
                    name="experience1"
                    value={formData.experience1}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="experience2" className="block text-gray-700 text-sm font-bold mb-2">
                    Experience 2
                  </label>
                  <textarea
                    id="experience2"
                    name="experience2"
                    value={formData.experience2}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="experience3" className="block text-gray-700 text-sm font-bold mb-2">
                    Experience 3
                  </label>
                  <textarea
                    id="experience3"
                    name="experience3"
                    value={formData.experience3}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="socialMediaProfiles" className="block text-gray-700 text-sm font-bold mb-2">
                    Social Media Profiles (Optional)
                  </label>
                  <input
                    type="text"
                    id="socialMediaProfiles"
                    name="socialMediaProfiles"
                    value={formData.socialMediaProfiles}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="resume" className="block text-gray-700 text-sm font-bold mb-2">
                    Resume (Paste or Upload)
                  </label>
                  <textarea
                    id="resume"
                    name="resume"
                    value={formData.resume}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                <div className="flex items-center justify-end mt-4">
                  <button
                    onClick={onClose}
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-4"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
}

export default Modal;