import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingPage from "./LoadingPage"; 


function Signup() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState("Recruiter");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true); 

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API_URL}/signup`, {
        username,
        email,
        password,
        userType,
      });
      setIsLoading(false); 
      toast.success("Successfully Registered!"); 
      navigate("/"); 
    } catch (error) {
      setIsLoading(false); 
      console.error("Signup error:", error);
      setError(error.response.data.message || "Error creating user");
    }
  };



return (
    <div className="flex min-h-screen bg-gradient-to-r from-green-500 to-pink-500">
      <div className="hidden md:block w-1/2 bg-cover bg-center" >
        <div className="bg-gray-900 bg-opacity-50 flex items-center justify-center h-full">
          <div className="text-center text-white px-4">
            <h2 className="text-3xl font-bold mb-4">
              Join Our Yoddah
            </h2>
            <p className="text-lg">
            comprehensive portal designed to facilitate the re-settlement and transition of veterans into the corporate world, helping them embark on their second innings as civilians
            </p>
          </div>
        </div>
      </div>

      {/* Right Side (Signup Form) */}   
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 px-8 py-6 mx-4 mt-4 text-left bg-white shadow-lg rounded-lg">
        {isLoading && <LoadingPage />} {/* Show loading spinner */}
        {!isLoading && (
          <>
            <h3 className="text-2xl font-bold text-gray-900 mb-6">Signup</h3>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <form onSubmit={handleSubmit} className="w-full max-w-md">
            {/* <h3 className="text-2xl font-bold text-gray-900 mb-6">Signup</h3> */}

{/* <form onSubmit={handleSubmit}> */}
  <div className="mb-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="username"
    >
      Username
    </label>
    <input
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      id="username"
      type="text"
      value={username}
      onChange={(e) => setUsername(e.target.value)}
      required
    />
  </div>
  <div className="mb-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="username"
    >
      Email ID
    </label>
    <input
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      id="email"
      type="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      required
    />
  </div>

  <div className="mb-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="password"
    >
      Password
    </label>
    <input
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      id="password"
      type="password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      required
    />
  </div>

  <div className="mb-6">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="userType"
    >
      User Type
    </label>
    <select
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      id="userType"
      value={userType}
      onChange={(e) => setUserType(e.target.value)}
      required
    >
      {/* <option value="Candidate">Candidate</option> */}
      <option value="Recruiter">Recruiter</option>
    </select>
  </div>

  <div className="flex items-center justify-between">
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      type="submit"
    >
      Signup
    </button>
    <button
        className="bg-white hover:bg-gray-100  hover:text-indigo-700 text-black w-50 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        onClick={()=>{navigate('/')}}
      >
        Already Having Account? Login.
      </button>
  </div>
</form>
            {/* </form> */}
          </>
        )}
      </div>
    </div>
  );
}

export default Signup;