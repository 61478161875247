// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import {
//   DownloadOutlined,
//   Email,
//   PointOfSale,
//   PersonAdd,
//   Traffic,
// } from '@mui/icons-material';
// import { Box, Button, Typography } from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';
// import BreakdownChart from './components/BreakdownChart';
// import OverviewChart from './components/OverviewChart';
// import StatBox from './components/StatBox';
// import { useGetDashboardQuery } from '@state/api';
// // import Sidebar from '@components/Sidebar'; // Import the Sidebar component

// function Home() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const storedToken = sessionStorage.getItem('token');
//     if (storedToken) {
//       setIsAuthenticated(true);
//     }
//     setIsLoading(false);
//   }, []);

//   const { data, isLoading: dashboardLoading } = useGetDashboardQuery();
//   console.log('Dashboard:', data);

//   const columns = [
//     {
//       field: '_id',
//       headerName: 'ID',
//       flex: 1,
//     },
//     {
//       field: 'userId',
//       headerName: 'User ID',
//       flex: 1,
//     },

//     {
//       field: 'createdAt',
//       headerName: 'CreatedAt',
//       flex: 1,
//     },

//     {
//       field: 'products',
//       headerName: '# of Products',
//       flex: 0.5,
//       sortable: false,
//       renderCell: (params) => params.value.length,
//     },
//     {
//       field: 'cost',
//       headerName: 'Cost',
//       flex: 1,
//       renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
//     },
//   ];

//   return (
//     <div className="flex h-screen bg-gray-100"> {/* Add bg-gray-100 to the main container */}
//       {/* <Sidebar /> */}
//       <div className="flex-1 overflow-y-auto p-4"> {/* Add overflow-y-auto to the main content area */}
//         {isLoading && <Loading />}
//         <div className="flex justify-between items-center mb-4">
//           <h1 className="text-3xl font-bold">DASHBOARD</h1>
//           <Typography variant="h6" color="gray.500">
//             Welcome to your dashboard
//           </Typography>
//           <Button
//             variant="contained"
//             className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//           >
//             <DownloadOutlined sx={{ mr: '10px' }} />
//             Download Reports
//           </Button>
//         </div>

//         {/* Main Dashboard Content */}
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
//           {/* Row 1 */}
//           <StatBox
//             title="Total Customers"
//             value={data && data.totalCustomers}
//             increase="+14%"
//             description="Since last month"
//             icon={<Email className="text-blue-500" />}
//           />
//           <StatBox
//             title="Sales Today"
//             value={data && data.todayStats.totalSales}
//             increase="+21%"
//             description="Since last month"
//             icon={<PointOfSale className="text-blue-500" />}
//           />
//           <StatBox
//             title="Monthly Sales"
//             value={data && data.thisMonthStats.totalSales}
//             increase="+5%"
//             description="Since last month"
//             icon={<PersonAdd className="text-blue-500" />}
//           />
//           <StatBox
//             title="Yearly Sales"
//             value={data && data.yearlySalesTotal}
//             increase="+43%"
//             description="Since last month"
//             icon={<Traffic className="text-blue-500" />}
//           />

//           {/* Row 2 */}
//           <div className="col-span-8 md:col-span-12 lg:col-span-8 row-span-3 bg-white rounded-lg shadow-md p-4">
//             <OverviewChart view="sales" isDashboard={true} />
//           </div>

//           <div className="col-span-4 md:col-span-12 lg:col-span-4 row-span-3 bg-white rounded-lg shadow-md p-4">
//             <h2 className="text-xl font-bold mb-4 text-gray-700">
//               Sales By Category
//             </h2>
//             <BreakdownChart isDashboard={true} />
//             <Typography
//               className="text-gray-500 text-sm mt-2"
//             >
//               Breakdown of real states and information via category for revenue
//               made for this year and total sales.
//             </Typography>
//           </div>

//           {/* Row 3 (DataGrid) */}
//           <div className="col-span-8 md:col-span-12 lg:col-span-8 row-span-3 bg-white rounded-lg shadow-md p-4">
//             <DataGrid
//               loading={dashboardLoading || !data}
//               getRowId={(row) => row._id}
//               rows={(data && data.transactions) || []}
//               columns={columns}
//               className="rounded-lg"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Home;


// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// // import { DownloadOutlined } from '@mui/icons-material';
// // import Sidebar from './components/Sidebar'; // Import the Sidebar component
// import Loading from '../LoadingPage'; // Import Loading component

// function Home() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const storedToken = sessionStorage.getItem('authToken');
//     if (storedToken) {
//       setIsAuthenticated(true);
//     }
//     setIsLoading(false);
//   }, []);
//   const data = {};

//   const { data, isLoading: dashboardLoading } = useState(false);
//   console.log('Dashboard:', data);

//   const columns = [
//     {
//       field: '_id',
//       headerName: 'ID',
//       flex: 1,
//     },
//     {
//       field: 'userId',
//       headerName: 'User ID',
//       flex: 1,
//     },

//     {
//       field: 'createdAt',
//       headerName: 'CreatedAt',
//       flex: 1,
//     },

//     {
//       field: 'products',
//       headerName: '# of Products',
//       flex: 0.5,
//       sortable: false,
//       renderCell: (params) => params.value.length,
//     },
//     {
//       field: 'cost',
//       headerName: 'Cost',
//       flex: 1,
//       renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
//     },
//   ];

//   return (
//     <div className="flex h-screen bg-gray-100"> 
//       {/* <Sidebar /> */}
//       <div className="flex-1 overflow-y-auto p-4"> 
//         {isLoading && <Loading />}
//         <div className="flex justify-between items-center mb-4">
//           <h1 className="text-3xl font-bold">DASHBOARD</h1>
//           <p className="text-gray-500">Welcome to your dashboard</p>
//           <button
//             className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//           >
//             {/* <DownloadOutlined className="mr-2" /> */}
//             Download Reports
//           </button>
//         </div>

//         {/* Main Dashboard Content */}
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
//           {/* Row 1 */}
//           <div className="bg-white rounded-lg shadow-md p-4">
//             <h2 className="text-xl font-bold mb-2 text-gray-700">Total Customers</h2>
//             <p className="text-2xl font-bold">{data && data.totalCustomers}</p>
//             <p className="text-gray-500 text-sm mt-2">+14% Since last month</p>
//           </div>
//           <div className="bg-white rounded-lg shadow-md p-4">
//             <h2 className="text-xl font-bold mb-2 text-gray-700">Sales Today</h2>
//             <p className="text-2xl font-bold">{data && data.todayStats.totalSales}</p>
//             <p className="text-gray-500 text-sm mt-2">+21% Since last month</p>
//           </div>
//           <div className="bg-white rounded-lg shadow-md p-4">
//             <h2 className="text-xl font-bold mb-2 text-gray-700">Monthly Sales</h2>
//             <p className="text-2xl font-bold">{data && data.thisMonthStats.totalSales}</p>
//             <p className="text-gray-500 text-sm mt-2">+5% Since last month</p>
//           </div>
//           <div className="bg-white rounded-lg shadow-md p-4">
//             <h2 className="text-xl font-bold mb-2 text-gray-700">Yearly Sales</h2>
//             <p className="text-2xl font-bold">{data && data.yearlySalesTotal}</p>
//             <p className="text-gray-500 text-sm mt-2">+43% Since last month</p>
//           </div>

//           {/* Row 2 */}
//           <div className="col-span-8 md:col-span-12 lg:col-span-8 row-span-3 bg-white rounded-lg shadow-md p-4">
//             {/* <OverviewChart view="sales" isDashboard={true} /> */}
//           </div>

//           <div className="col-span-4 md:col-span-12 lg:col-span-4 row-span-3 bg-white rounded-lg shadow-md p-4">
//             <h2 className="text-xl font-bold mb-4 text-gray-700">
//               Sales By Category
//             </h2>
//             {/* <BreakdownChart isDashboard={true} /> */}
//             <p
//               className="text-gray-500 text-sm mt-2"
//             >
//               Breakdown of real states and information via category for revenue
//               made for this year and total sales.
//             </p>
//           </div>

//           {/* Row 3 (DataGrid) */}
//           <div className="col-span-8 md:col-span-12 lg:col-span-8 row-span-3 bg-white rounded-lg shadow-md p-4">
//             <div className="bg-white shadow-md rounded-lg p-4">
//               <h2 className="text-xl font-bold mb-4">Recent Transactions</h2>
//               <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
//                 <thead>
//                   <tr>
//                     <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                       ID
//                     </th>
//                     <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                       User ID
//                     </th>
//                     <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                       CreatedAt
//                     </th>
//                     <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                       # of Products
//                     </th>
//                     <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                       Cost
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {(data && data.transactions  || []).map((transaction) => (
//                     <tr key={transaction._id}>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
//                         {transaction._id}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         {transaction.userId}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         {transaction.createdAt}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         {transaction.products.length}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         {`$${Number(transaction.cost).toFixed(2)}`}
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Home;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineDownload } from "react-icons/md";// import Sidebar from '@components/Sidebar';
import Loading from '../LoadingPage';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Home({isSidebarOpen}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [veteransCount, setVeteransCount] = useState(0);
  const [candidatesCount, setCandidatesCount] = useState(0);
  const [veteransData, setVeteransData] = useState([]); // For the chart
  const [candidatesData, setCandidatesData] = useState([]); // For the chart
  const navigate = useNavigate();

  const handleDownloadReport = () => {

  }

  useEffect(() => {
    const storedToken = sessionStorage.getItem('token');
    if (storedToken) {
      setIsAuthenticated(true);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const veteransResponse = await fetch(`${process.env.REACT_APP_SERVER_API_URL}/veterans/count`);
        const candidatesResponse = await fetch(`${process.env.REACT_APP_SERVER_API_URL}/candidates/count`);
        const veteransChartDataResponse = await fetch(`${process.env.REACT_APP_SERVER_API_URL}/veterans/chart-data`);
        const candidatesChartDataResponse = await fetch(`${process.env.REACT_APP_SERVER_API_URL}/candidates/chart-data`);

        if (
          veteransResponse.ok &&
          candidatesResponse.ok &&
          veteransChartDataResponse.ok &&
          candidatesChartDataResponse.ok
        ) {
          const veteransData = await veteransChartDataResponse.json();
          const candidatesData = await candidatesChartDataResponse.json();
          const veteransCount = await veteransResponse.json();
          const candidatesCount = await candidatesResponse.json();
          setVeteransCount(veteransCount.count);
          setCandidatesCount(candidatesCount.count);
          setVeteransData(veteransData);
          setCandidatesData(candidatesData);
        } else {
          console.error(
            'Error fetching data:',
            veteransResponse.status,
            candidatesResponse.status,
            veteransChartDataResponse.status,
            candidatesChartDataResponse.status
          );
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Chart Data
  const chartData = {
    labels: veteransData.map((item) => item.month),
    datasets: [
      {
        label: 'Veterans',
        data: veteransData.map((item) => item.count),
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Candidates',
        data: candidatesData.map((item) => item.count),
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Monthly Veterans and Candidates Count',
      },
    },
  };

  return (
    <div className={`flex  bg-white ${
      isSidebarOpen? "ml-0 h-full" : "ml-0 "
    }`}> 
      {/* <Sidebar /> */}
      <div className="flex-1 overflow-y-auto p-4"> 
        {isLoading && <Loading />}
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-bold">DASHBOARD</h1>
          <p className="text-black font-bold">
            Welcome <span className='text-green-600 font-bold'>{sessionStorage.getItem('username')}</span>
            </p>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex"
            onClick={handleDownloadReport}
          >
            <MdOutlineDownload size={30} className="mr-2" /> Download Reports
          </button>
        </div>

        {/* Main Dashboard Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Row 1 */}
          <div className="bg-white rounded-lg shadow-xl p-4">
            <h2 className="text-xl font-bold mb-2 text-gray-700">Total Veterans</h2>
            <p className="text-2xl font-bold">{veteransCount}</p>
          </div>
          <div className="bg-white rounded-lg shadow-xl p-4">
            <h2 className="text-xl font-bold mb-2 text-gray-700">Total Candidates</h2>
            <p className="text-2xl font-bold">{candidatesCount}</p>
          </div>
          {/* ... Add other stat boxes as needed ... */}
        </div>

        {/* Row 2 (Chart) */}
        <div className="bg-white rounded-lg shadow-xl p-4 mt-4">
          <h2 className="text-xl font-bold mb-4 text-gray-700">
            Monthly Data
          </h2>
          <Bar data={chartData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
}

export default Home;

