// import React from 'react'

// function ExperiencePreview({resumeInfo}) {
//   return (
//     <div className='my-2'>
//         <h2 className='text-center font-bold text-sm mb-2'
//         style={{
//             color:resumeInfo?.themeColor
//         }}
//         >Professional Experience</h2>
//         <hr style={{
//             borderColor:resumeInfo?.themeColor
//         }} />

//         {resumeInfo?.Experience?.map((experience,index)=>(
//             <div key={index} className='my-5'>
//                 <h2 className='text-sm font-bold'
//                  style={{
//                     color:resumeInfo?.themeColor
//                 }}>{experience?.title}</h2>
//                 <h2 className='text-xs flex justify-between italic'>{experience?.companyName},{' '}
//                 {experience?.city},{' '}
//                 {experience?.state}
//                 <span>{experience?.startDate} To {experience?.currentlyWorking?'Present':experience.endDate} </span>
//                 </h2>
//                 {/* <p className='text-xs my-2'>
//                     {experience.workSummery}
//                 </p> */}
//                 <div className='text-xs my-2 justify-evenly' dangerouslySetInnerHTML={{__html:experience?.workSummery}} />
//             </div>
//         ))}
//     </div>
//   )
// }

// export default ExperiencePreview


// import React from 'react';

// function ExperiencePreview({ resumeInfo }) {
//     const sortedExperience = resumeInfo?.Experience?.slice().sort((a, b) => {
//         const dateA = new Date(a.currentlyWorking ? new Date() : a.endDate);
//         const dateB = new Date(b.currentlyWorking ? new Date() : b.endDate);
//         return dateB - dateA;
//     });

//     return (
//         <div className='my-2'>
//             <h2 className='text-center font-bold text-sm mb-2'
//                 style={{
//                     color: 'Black'
//                 }}
//             >Professional Experience</h2>
//             <hr className='h-px bg-gray-200 border-0 dark:bg-gray-700' style={{
//                 borderColor: 'Black'
//             }} />

//             {sortedExperience?.map((experience, index) => (
//                 <div key={index} className='my-5'>
//                     <h2 className='text-sm font-bold'
//                         style={{
//                             color: 'Black'
//                         }}>{experience?.title}</h2>
//                     <h2 className='text-xs flex justify-between italic'>{experience?.companyName},{' '}
//                         {experience?.city},{' '}
//                         {experience?.state}
//                         <span>{experience?.startDate} To {experience?.currentlyWorking ? 'Present' : experience.endDate} </span>
//                     </h2>
//                     <div className='text-xs my-2 text-justify' dangerouslySetInnerHTML={{ __html: experience.workSummary }} />
//                 </div>
//             ))}
//         </div>
//     );
// }

// export default ExperiencePreview;

import React from 'react';

function ExperiencePreview({ resumeInfo }) {
    const sortedExperience = resumeInfo?.Experience?.slice().sort((a, b) => {
        const dateA = new Date(a.currentlyWorking ? new Date() : a.endDate);
        const dateB = new Date(b.currentlyWorking ? new Date() : b.endDate);
        return dateB - dateA;
    });

    return (
        <div className='my-2'>
            <h2 className='text-center font-bold text-sm mb-2'
                style={{
                    color: resumeInfo?.themeColor
                }}
            >Professional Experience</h2>
            <hr style={{
                // borderColor: resumeInfo?.themeColor
                 borderColor: "black"
            }} />

            {sortedExperience?.map((experience, index) => (
                <div key={index} className='my-5'>
                    <h2 className='text-sm font-bold'
                        style={{
                            color: resumeInfo?.themeColor
                        }}>{experience?.title}</h2>
                    <h2 className='text-xs flex justify-between italic'>{experience?.companyName},{' '}
                        {experience?.city},{' '}
                        {experience?.state}
                        <span>{experience?.startDate} To {experience?.currentlyWorking ? 'Present' : experience.endDate} </span>
                    </h2>
                    <div className='text-xs my-2 justify-evenly' dangerouslySetInnerHTML={{ __html: experience?.workSummary }} />
                </div>
            ))}
        </div>
    );
}

export default ExperiencePreview;
