import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'; // Import icons
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs'; // Import icons
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingPage from '../LoadingPage'

function CandidatesDet({isSidebarOpen}) {
  const [candidates, setCandidates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20); // Adjust as needed
  const [showFullDetails, setShowFullDetails] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const response = await fetch('/candidates'); // Update API endpoint
        if (response.ok) {
          const data = await response.json();
          setCandidates(data);
        } else {
          console.error('Error fetching candidates:', response.status);
        }
      } catch (error) {
        console.error('Error fetching candidates:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCandidates();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleShowAllClick = (candidate) => {
    setSelectedCandidate(candidate);
    setShowFullDetails(true);
  };

  const handleCloseModal = () => {
    setShowFullDetails(false);
  };

  const handleDeleteConfirmation = () => {
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteCandidate = async () => {
    try {
      setIsDeleting(true); // Show loading indicator

      const response = await fetch(`${process.env.REACT_APP_SERVER_API_URL}/candidates/${selectedCandidate._id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const updatedCandidates = candidates.filter(
          (candidate) => candidate._id !== selectedCandidate._id
        );
        setCandidates(updatedCandidates);
        setSelectedCandidate(null);
        setShowDeleteModal(false);
        toast.success('Candidate deleted successfully!');
      } else {
        console.error('Error deleting candidate:', response.status);
        toast.error('Error deleting candidate. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting candidate:', error);
      toast.error('Error deleting candidate. Please try again.');
    } finally {
      setIsDeleting(false);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = candidates.slice(indexOfFirstItem, indexOfLastItem);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(candidates.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex h-screen bg-white">
      <div className={` bg-white text-white shadow-md p-4  transition-all duration-100 ease-in-out fixed top-0 h-screen ${isSidebarOpen? "left-64 w-49" : "left-20"
      }`}>
        <div className="mb-4">
          <h2 className="text-xl font-bold text-black">Advanced Filters</h2>
        </div>
        <div className="mb-4">
          <label htmlFor="keyword" className="block text-gray-700 font-bold mb-2">
            Keyword
          </label>
          <input
            type="text"
            id="keyword"
            value={searchTerm}
            onChange={handleSearch}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Type a Keyword"
          />
          <p className="text-gray-600 text-xs mt-1">
            Multiple keywords separated by comma or enter key
          </p>
          <div className="mt-2">
            <input type="checkbox" id="applyBoolean" />
            <label htmlFor="applyBoolean" className="ml-2 text-gray-700">
              Apply Boolean
            </label>
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="excludeKeywords" className="block text-gray-700 font-bold mb-2">
            Exclude Keywords
          </label>
          <input
            type="text"
            id="excludeKeywords"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Type a Keyword"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="remoteWork" className="block text-gray-700 font-bold mb-2">
            Looking for Remote Work
          </label>
          <input type="checkbox" id="remoteWork" />
        </div>
        <div className="mb-4">
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="Professional">Professional</option>
            <option value="Education">Education</option>
            <option value="Personal Info">Personal Info</option>
            <option value="Salary">Salary</option>
          </select>
        </div>
      </div>
      <div className={` bg-white flex-1 p-4 top-0  transition-all duration-200 ease-in-out overflow-y-auto 
       ${isSidebarOpen? "ml-80" : "ml-64"
       }`} 
      >
      {/* <div className={`flex-1 p-4 top-0  transition-all duration-300 ease-in-out overflow-y-auto 
       `} 
      > */}
        {isLoading && <LoadingPage />}

        <div className="flex justify-between mb-4">
          <h1 className="text-3xl font-bold">Candidates</h1>
          <p className="text-gray-500">
            Total {candidates.length} Found
          </p>
          <button
            // onClick={() => navigate('/candidates/add')}
            onClick={() => console.log("Add Ne Candidate Clicked")}
            className= "flex bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded items-center"
          >
            <AiOutlinePlusCircle className="mr-2" /> Add New Candidate
          </button>
        </div>

        <div className="flex items-center mb-4">
          <div className="flex-1">
            <input
              type="text"
              placeholder="Type here to search"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={handleSearch}
            />
          </div>
          <div>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="Recently Modified">Sort By: Recently Modified</option>
            </select>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Role
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contact
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Location
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Consent Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Source
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Skills
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((candidate) => (
                <tr key={candidate._id} className="hover:bg-gray-100">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {candidate._id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {candidate.firstName} {candidate.lastName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {candidate.role}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {candidate.phone} <br />
                    {candidate.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {candidate.currentLocation}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    NA
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    Candidate Apply / LinkedIn
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {candidate.skills.slice(0, 3).join(', ')}
                    {candidate.skills.length > 3 && (
                      <span className="text-gray-500 font-bold"> ...</span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleShowAllClick(candidate)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      >
                        <FaRegEye className="mr-1" />
                        View
                      </button>
                      <button
                        onClick={() => navigate(`/candidates/edit/${candidate._id}`)}
                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                      >
                        <FaEdit className="mr-1" />
                        Edit
                      </button>
                      <button
                        onClick={handleDeleteConfirmation}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      >
                        <FaTrash className="mr-1" />
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4 flex justify-between items-center">
          <div className="text-gray-600">
            Showing{' '}
            {indexOfFirstItem + 1} - {indexOfLastItem} of {candidates.length}{' '}
            results
          </div>
          <div className="flex space-x-2">
            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => handlePageChange(number)}
                className={`py-2 px-4 rounded-md  text-gray-500 hover:bg-gray-100 ${
                  currentPage === number && 'bg-gray-100'
                }`}
              >
                {number}
              </button>
            ))}
          </div>
        </div>

        {/* Modal for delete confirmation */}
        <div
          className={`fixed top-0 left-0 right-0 z-50 items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full ${
            showDeleteModal ? 'block' : 'hidden'
          }`}
        >
          <div className="relative w-full max-w-2xl max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-100">
              {/* Modal header */}
              <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-grey">
                  Delete Candidate
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={handleCancelDelete}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-6 space-y-6">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  Are you sure you want to delete this candidate?
                </p>
              </div>
              {/* Modal footer */}
              <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-700 dark:focus:ring-red-800"
                  onClick={handleCancelDelete}
                >
                  Cancel
                </button>
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={handleDeleteCandidate}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Loading Screen while deleting */}
        {isDeleting && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
            <div className="bg-white p-4 rounded-md shadow-md">
              <div className="flex items-center">
                <svg
                  className="animate-spin h-5 w-5 mr-3"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0C0 5.373 5.373 0 12 0v4m8 11.451c0 1.291-.873 2.403-2.04 2.857H7A2 2 0 015 18c-1.291 0-2.403-.873-2.857-2.04H3M11 7h2m-4 0h2"
                  ></path>
                </svg>
                <span className="text-green-500 font-bold">
                  Deleting...
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CandidatesDet;