
// import React from "react";
// import { Outlet, Link } from "react-router-dom"; // Import Link for routing

// function Dashboard({ userType, onLogout }) {
//   return (
//     <div className="flex h-screen"> {/* Set h-screen for full height */}
//       <div className="bg-gray-800 w-64 fixed top-0 left-0 h-full flex flex-col"> 
//         {/* Sidebar */}
//         <div className="p-4">
//           <h2 className="text-white text-xl font-bold mb-4">
//             {userType} Dashboard
//           </h2>
//           <ul className="space-y-2 flex-1"> {/* Make the list take up the remaining space */}
//             <li>
//               <Link to="/dashboard" className="block text-gray-300 hover:text-white rounded-md px-3 py-2">
//                 Home
//               </Link>
//             </li>
//             <li>
//               <Link to="/dashboard/resume-parser" className="block text-gray-300 hover:text-white rounded-md px-3 py-2">
//                 Resume Parser
//               </Link>
//             </li>
//             <li>
//               <Link to="/dashboard/resume-builder" className="block text-gray-300 hover:text-white rounded-md px-3 py-2">
//                 Resume Builder
//               </Link>
//             </li>
//             <li>
//               <Link to="/dashboard/profile" className="block text-gray-300 hover:text-white rounded-md px-3 py-2">
//                 Profile
//               </Link>
//             </li>
//           </ul>
//           <button
//             className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-auto"
//             onClick={onLogout}
//           >
//             Logout
//           </button>
//         </div>
//       </div>
//       <div className="flex-1 p-4 ml-64"> {/* Push the main content to the right */}
//         {/* Dashboard Content */}
//         <Outlet /> {/* Render the child route component */}
//       </div>
//     </div>
//   );
// }

// export default Dashboard;

// import React, { useState } from "react";
// import { Outlet, Link } from "react-router-dom";
// import {
//   MdHome,
//   MdOutlineDescription,
//   MdOutlineBuild,
//   MdOutlineAccountCircle,
// } from "react-icons/md"; // Import icons from react-icons
// import { RxDashboard } from "react-icons/rx";


// function Dashboard({ userType, onLogout }) {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State for toggle

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   return (
//     <div className="flex h-screen bg-gray-100">
//       <div
//         className={`bg-gray-800 w-64 fixed top-0 left-0 h-full flex flex-col transition-all duration-300 ease-in-out ${
//           isSidebarOpen ? "w-64" : "w-20 " // Adjust the closed width
//         }`} // Use translate-x for sliding
//       >
//         <div className="p-4 flex items-center justify-between">
//           {isSidebarOpen && (
//             <h2 className="text-white text-xl font-bold mb-4">
//               {userType} Dashboard
//             </h2>
//           )}
//           <button onClick={toggleSidebar} className="text-white">
//             {isSidebarOpen ? (
//               <RxDashboard size={24} />
//             ) : (
//               <RxDashboard size={24} />
//             )}
//           </button>
//         </div>
//         <ul className="space-y-2 flex-1 px-4">
//           <li>
//             <Link
//               to="/dashboard"
//               className="block text-gray-300 hover:text-white rounded-md px-3 py-2 flex items-center"
//             >
//               <MdHome size={20} className="mr-2" />
//               {isSidebarOpen && "Home"}
//             </Link>
//           </li>
//           <li>
//             <Link
//               to="/dashboard/resume-parser"
//               className="block text-gray-300 hover:text-white rounded-md px-3 py-2 flex items-center"
//             >
//               <MdOutlineDescription size={20} className="mr-2" />
//               {isSidebarOpen && "Resume Parser"}
//             </Link>
//           </li>
//           <li>
//             <Link
//               to="/dashboard/resume-builder"
//               className="block text-gray-300 hover:text-white rounded-md px-3 py-2 flex items-center"
//             >
//               <MdOutlineBuild size={20} className="mr-2" />
//               {isSidebarOpen && "Resume Builder"}
//             </Link>
//           </li>
//           <li>
//             <Link
//               to="/dashboard/profile"
//               className="block text-gray-300 hover:text-white rounded-md px-3 py-2 flex items-center"
//             >
//               <MdOutlineAccountCircle size={20} className="mr-2" />
//               {isSidebarOpen && "Profile"}
//             </Link>
//           </li>
//         </ul>
//         <button
//           className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-auto px-4"
//           onClick={onLogout}
//         >
//           Logout
//         </button>
//       </div>
//       <div className="flex-1 p-4 ml-64">
//         <Outlet />
//       </div>
//     </div>
//   );


// }

// export default Dashboard;


import React, { useState, useContext } from "react";
import {
  MdHome,
  MdOutlineDescription,
  MdOutlineBuild,
  MdOutlineAccountCircle,
  MdTableView,
} from "react-icons/md";
import ResumeParser from "./ResumeParser/ResumeParser";
import BulkResumeParser from "./ResumeParser/BulkResumeParser";
import BulkParser from "./ResumeParser/BulkParser";
import ResumeBuilder from "./ResumeBuilder/ResumeBuilder";
import Candidates from "./Candidates/Candidates";
import Profile from "./Profile/Profile";
import { FiMenu } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import Veterans from "./Veterans/Veterans";
import Home from "./Home/Home";
import { GrMultiple } from "react-icons/gr";
import { FaUserTie } from "react-icons/fa";
import { FaPersonMilitaryRifle } from "react-icons/fa6";
import Report from './Report/Report';
import { TbReportAnalytics } from "react-icons/tb";
import LoadingPage from './LoadingPage'
import CandidatesDet from './Candidates/CandidateDet'
import CandidateTable from "./Table/CandidateTable";




function Dashboard({ userType, username, onLogout }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [currentView, setCurrentView] = useState("home"); // State to manage current view
  const [isLoading, setIsLoading] = useState(false); 
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // const handleViewChange = (view) => {
  //   // try{
  //     setCurrentView(view);
  //   //   setTimeout(() => {
  //   //     setIsLoading(true);
  //   //   }, 2000); // 3000ms = 3 seconds
  //   // }finally{
  //   //   setIsLoading(false);
  //   // }
  // };
  const handleViewChange = (view) => {
      setCurrentView(view);
  };

  return (
    <div className="container flex p-4 h-full max-w-full bg-white ">
      <div className={`bg-gray-200 shadow-sm text-black fixed top-0 left-0 h-full flex flex-col transition-all duration-300 ease-in-out   ${
          isSidebarOpen ? "w-64" : "w-21" 
        }`}
        style={{ backgroundImage: 'linear-gradient(90deg, rgba(255,253,159,1) 21%, rgba(255,217,183,1) 100%, rgba(255,255,255,1) 100%)'  }}
      >
        <div className="p-4 flex items-center justify-between">
          <button onClick={toggleSidebar} className="text-black">
            {isSidebarOpen?
            (<IoMdClose className="ml-3 hover:animate-pulse" size={30}/>)
            :
            (<FiMenu className="ml-3 hover:animate-pulse" size={30} />)}
          </button>
        </div>
        <ul className="space-y-2 flex-1 px-4 overflow-y-auto">
          {isSidebarOpen && (
            <div className="p-2">
              <h2 className="text-amber-600 text-xl font-bold mb-2">
                Yoddah 
              </h2>
              <h2 className="text-black text-xl font-bold mb-4">
                Recruiter Dashboard
              </h2>
            </div>
          )}
          <li>
            <button
              onClick={() => handleViewChange("home")}
              className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
                currentView === "home" ? "bg-green-500" : ""
              }`}
            >
              <MdHome size={30} className="hover:animate-pulse" />
              <span className="ml-2">{isSidebarOpen && "Home"}</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => handleViewChange("resume-parser")}
              className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
                currentView === "resume-parser" ? "bg-green-500" : ""
              }`}
            >
              <MdOutlineDescription size={30} className="mx-0 hover:animate-pulse" />
              <span className="ml-1">{isSidebarOpen && "Single Resume Parser"}</span>
            </button>
          </li>
          {/* <li>
            <button
              onClick={() => handleViewChange("bulk-resume-parser")}
              className={`block text-gray-300 hover:text-white rounded-md px-3 py-2 flex items-center ${
                currentView === "bulk-resume-parser" ? "bg-green-500" : ""
              }`}
            >
              <GrMultiple size={20} className="mr-2 hover:animate-pulse" />
              {isSidebarOpen && "Bulk Resume Parser"}
            </button>
          </li> */}
          <li>
            <button
              onClick={() => handleViewChange("bulk-parser")}
              className={`block text-black hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
                currentView === "bulk-parser" ? "bg-green-500" : ""
              }`}
            >
              <GrMultiple size={30} className="mx-0 hover:animate-pulse" />
              <span className="ml-2">{isSidebarOpen && "Bulk Resume Parser"}</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => handleViewChange("candidates-view")}
              className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
                currentView === "candidates-view" ? "bg-green-500" : ""
              }`}
            >
              <FaUserTie size={30} className="mx-0 hover:animate-pulse" />
              <span className="ml-2">{isSidebarOpen && "Candidates"}</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => handleViewChange("candidates-view1")}
              className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
                currentView === "candidates-view1" ? "bg-green-500" : ""
              }`}
            >
              <FaUserTie size={30} className="mx-0 hover:animate-pulse" />
              <span className="ml-2">{isSidebarOpen && "Candidates Details"}</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => handleViewChange("veterans-view")}
              className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
                currentView === "veterans-view" ? "bg-green-500" : ""
              }`}
            >

              <FaPersonMilitaryRifle size={30} className="mx-0 hover:animate-pulse" />
              <span className="ml-2">{isSidebarOpen && "Veterans"}</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => handleViewChange("resume-builder")}
              className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
                currentView === "resume-builder" ? "bg-green-500" : ""
              }`}
            >
              <MdOutlineBuild size={30} className="mx-0 hover:animate-pulse" />
              <span className="ml-2">{isSidebarOpen && "Resume Builder"}</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => handleViewChange("report")}
              className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
                currentView === "report" ? "bg-green-500" : ""
              }`}
            >
              <TbReportAnalytics size={30} className="mx-0 hover:animate-pulse" />
              <span className="ml-2">{isSidebarOpen && "Reports"}</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => handleViewChange("table")}
              className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
                currentView === "table" ? "bg-green-500" : ""
              }`}
            >
              <MdTableView size={30} className="mx-0 hover:animate-pulse" />
              <span className="ml-2">{isSidebarOpen && "Candidate Table"}</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => handleViewChange("profile")}
              className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
                currentView === "profile" ? "bg-green-500" : ""
              }`}
            >
              <MdOutlineAccountCircle size={30} className="mx-0 hover:animate-pulse" />
              <span className="ml-2">{isSidebarOpen && "Profile"}</span>
            </button>
          </li>
        </ul>
        {isSidebarOpen && (
          <button
          data-modal-target="popup-modal" data-modal-toggle="popup-modal"
            className="bg-red-500 hover:bg-red-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-auto px-4 active:animate-ping"
            onClick={onLogout}
            // class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
          >
            Logout
          </button>
        )}
      </div>

      <div
     className={`flex-1  p-5 transition-all duration-300 ease-in-out bg-white shadow-lg  ${
       isSidebarOpen ? "ml-64 max-w-full" : "ml-20 max-w-full"
     }`}
   >
     {/* Render the correct component based on currentView */}
     {currentView === "home" &&  <Home isSidebarOpen={isSidebarOpen}/>}
     {currentView === "resume-parser" && <ResumeParser />}
     {currentView === "bulk-resume-parser" && <BulkResumeParser />}
     {currentView === "bulk-parser" && <BulkParser />}
     {currentView === "candidates-view" && <Candidates />}
     {currentView === "candidates-view1" && <CandidatesDet isSidebarOpen={isSidebarOpen} />}
     {currentView === "veterans-view" && <Veterans />}
     {currentView === "resume-builder" && <ResumeBuilder />}
     {currentView === "profile" && <Profile />}
     {currentView === "report" && <Report isSidebarOpen={isSidebarOpen}/>}
     {currentView === "table" && <CandidateTable isSidebarOpen={isSidebarOpen}/>}
      </div>
    </div>
  );
}

export default Dashboard;