import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { AIChatSession } from '../../service/AIModel'; 

const HalfYearlyReport = () => {
  const [reportData, setReportData] = useState(null);
  const [reportPromptData, setReportPromptData] = useState("");
  const [insights, setInsights] = useState({ salesTrendInsights: [], reasonsForSalesChange: [] });
  const [loading, setLoading] = useState(true);
  const [insightsData, setInsightsData] = useState([])

 const generateInsights = async () => {
   try {
     setLoading(true);
     console.log('generateInsights function called!');
     const PROMPT = `Based on the following data, ${reportPromptData} - provide in paragraph - what was sales trend insights and why did sales change?`;
     console.log("promt before sending: ", PROMPT)
     const response = await AIChatSession.sendMessage(PROMPT);
    console.log('API Response:', response); // Log the response 
    const aiResponse = JSON.parse(response.response.text());
    setInsightsData(aiResponse);
    localStorage.setItem('aiInsights_response', JSON.stringify(aiResponse));
    console.log("insights Data: ", insightsData)
    
  } catch (error) {
    console.error('Error generating AI Insights:', error);
  }finally{
    setLoading(false);
  } 

 }

  useEffect( () => {
    // Fetch report data from the backend
    axios.get(`${process.env.REACT_APP_SERVER_API_URL}/api/report-data`)
      .then( async (response) => {
        const repData = JSON.stringify(response.data);
        setReportData(response.data);
        setReportPromptData(repData);
        console.log('Report Data: ',reportData);
        console.log('Report string Data: ',reportPromptData);
        // const PROMPT = `Based on the following data, provide sales trend insights and why did sales change? in bullet points  for the report: ${reportData}`;
        // const aiResponse = await AIChatSession.sendMessage(prompt);
        // console.log('AI Response:', aiResponse); // Log the response 
        // const aiInsightsData = JSON.parse(aiResponse.response.text());
        // setInsightsData(aiInsightsData);
        // localStorage.setItem('aiInsightsData', JSON.stringify(aiInsightsData));
  
        // return aiInsightsData
        return axios.post(`${process.env.REACT_APP_SERVER_API_URL}/api/generate-insights`, { data: response.data });
      })
      .then((response) => {
        const iData = response.data.insights || { salesTrendInsights: [], reasonsForSalesChange: [] };
        setInsights(iData);
        setInsightsData(iData)
        console.log('Fetched insights:', insightsData); // Log the fetched insights
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching report data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-green-500">
          
        </div>
        <span className="fixed animate-bounce font-bold text-xs">Yoddah AI Generating...</span>
      </div>
    );
  }

  return (
    <div className="p-8 bg-white min-h-screen">
      <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Annual Report</h1>

       <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">Yoddah AI-Generated Insights</h2>
           <button 
            className="bg-blue-500 text-white ml-4 px-6 py-2 rounded-full hover:bg-blue-800 transition" 
            onClick={generateInsights}
            >
            Generate Insights
          </button></div>
             
       <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
             { insightsData.salesTrendInsights && (
              <div className="mt-4">
                <p className="font-bold text-gray-800">salesTrendInsights:</p>
                  {insightsData.salesTrendInsights.map((i, index) => (
                    <div key={index} className="break-inside-avoid custom-bullet pl-5">
                      <ul className="list-disc pl-5 text-gray-600">
                          <li key={i}>{i}</li>
                      </ul>
                    </div>
                    ))}
              </div>
            )}
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
              <p>{insights}</p>
            </div>

      {reportData && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">Sales Overview</h3>
            <Bar data={reportData.salesOverview} />
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">Revenue Growth</h3>
            <Line data={reportData.revenueGrowth} />
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">Market Share</h3>
            <Pie data={reportData.marketShare} />
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">Customer Satisfaction</h3>
            <Bar data={reportData.customerSatisfaction} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HalfYearlyReport;

