import { Button } from './ui/button';
import { ResumeInfoContext } from './ResumeInfoContext';
import { Brain, LoaderCircle } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { BtnBold, BtnBulletList, BtnItalic, BtnLink, BtnNumberedList, BtnStrikeThrough, BtnUnderline, Editor, EditorProvider, Separator, Toolbar, createButton } from 'react-simple-wysiwyg';
import { AIChatSession } from '../../service/AIModel';
import { toast } from "react-toastify";

const BtnAlignJustify = createButton('Align Justify', '≡', 'justifyFull');
const BtnBullet = createButton('Align Justify', '-', 'justifyFull');

function RichTextEditor({ onRichTextEditorChange, index, defaultValue, promptTemplate, contextType }) {
    const [value, setValue] = useState(defaultValue);
    const { resumeInfo } = useContext(ResumeInfoContext);
    const [loading, setLoading] = useState(false);
    const [skillsDetails, setSkillsDetails] = useState([]);


    const GenerateSummaryFromAI = async () => {
        let title, duration;

        // Determine the title based on contextType
        if (contextType === 'Experience') {
            if (!resumeInfo?.Experience[index]?.title) {
                toast('Please Add Position Title');
                return;
            }
            title = resumeInfo.Experience[index]?.title || '';
            console.log("Context Type",contextType)
            console.log("Position Title: ", resumeInfo?.Experience[index]?.title)
            console.log("Position Title -- : ", title)

            // Calculate duration for experience prompts
            const calculateDuration = () => {
                if (!resumeInfo.Experience[index].startDate || !resumeInfo.Experience[index].endDate) {
                    return '';
                }
                const start = new Date(resumeInfo.Experience[index].startDate);
                const end = new Date(resumeInfo.Experience[index].currentlyWorking ? new Date() : resumeInfo.Experience[index].endDate);
                let years = end.getFullYear() - start.getFullYear();

                if (years <= 0) {
                    years = 0;
                }

                return `${years} years`;
            };
            duration = calculateDuration();
            console.log("Experience Duration:", duration)

        } else if (contextType === 'Skills') {
            if (!resumeInfo?.Skills[index]?.title) {
              console.log('ResumeInfo : ', resumeInfo)
              console.log('resumeInfo?.Skills[0]?.positionTitle : ', resumeInfo?.Skills[index]?.title)
                toast('Please Add Job Title');
                return;
            }
            title = resumeInfo?.Skills[index]?.title || '';
            console.log("title in Skills: ", resumeInfo.Skills[index].title)
            console.log("resumeinfo: ", resumeInfo)
            duration = ''; // No duration needed for Skills
        }

        setLoading(true);

        // Prepare the prompt by replacing the placeholders
        let prompt = promptTemplate;

        if (prompt.includes('{positionTitle}')) {
            prompt = prompt.replace('{positionTitle}', title);
        }

        if (prompt.includes('{jobTitle}')) {
            prompt = prompt.replace('{jobTitle}', title);
        }

        if (prompt.includes('{duration}') && duration) {
            prompt = prompt.replace('{duration}', duration);
        }

        try {
            console.log("prompt before sending it to the Ai:", prompt)
           
            if(contextType=="Experience"){

                const result = await AIChatSession.sendMessage(prompt);
                const resp = await result.response.text();
                console.log("response from ai:",resp)
                setValue(resp.replace('[', '').replace(']', ''));
                onRichTextEditorChange({ target: { value: resp.replace('[', '').replace(']', '') } });
            } 
            else if(contextType=="Skills"){   
                
                const result = await AIChatSession.sendMessage(prompt);
                const resp = await result.response.text();
                const skillsData = JSON.parse(result.response.text());
                setSkillsDetails(skillsData);
                console.log("response from ai skillsData: ",skillsData)

                onRichTextEditorChange({ target: { value: resp.replace('[', '').replace(']', '')} });
            }
        } catch (error) {
            console.error("Error generating AI summary:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className='flex justify-between my-2'>
                <label className='text-xs'>Skills Summary</label>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={GenerateSummaryFromAI}
                    disabled={loading}
                    className="flex gap-2 border-primary text-primary">
                    {loading ? <LoaderCircle className='animate-spin' /> :
                        <>
                            <Brain className='h-4 w-4' /> Generate from Yoddah AI
                        </>
                    }
                </Button>
            </div>
            <EditorProvider>
                <Editor value={value} onChange={(e) => {
                    setValue(e.target.value);
                    onRichTextEditorChange(e);
                }}>
                    <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnStrikeThrough />
                        <Separator />
                        <BtnNumberedList />
                        <BtnBulletList />
                        <BtnAlignJustify />
                        <Separator />
                        <BtnLink />
                    </Toolbar>
                </Editor>
            </EditorProvider>
        </div>
    );
}

export default RichTextEditor;
