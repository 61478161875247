import { Button } from './ui/button'
import { Input } from './ui/input'
import React, { useContext, useEffect, useState } from 'react'
import RichTextEditor from './RichTextEditor'
import { ResumeInfoContext } from './ResumeInfoContext'
import { useParams } from 'react-router-dom'
// import GlobalApi from './../../../../../service/GlobalApi'
// import { toast } from 'sonner'
import { LoaderCircle } from 'lucide-react'
import { toast } from "react-toastify";


// const PROMPT='position title: {positionTitle} , years of experience : {duration}  Depends on position title and years of experience , give me a Work Experience in Bullet Points for my experience which should be only Indian based in resume (Please do not add experience level and No JSON array and no quotations, and no json format result) , give me result in HTML tags in 200 words'


const formField={
    title:'',
    companyName:'',
    city:'',
    state:'',
    startDate:'',
    endDate:'',
    workSummary:'',

}
function Experience() {
    const [experinceList,setExperinceList]=useState( []);
    const {resumeInfo,setResumeInfo}=useContext(ResumeInfoContext);
    const params=useParams();
    const [loading,setLoading]=useState(false);

    // useEffect(()=>{
    //     resumeInfo?.Experience.length>0&&setExperinceList(resumeInfo?.Experience)
        
    // },[])

    useEffect(() => {
        if (resumeInfo?.Experience && resumeInfo.Experience.length > 0) {
          setExperinceList(resumeInfo.Experience);
        }
        // setResumeInfo({
        //     ...resumeInfo,
        //     Experience:experinceList
        // });
      }, []);

    const handleChange=(index,event)=>{
        const newEntries=experinceList.slice();
        const {name,value}=event.target;
        newEntries[index][name]=value;
        console.log(newEntries)
        setExperinceList(newEntries);
        setResumeInfo({
            ...resumeInfo,
            Experience:experinceList
        });
    }

    const AddNewExperience=()=>{
    
        setExperinceList([...experinceList,{
            title:'',
            companyName:'',
            city:'',
            state:'',
            startDate:'',
            endDate:'',
            workSummary:'',
        }])
    }

    const RemoveExperience=()=>{
        setExperinceList(experinceList=>experinceList.slice(0,-1))
    }

    const handleRichTextEditor=(e,name,index)=>{
        const newEntries=experinceList.slice();
        newEntries[index][name]=e.target.value;
       
        setExperinceList(newEntries);

        setResumeInfo({
            ...resumeInfo,
            Experience:experinceList
        });
        console.log("Experience list entries:", experinceList)
    }

    // useEffect(()=>{
    //     setResumeInfo({
    //         ...resumeInfo,
    //         Experience:experinceList
    //     });
     
    // },[]);


    const onSave=()=>{
        try{setLoading(true)
        localStorage.setItem("ResumeInfo_Experience", experinceList);
        
        const data={
            data:{
                Experience:experinceList.map(({ id, ...rest }) => rest)
            }
        }

         console.log('Experience List',experinceList)

        // GlobalApi.UpdateResumeDetail(params?.resumeId,data).then(res=>{
        //     console.log(res);
        //     setLoading(false);
        //     toast('Details updated !')
        // },(error)=>{
        //     setLoading(false);
        // })
        }finally{
            setLoading(false);
        }
    }
  return (
    <div>
        <div className='p-5 shadow-lg rounded-lg border-t-primary border-t-4 mt-10'>
        <h2 className='font-bold text-lg'>Professional Experience</h2>
        <p>Add Your previous Job experience</p>
        <div>
            {experinceList.map((item,index)=>(
                <div key={index}>
                    <div className='grid grid-cols-2 gap-3 border p-3 my-5 rounded-lg'>
                        <div>
                            <label className='text-xs'>Position Title</label>
                            <Input name="title" 
                            onChange={(event)=>handleChange(index,event)}
                            defaultValue={item?.title}
                            />
                        </div>
                        <div>
                            <label className='text-xs'>Company Name</label>
                            <Input name="companyName" 
                            onChange={(event)=>handleChange(index,event)}
                            defaultValue={item?.companyName} />
                        </div>
                        <div>
                            <label className='text-xs'>City</label>
                            <Input name="city" 
                            onChange={(event)=>handleChange(index,event)} 
                            defaultValue={item?.city}/>
                        </div>
                        <div>
                            <label className='text-xs'>State</label>
                            <Input name="state" 
                            onChange={(event)=>handleChange(index,event)}
                            defaultValue={item?.state}
                             />
                        </div>
                        <div>
                            <label className='text-xs'>Start Date</label>
                            <Input type="date"  
                            name="startDate" 
                            onChange={(event)=>handleChange(index,event)} 
                            defaultValue={item?.startDate}/>
                        </div>
                        <div>
                            <label className='text-xs'>End Date</label>
                            <Input type="date" name="endDate" 
                            onChange={(event)=>handleChange(index,event)} 
                            defaultValue={item?.endDate}
                            />
                        </div>
                        <div className='col-span-2'>
                           {/* Work Summary  */}
                           {/* <RichTextEditor
                           index={index}
                           defaultValue={item?.workSummary}
                           onRichTextEditorChange={(event)=>handleRichTextEditor(event,'workSummary',index)}  
                           promptTemplate='Please provide the output in bullet points for the following input - position title: {positionTitle} , years of experience : {duration}  Depending on position title and years of experience, give me a Work Experience in 5 Bullet Points for my experience which should be only Indian based in resume (Please do not add or mention experience level and No JSON array and no quotations), give me result in HTML tags in 200 words'
                           /> */}
                           <RichTextEditor
                            index={index}
                            defaultValue={item?.workSummary}
                            onRichTextEditorChange={(event)=>handleRichTextEditor(event,'workSummary',index)}
                            promptTemplate='Please provide the output for the following input - position title: {positionTitle}, years of experience: {duration}. --Depending on position title and years of experience, Provide the Results in 5 Bullet points - the significant points only. {Note : Dont provide any json syntax in response such as key and value type fields, provide only in bullet points, No words such as Summary key or Experience Level key should be present in the Bullet points}. please provide in the HTML Tags'
                            contextType="Experience"
                            />

                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div className='flex justify-between'>
            <div className='flex gap-2'>
            <Button variant="outline" onClick={AddNewExperience} className="text-primary"> + Add More Experience</Button>
            <Button variant="outline" onClick={RemoveExperience} className="text-primary"> - Remove</Button>

            </div>
            <Button className='bg-green-400 hover:bg-green-600 active:animate-ping' disabled={loading} onClick={()=>onSave()}>
            {loading?<LoaderCircle className='animate-spin' />:'Save'}    
            </Button>
        </div>
        </div>
    </div>
  )
}

export default Experience