import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";

import { Box, Button, IconButton, Typography, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { MaterialReactTable, MRT_Table }  from 'material-react-table';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Navigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { ExportToCsv } from 'export-to-csv'; // Import the CSV export library
import { generateCsv, download } from 'export-to-csv'; // Import the CSV export library
import {toast} from 'react-toastify'

// Define the columns for the table
const columns = [
  { accessorKey: 'c_id', header: 'Yoddah Candidate ID' },
  { accessorKey: 'Sr_No', header: 'Sr No' },
  { accessorKey: 'Sub_Date', header: 'Submission Date' },
  { accessorKey: 'Consultant_Name', header: 'Consultant Name' },
  { accessorKey: 'Candidates_Name', header: 'Candidate Name' },
  { accessorKey: 'Position_applied_for', header: 'Position Applied For' },
  { accessorKey: 'Rel_Exp', header: 'Relevant Experience (Years)' },
  { accessorKey: 'Total_Exp_Range', header: 'Total Experience (Years)' },
  { accessorKey: 'Current_Org', header: 'Current Organization' },
  { accessorKey: 'Current_Designation', header: 'Current Designation' },
  { accessorKey: 'Current_Skills', header: 'Current Skills' },
  { accessorKey: 'Current_Location', header: 'Current Location' },
  { accessorKey: 'Education', header: 'Education' },
  { accessorKey: 'Year_of_Passing', header: 'Year of Passing' },
  { accessorKey: 'Current_CTC_fixed', header: 'Current CTC (Fixed)' },
  { accessorKey: 'Current_CTC_Variable', header: 'Current CTC (Variable)' },
  { accessorKey: 'Total_CTC', header: 'Total CTC' },
  { accessorKey: 'Exp_CTC', header: 'Expected CTC' },
  { accessorKey: 'NP_in_days', header: 'Notice Period (Days)' },
  { accessorKey: 'Interview_schedule', header: 'Interview Schedule' },
];

const CandidateTable = ({isSidebarOpen}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [tableState, setTableState] = useState({});
  
  const navigate = useNavigate();

  const handleClick = (event, path) => {
      event.preventDefault();
      navigate(path);
  };

  const [formData, setFormData] = useState({
    c_id:'',
    Sr_No: '',
    Sub_Date: '',
    Consultant_Name: '',
    Candidates_Name: '',
    Position_applied_for: '',
    Rel_Exp: '',
    Total_Exp_Range: '',
    Current_Org: '',
    Current_Designation: '',
    Current_Skills: '',
    Current_Location: '',
    Education: '',
    Year_of_Passing: '',
    Current_CTC_fixed: '',
    Current_CTC_Variable: '',
    Total_CTC: '',
    Exp_CTC: '',
    NP_in_days: '',
    Interview_schedule: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_API_URL}/api/candidates`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching candidate data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOpenDialog = (candidate = null) => {
    setSelectedCandidate(candidate);
    setFormData(candidate || {
        c_id:'',
      Sr_No: '',
      Sub_Date: '',
      Consultant_Name: '',
      Candidates_Name: '',
      Position_applied_for: '',
      Rel_Exp: '',
      Total_Exp_Range: '',
      Current_Org: '',
      Current_Designation: '',
      Current_Skills: '',
      Current_Location: '',
      Education: '',
      Year_of_Passing: '',
      Current_CTC_fixed: '',
      Current_CTC_Variable: '',
      Total_CTC: '',
      Exp_CTC: '',
      NP_in_days: '',
      Interview_schedule: ''
    });
    setEditMode(!!candidate);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      if (editMode) {
        await axios.put(`${process.env.REACT_APP_SERVER_API_URL}/api/candidates/${formData.c_id}`, formData);
      } else {
        await axios.post(`${process.env.REACT_APP_SERVER_API_URL}/api/candidates`, formData);
      }
      const response = await axios.get(`${process.env.REACT_APP_SERVER_API_URL}/api/candidates`);
      setData(response.data);
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving candidate data:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_API_URL}/api/candidates/${id}`);
      const response = await axios.get(`${process.env.REACT_APP_SERVER_API_URL}/api/candidates`);
      setData(response.data);
    } catch (error) {
      console.error('Error deleting candidate data:', error);
    }
  };

  const handleExportCSV = () => {
    return data;
  };

//   const handleExportPDF3 = (columns, data) => {
//     const doc = new jsPDF();

//     // Add a title or other content to the PDF
//     doc.text('Candidate Report', 20, 10);

//     // If you have column headers and data, process them
//     const selectedColumns = tableState.columnVisibility
//     console.log('Selected Columns: ', selectedColumns)
//     const headers = columns.map(col => col.header);  // Assuming columns have a 'header' property
//     // const bodyData = data.map(row => {
//     //     const filteredRow = {};
//     //     selectedColumns.forEach(col => {
//     //       filteredRow[col] = row[col];
//     //     });
//     //     return filteredRow;
//     //   });
//     const bodyData = data.map(row =>
//         columns.map(col => row[col.accessor])  // Assuming columns have an 'accessor' to map data
//     );

//     // Check if data is not empty before exporting
//     if (bodyData.length > 0) {
//         // Generate the table using autoTable
//         doc.autoTable({
//             head: [headers],
//             body: bodyData,
//             startY: 20, // Adjust this value to position the table correctly
//         });
//     } else {
//         doc.text('No data available for export.', 20, 30);
//         toast.warn('No data available for export.')
//     }

//     // Save the PDF
//     toast.success('candidate_report generated!')
//     doc.save('candidate_report.pdf');
// };

//   const handleExportPDF2 = () => {
//     const doc = new jsPDF();

//     // Add a title or other content to the PDF
//     doc.text('Candidate Report', 20, 10);

//     // Extract the table data
//     const columns = data.getVisibleLeafColumns().map(col => col.header);
//     const data = data.getRowModel().rows.map(row =>
//         columns.map(col => row.getValue(col))
//     );

//     // Check if data is not empty before exporting
//     if (data.length > 0) {
//         // Generate the table using autoTable
//         doc.autoTable({
//             head: [columns],
//             body: data,
//             startY: 20, // Adjust this value to position the table correctly
//         });
//     } else {
//         doc.text('No data available for export.', 20, 30);
//     }

//     // Save the PDF
//     doc.save('candidate_report.pdf');
// };

const handleExportSelectedCSV = () => {
    const selectedColumns = tableState.columnVisibility
      ? Object.keys(tableState.columnVisibility).filter(key => tableState.columnVisibility[key])
      : [];

    const filteredData = data.map(row => {
      const filteredRow = {};
      selectedColumns.forEach(col => {
        filteredRow[col] = row[col];
      });
      return filteredRow;
    });

    return filteredData;
  };
   

  const handleExportPDF = () => {
    const doc = new jsPDF();
    doc.text('Candidate Report', 14, 22);
    doc.autoTable({ html: '#candidate-table' });
    doc.save('CandidateReport.pdf');
  };

  return (
    <div className={`container mx-auto p-6 transition-all duration-300 ease-in-out bg-white top-0 ${
        isSidebarOpen ? 'left-64' : 'left-20'
    }`}>
         <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                <Link
                    underline="hover"
                    color="inherit"
                    href="/"
                    onClick={(event) => handleClick(event, '/dashboard')}
                >
                    Home
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    href="/"
                    onClick={(event) => handleClick(event, '/candidate-table')}
                >
                    Candidate Table
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    href="/reports"
                    onClick={(event) => handleClick(event, '/candidate-report')}
                >
                    Candidate Report
                </Link>
            </Breadcrumbs>
        <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
            Candidate Table
        </Typography>
        <Box sx={{ backgroundColor:"white", mb: 2,
         overflow: 'hidden',
         borderRadius: 2,
         zIndex: 1, // Ensure this is lower than other components
        transition: 'all 0.3s ease-in-out',
         }}>
            {/* <Button
            variant="contained"
            color="primary"
            onClick={handleExportPDF(columns, data)}
            className='text-black'
            sx={{ color:'black' ,mr: 2 , backgroundImage: 'linear-gradient(90deg, rgba(255,253,159,1) 21%, rgba(255,217,183,1) 100%, rgba(255,255,255,1) 100%)' }}
            >
            Export to PDF
            </Button> */}
            <CSVLink
            data={handleExportCSV()}
            filename="CandidateReport.csv"
            className="btn btn-primary"
            >
            <Button variant="contained"      
            sx={{ color:'black' ,mr: 2 , backgroundImage: 'linear-gradient(90deg, rgba(255,253,159,1) 21%, rgba(255,217,183,1) 100%, rgba(255,255,255,1) 100%)' }}
            >
                Export to CSV
            </Button>
            </CSVLink>
            <CSVLink
            data={handleExportSelectedCSV()}
            filename="CandidateReport_SelectedColumns.csv"
            >
            <Button variant="contained"              
            sx={{ color:'black' ,mr: 2 , backgroundImage: 'linear-gradient(90deg, rgba(255,253,159,1) 21%, rgba(255,217,183,1) 100%, rgba(255,255,255,1) 100%)' }}
            >
                Export Selected Columns to CSV
            </Button>
            </CSVLink>
            <Button
            variant="contained"
            color="secondary"
            onClick={() => handleOpenDialog()}
            startIcon={<AddIcon />}
            sx={{ ml: 2, color:'black' , backgroundImage: 'linear-gradient(90deg, rgba(255,253,159,1) 21%, rgba(255,217,183,1) 100%, rgba(255,255,255,1) 100%)' }}
            >
            Add Candidate
            </Button>
            <Button
            variant="contained"
            color="primary"
            onClick={()=>{
                navigate("/candidate-report");
            }}
            className='text-black'
            sx={{ color:'black' ,ml: 2 , backgroundImage: 'linear-gradient(90deg, rgba(255,253,159,1) 21%, rgba(255,217,183,1) 100%, rgba(255,255,255,1) 100%)' }}
            >
            View Candidate Report
            </Button>
           
        </Box>
      

        <MaterialReactTable
        sx={{ overflow: 'hidden',zIndex: 1}}
        columns={columns}
        data={data}
        isLoading={loading}
        renderRowActions={({ row }) => (
            <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton
                color="primary"
                onClick={() => handleOpenDialog(row.original)}
            >
                <EditIcon />
            </IconButton>
            <IconButton
                color="error"
                onClick={() => handleDelete(row.original._id)}
            >
                <DeleteIcon />
            </IconButton>
            </Box>
        )}
        onStateChange={(newState) => setTableState(newState)}
        enableColumnActions
        enableColumnDragging
        enableCellActions
        enableEditing
        enableFullScreenToggle
        enableRowActions
        enableSorting
        enablePagination
        className=" fixed"
        />

        {/* Dialog for Add/Edit */}
        <Dialog open={open} onClose={handleCloseDialog}>
            <DialogTitle>{editMode ? 'Edit Candidate' : 'Add Candidate'}</DialogTitle>
            <DialogContent>
            {Object.keys(formData).map((key) => (
                <TextField
                key={key}
                name={key}
                label={key.replace(/_/g, ' ')}
                value={formData[key]}
                onChange={handleChange}
                fullWidth
                margin="normal"
                multiline={key === 'Current_Skills'}
                rows={key === 'Current_Skills' ? 2 : 1}
                />
            ))}
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleSubmit}>{editMode ? 'Update' : 'Add'}</Button>
            </DialogActions>
        </Dialog>
        </Box>
    </div>
  );
};

export default CandidateTable;
