import { Button } from './ui/button'
import { Textarea } from './ui/textarea'
import { ResumeInfoContext } from './ResumeInfoContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
// import GlobalApi from './../../../../../service/GlobalApi';
import { Brain, LoaderCircle } from 'lucide-react';
// import { toast } from 'sonner';
import { AIChatSession } from '../../service/AIModel';

const prompt="Job Title: {jobTitle} , Depends on job title give me list of  summary for 3 experience level, Mid Level and Freasher level in 3 -4 lines in array format, With summary and experience_level Field in JSON Format"
// const prompt="Job Title: {jobTitle} , Depends on job title give me list of  summary for experience level Field in JSON Format"

function Summary({enabledNext}) {
    const {resumeInfo,setResumeInfo}=useContext(ResumeInfoContext);
    const [summary,setSummary]=useState(localStorage.getItem("ResumeInfo_Summary")|| '');
    const [loading,setLoading]=useState(false);
    const params=useParams();
    const [aiGeneratedSummaryList,setAiGenerateSummaryList]=useState();
    useEffect(()=>{
        summary&&setResumeInfo({
            ...resumeInfo,
            summary:summary
        })
    },[summary])

    const GenerateSummaryFromAI=async()=>{
        setLoading(true)
        const PROMPT=prompt.replace('{jobTitle}',resumeInfo?.jobTitle);
        console.log(PROMPT);
        const result=await AIChatSession.sendMessage(PROMPT);
        console.log(JSON.parse(result.response.text()))
       
        setAiGenerateSummaryList(JSON.parse(result.response.text()))
        setLoading(false);
    }

    const onSave=(e)=>{
        try{e.preventDefault();
        console.log('Resume info: ', resumeInfo)
        const data={
            data:{
                summary:summary
            }
        }
        localStorage.setItem("ResumeInfo_Summary", summary)
        setLoading(true)
    }finally{
            setLoading(false)   
        }
        // GlobalApi.UpdateResumeDetail(params?.resumeId,data).then(resp=>{
        //     console.log(resp);
        //     enabledNext(true);
        //     setLoading(false);
        //     toast("Details updated")
        // },(error)=>{
        //     toast("Error: ",error)
        //     console.error(error)
        //     // toast("Something Went Wrong!")
        //     setLoading(false);
        // })
    }
    return (
    <div>
         <div className='p-5 shadow-lg rounded-lg border-t-primary border-t-4 mt-10'>
        <h2 className='font-bold text-lg'>Summary</h2>
        <p>Add Summary for your job title</p>

        <form className='mt-7' onSubmit={onSave}>
            <div className='flex justify-between items-end'>
                <label>Add Summary</label>
                <Button variant="outline" onClick={()=>GenerateSummaryFromAI()} 
                type="button" size="sm" className="border-primary text-primary flex gap-2"> 
                <Brain className='h-4 w-4' />  Generate from Yoddah AI</Button>
            </div>
            <Textarea className="mt-5 text-justify" required
            value={summary}
                defaultValue={summary?summary:resumeInfo?.summary}
            onChange={(e)=>setSummary(e.target.value)}
            />
            <div className='mt-2 flex justify-end'>
            <Button 
                type="submit"
                 className='bg-green-400 hover:bg-green-600 active:animate-ping'
                disabled={loading}>
                    {loading?<LoaderCircle className='animate-spin' />:'Save'}
                    </Button>
            </div>
        </form>
        </div>

        
       {aiGeneratedSummaryList&& <div className='my-5'>
            <h2 className='font-bold text-lg'>Suggestions</h2>
            {aiGeneratedSummaryList?.map((item,index)=>(
                <div key={index} 
                onClick={()=>setSummary(item?.summary)}
                className='p-5 shadow-lg my-4 rounded-lg cursor-pointer'>
                    <h2 className='font-bold my-1 text-primary'>Level: {item?.experience_level}</h2>
                    <p className='text-justify'>{item?.summary}</p>
                </div>
            ))}
        </div>}

    </div>
  )
}

export default Summary