// import React, { useState } from "react";
// import { BsFileEarmarkPdf, BsFileEarmarkWord } from "react-icons/bs"; // Import icons

// function ResumeParser() {
//   const [selectedFiles, setSelectedFiles] = useState([]); // State for uploaded files
//   const [showModal, setShowModal] = useState(false); // State for modal
//   const [selectedForRemoval, setSelectedForRemoval] = useState([]); // State for files to remove

//   const handleFileChange = (e) => {
//     const files = Array.from(e.target.files);
//     setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
//   };

//   const handleModalClose = () => {
//     setShowModal(false);
//     setSelectedForRemoval([]); // Clear selected files for removal
//   };

//   const handleCheckboxChange = (file) => {
//     if (selectedForRemoval.includes(file)) {
//       setSelectedForRemoval(
//         selectedForRemoval.filter((f) => f !== file)
//       );
//     } else {
//       setSelectedForRemoval([...selectedForRemoval, file]);
//     }
//   };

//   const handleRemoveSelected = () => {
//     setSelectedFiles(
//       selectedFiles.filter((file) => !selectedForRemoval.includes(file))
//     );
//     setSelectedForRemoval([]); // Clear selected files for removal
//   };

//   return (
//     <div>
//       <h2 className="text-2xl font-bold mb-4">Resume Parser</h2>
//       <div className="mb-4">
//         <label htmlFor="fileUpload" className="block text-gray-700 text-sm font-bold mb-2">
//           Upload Resumes
//         </label>
//         <input
//           type="file"
//           id="fileUpload"
//           accept=".pdf,.docx"
//           multiple
//           onChange={handleFileChange}
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//         />
//       </div>

//       {/* View Uploaded Files Button */}
//       <button
//         onClick={() => setShowModal(true)}
//         className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//       >
//         View Uploaded Files
//       </button>

//       {/* Modal */}
//       {showModal && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
//           <div className="bg-white rounded-lg shadow-lg p-6 w-1/2 max-w-md">
//             <h3 className="text-xl font-bold mb-4">Uploaded Files</h3>
//             <ul className="list-disc">
//               {selectedFiles.map((file) => (
//                 <li key={file.name} className="flex items-center">
//                   <input
//                     type="checkbox"
//                     checked={selectedForRemoval.includes(file)}
//                     onChange={() => handleCheckboxChange(file)}
//                     className="mr-2"
//                   />
//                   <span className="mr-2">
//                     {file.name}{" "}
//                     {file.type === "application/pdf" ? (
//                       <BsFileEarmarkPdf className="inline" />
//                     ) : (
//                       <BsFileEarmarkWord className="inline" />
//                     )}
//                   </span>
//                 </li>
//               ))}
//             </ul>

//             <div className="mt-4 flex justify-end">
//               <button
//                 onClick={handleModalClose}
//                 className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
//               >
//                 Close
//               </button>
//               {selectedForRemoval.length > 0 && (
//                 <button
//                   onClick={handleRemoveSelected}
//                   className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//                 >
//                   Remove Selected
//                 </button>
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default ResumeParser;
//-----------------------not working below------------------
// import React, { useState, useEffect } from "react";
// import { BsFileEarmarkPdf, BsFileEarmarkWord } from "react-icons/bs";
// import { AIChatSession, model, generationConfig } from "../../service/AIModel"; // Import your AIModel
// import { parse } from "parse-resume"; // Import parse-resume for resume parsing

// function ResumeParser() {
//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [selectedForRemoval, setSelectedForRemoval] = useState([]);
//   const [jobDescription, setJobDescription] = useState("");
//   const [uploadedJobFile, setUploadedJobFile] = useState(null);
//   const [topProfiles, setTopProfiles] = useState([]);

//   const handleFileChange = (e) => {
//     const files = Array.from(e.target.files);
//     setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
//   };

//   const handleModalClose = () => {
//     setShowModal(false);
//     setSelectedForRemoval([]);
//   };

//   const handleCheckboxChange = (file) => {
//     if (selectedForRemoval.includes(file)) {
//       setSelectedForRemoval(
//         selectedForRemoval.filter((f) => f !== file)
//       );
//     } else {
//       setSelectedForRemoval([...selectedForRemoval, file]);
//     }
//   };

//   const handleRemoveSelected = () => {
//     setSelectedFiles(
//       selectedFiles.filter((file) => !selectedForRemoval.includes(file))
//     );
//     setSelectedForRemoval([]);
//   };


//   // Handle Job Description Text Area Change
//   const handleJobDescriptionChange = (e) => {
//     setJobDescription(e.target.value);
//   };

//   // Handle Job Description File Upload
//   const handleJobFileChange = (e) => {
//     setUploadedJobFile(e.target.files[0]);
//   };

//   // Extract Data from AI Response
//   const extractAIData = (response) => {
//     // Assume the AI response is a JSON object with the following structure:
//     // {
//     //   "candidates": [
//     //     { "Name": "...", "Skills": "...", ... },
//     //     { "Name": "...", "Skills": "...", ... },
//     //     ...
//     //   ]
//     // }
//     // You'll need to adjust this based on the actual AI response format
//     return response.candidates.map((candidate) => ({
//       Name: candidate.Name,
//       Skills: candidate.Skills,
//       Address: candidate.Address,
//       Experience: candidate.Experience,
//       PhoneNumber: candidate.PhoneNumber,
//       Email: candidate.Email,
//       Strength: candidate.Strength,
//       Weakness: candidate.Weakness,
//     }));
//   };

//   // Function to generate AI response and extract data
//   const generateAITopProfiles = async () => {
//     try {
//       // Get the job description content from the textarea or uploaded file
//       let jobDescriptionContent = jobDescription;
//       if (uploadedJobFile) {
//         // Parse the file to get the text content (replace with your file parsing logic)
//         const parsedText = await parseFile(uploadedJobFile);
//         jobDescriptionContent = parsedText;
//       }

//       // Get the uploaded resumes as an array of file objects
//       const filesArray = selectedFiles; 

//       // Call your AI model with the jobDescriptionContent and filesArray
//       const response = await AIChatSession.send({
//         content: `Analyze the following job description and resumes to find the top 10 matches:\n\nJob Description: ${jobDescriptionContent}\n\nResumes: ${JSON.stringify(
//           filesArray
//         )}`,
//         // ... other generationConfig options
//       });

//       // Extract the data from the AI response
//       const aiResults = extractAIData(response.response);

//       setTopProfiles(aiResults);
//     } catch (error) {
//       console.error("Error generating top profiles:", error);
//     }
//   };

//   // Helper function to parse a file's content (placeholder, you need to implement this)
//   const parseFile = async (file) => {
//     if (file.type === "application/pdf") {
//       // Use a PDF parsing library to extract text content
//     } else if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
//       // Use a Word document parsing library to extract text content
//     }
//     return "Parsed file content"; // Placeholder
//   };



//   // Function to generate AI response and extract data
//   const generateTopProfiles = async () => {
//     try {
//       // ... (Get jobDescriptionContent and filesArray as before) ... 
//       let jobDescriptionContent = jobDescription;
//       if (uploadedJobFile) {
//         // Parse the file to get the text content (replace with your file parsing logic)
//         const parsedText = await parseFile(uploadedJobFile);
//         jobDescriptionContent = parsedText;
//       }

//       // Get the uploaded resumes as an array of file objects
//       const filesArray = selectedFiles;

//       const response = await AIChatSession.send({
//         content: `Analyze the following job description and resumes to find the top 10 matches, providing their rank, name, matching percentage, skills, email, phone number, strengths, and weaknesses.
        
//         Job Description: ${jobDescriptionContent}\n\nResumes: ${JSON.stringify(
//           filesArray
//         )}`,
//         // ... other generationConfig options
//       });

//       // Extract the data from the AI response (adjust based on your AI model's response format)
//       const aiResults = response.response.candidates.map((candidate, index) => ({
//         Rank: index + 1, // Assign rank based on index
//         Name: candidate.Name,
//         MatchingPercentage: candidate.MatchingPercentage, // Assuming AI provides this
//         Skills: candidate.Skills,
//         Email: candidate.Email,
//         PhoneNumber: candidate.PhoneNumber,
//         Strengths: candidate.Strengths,
//         Weakness: candidate.Weakness,
//       }));

//       setTopProfiles(aiResults);
//     } catch (error) {
//       console.error("Error generating top profiles:", error);
//     }
//   };

  

//   return (
//     <div>
//       <h2 className="text-2xl font-bold mb-4">Resume Parser</h2>

//       {/* Job Description Section */}
//       <div className="mb-4">
//         <label
//           htmlFor="jobDescription"
//           className="block text-gray-700 text-sm font-bold mb-2"
//         >
//           Job Description
//         </label>
//         <textarea
//           id="jobDescription"
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none"
//           value={jobDescription}
//           onChange={handleJobDescriptionChange}
//         />
//       </div>
//       <div className="mb-4">
//         <label
//           htmlFor="jobFile"
//           className="block text-gray-700 text-sm font-bold mb-2"
//         >
//           Upload Job Description File
//         </label>
//         <input
//           type="file"
//           id="jobFile"
//           accept=".pdf,.docx"
//           onChange={handleJobFileChange}
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//         />
//       </div>

//       {/* Resume Upload Section (Same as before) */}
//       {/* ... */}

//       {/* Top 10 Profiles Button */}
//       <button
//         onClick={generateTopProfiles}
//         className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//       >
//         Top 10 Profiles
//       </button>

//       {/* Display AI Results Table */}
//       <div className="mt-4">
//         <table className="w-full table-auto">
//           <thead>
//             <tr>
//               <th className="px-4 py-2 text-left text-gray-500 font-bold">
//                 Rank
//               </th>
//               <th className="px-4 py-2 text-left text-gray-500 font-bold">
//                 Name
//               </th>
//               <th className="px-4 py-2 text-left text-gray-500 font-bold">
//                 Matching Percentage
//               </th>
//               <th className="px-4 py-2 text-left text-gray-500 font-bold">
//                 Skills
//               </th>
//               <th className="px-4 py-2 text-left text-gray-500 font-bold">
//                 Email
//               </th>
//               <th className="px-4 py-2 text-left text-gray-500 font-bold">
//                 Phone Number
//               </th>
//               <th className="px-4 py-2 text-left text-gray-500 font-bold">
//                 Strengths
//               </th>
//               <th className="px-4 py-2 text-left text-gray-500 font-bold">
//                 Weakness
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {topProfiles.slice(0, 10).map((profile) => ( // Only display top 10
//               <tr key={profile.Name}>
//                 <td className="border px-4 py-2">{profile.Rank}</td>
//                 <td className="border px-4 py-2">{profile.Name}</td>
//                 <td className="border px-4 py-2">{profile.MatchingPercentage}%</td>
//                 <td className="border px-4 py-2">{profile.Skills}</td>
//                 <td className="border px-4 py-2">{profile.Email}</td>
//                 <td className="border px-4 py-2">{profile.PhoneNumber}</td>
//                 <td className="border px-4 py-2">{profile.Strengths}</td>
//                 <td className="border px-4 py-2">{profile.Weakness}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default ResumeParser;


//--------------new one streamlit to react converted one--------------

// import React, { useState } from 'react';
// import axios from 'axios';

// const ResumeParser = () => {
//   const [page, setPage] = useState("Home");
//   const [uploadedFiles, setUploadedFiles] = useState([]);
//   const [jobDescription, setJobDescription] = useState("");
//   const [answers, setAnswers] = useState({});
//   const [results, setResults] = useState("");

//   const handleFileUpload = (e) => {
//     setUploadedFiles([...e.target.files]);
//   };

//   const handleInputChange = (e) => {
//     setJobDescription(e.target.value);
//   };

//   const handleAnswerChange = (e) => {
//     setAnswers({ ...answers, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (action) => {
//     const formData = new FormData();
//     uploadedFiles.forEach(file => formData.append('files', file));
//     formData.append('jobDescription', jobDescription);
//     formData.append('answers', JSON.stringify(answers));

//     try {
//       const response = await axios.post(`http://localhost:1000/${action}`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       setResults(response.data);
//     } catch (error) {
//       console.error("There was an error!", error);
//     }
//   };

//   return (
//     <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
//       <div className="w-full max-w-3xl bg-white shadow-lg rounded-lg p-8 mt-8">
//         {page === "Home" && (
//           <div>
//             <h1 className="text-3xl font-bold text-center text-blue-600 mb-8">Welcome to Yoddah AI ATS Resume Expert</h1>
//             <div className="flex justify-around">
//               <button 
//                 className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition" 
//                 onClick={() => setPage("Upload Resume")}>
//                 Bulk Upload Resume
//               </button>
//               <button 
//                 className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition" 
//                 onClick={() => alert("External DB Connection functionality to be implemented.")}>
//                 External DB Connection
//               </button>
//               <button 
//                 className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition" 
//                 onClick={() => alert("Manual Data Entry functionality to be implemented.")}>
//                 Manual Data Entry
//               </button>
//             </div>
//           </div>
//         )}

//         {page === "Upload Resume" && (
//           <div>
//             <h1 className="text-2xl font-bold text-center text-blue-600 mb-8">Upload Resumes</h1>
//             <input 
//               type="file"
//               name="resumes" 
//               multiple 
//               className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-600 hover:file:bg-blue-100" 
//               onChange={handleFileUpload} 
//             />
//             <button 
//               className="bg-green-600 text-white px-6 py-2 rounded-full mt-4 hover:bg-green-700 transition" 
//               onClick={() => uploadedFiles.length ? setPage("Job Description") : alert("Please upload at least one resume.")}>
//               Continue to JD
//             </button>
//           </div>
//         )}

//         {page === "Job Description" && (
//           <div>
//             <h1 className="text-2xl font-bold text-center text-blue-600 mb-8">Job Description</h1>
//             <textarea 
//               placeholder="Type the Job Description here" 
//               value={jobDescription} 
//               onChange={handleInputChange} 
//               className="w-full h-40 p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
//             ></textarea>
//             <button 
//               className="bg-green-600 text-white px-6 py-2 rounded-full mt-4 hover:bg-green-700 transition" 
//               onClick={() => jobDescription ? setPage("Evaluation") : alert("Please provide a job description.")}>
//               Continue to Evaluation
//             </button>
//           </div>
//         )}

//         {/* {page === "Recruiter Questions" && (
//           <div>
//             <h1 className="text-2xl font-bold text-center text-blue-600 mb-8">Recruiter Questions</h1>
//             <div className="space-y-4">
//               <input 
//                 name="location" 
//                 placeholder="1. Location of the Job" 
//                 value={answers.location || ''} 
//                 onChange={handleAnswerChange} 
//                 className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
//               />
//               <select 
//                 name="gender" 
//                 value={answers.gender || 'All'} 
//                 onChange={handleAnswerChange} 
//                 className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600">
//                 <option value="Male">Male</option>
//                 <option value="Female">Female</option>
//                 <option value="Others">Others</option>
//                 <option value="All">All</option>
//               </select>
//               <select 
//                 name="diversity_hiring" 
//                 value={answers.diversity_hiring || 'No'} 
//                 onChange={handleAnswerChange} 
//                 className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600">
//                 <option value="Yes">Yes</option>
//                 <option value="No">No</option>
//               </select>
//               <input 
//                 name="education_restriction" 
//                 placeholder="4. Education Restrictions" 
//                 value={answers.education_restriction || ''} 
//                 onChange={handleAnswerChange} 
//                 className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
//               />
//               <input 
//                 name="salary_range" 
//                 placeholder="5. Salary Range" 
//                 value={answers.salary_range || ''} 
//                 onChange={handleAnswerChange} 
//                 className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
//               />
//               <input 
//                 name="industry_experience" 
//                 placeholder="6. Industry Experience" 
//                 value={answers.industry_experience || ''} 
//                 onChange={handleAnswerChange} 
//                 className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
//               />
//               <select 
//                 name="notice_period" 
//                 value={answers.notice_period || 'No restriction'} 
//                 onChange={handleAnswerChange} 
//                 className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600">
//                 <option value="15 days">15 days</option>
//                 <option value="30 days">30 days</option>
//                 <option value="60 days">60 days</option>
//                 <option value="No restriction">No restriction</option>
//               </select>
//             </div>
//             <button 
//               className="bg-green-600 text-white px-6 py-2 rounded-full mt-8 hover:bg-green-700 transition" 
//               onClick={() => setPage("Evaluation")}>
//               Submit
//             </button>
//           </div>
//         )} */}


// {/* {page === "Recruiter Questions" && (
//           <div>
//             <h1 className="text-2xl font-bold text-center text-blue-600 mb-8">Recruiter Questions</h1>
//             <div className="space-y-6">
//               <div className="bg-gray-50 p-4 rounded-md shadow-sm">
//                 <label className="block text-gray-700 font-semibold mb-2">1. Location of the Job</label>
//                 <input 
//                   name="location" 
//                   placeholder="Enter job location" 
//                   value={answers.location || ''} 
//                   onChange={handleAnswerChange} 
//                   className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
//                 />
//               </div>
              
//               <div className="bg-gray-50 p-4 rounded-md shadow-sm">
//                 <label className="block text-gray-700 font-semibold mb-2">2. Gender</label>
//                 <select 
//                   name="gender" 
//                   value={answers.gender || 'All'} 
//                   onChange={handleAnswerChange} 
//                   className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600">
//                   <option value="Male">Male</option>
//                   <option value="Female">Female</option>
//                   <option value="Others">Others</option>
//                   <option value="All">All</option>
//                 </select>
//               </div>

//               <div className="bg-gray-50 p-4 rounded-md shadow-sm">
//                 <label className="block text-gray-700 font-semibold mb-2">3. Diversity Hiring</label>
//                 <select 
//                   name="diversity_hiring" 
//                   value={answers.diversity_hiring || 'No'} 
//                   onChange={handleAnswerChange} 
//                   className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600">
//                   <option value="Yes">Yes</option>
//                   <option value="No">No</option>
//                 </select>
//               </div>
              
//               <div className="bg-gray-50 p-4 rounded-md shadow-sm">
//                 <label className="block text-gray-700 font-semibold mb-2">4. Education Restrictions</label>
//                 <input 
//                   name="education_restriction" 
//                   placeholder="Specify education restrictions" 
//                   value={answers.education_restriction || ''} 
//                   onChange={handleAnswerChange} 
//                   className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
//                 />
//               </div>
              
//               <div className="bg-gray-50 p-4 rounded-md shadow-sm">
//                 <label className="block text-gray-700 font-semibold mb-2">5. Salary Range</label>
//                 <input 
//                   name="salary_range" 
//                   placeholder="Enter salary range" 
//                   value={answers.salary_range || ''} 
//                   onChange={handleAnswerChange} 
//                   className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
//                 />
//               </div>
              
//               <div className="bg-gray-50 p-4 rounded-md shadow-sm">
//                 <label className="block text-gray-700 font-semibold mb-2">6. Industry Experience</label>
//                 <input 
//                   name="industry_experience" 
//                   placeholder="Specify industry experience" 
//                   value={answers.industry_experience || ''} 
//                   onChange={handleAnswerChange} 
//                   className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
//                 />
//               </div>
              
//               <div className="bg-gray-50 p-4 rounded-md shadow-sm">
//                 <label className="block text-gray-700 font-semibold mb-2">7. Notice Period</label>
//                 <select 
//                   name="notice_period" 
//                   value={answers.notice_period || 'No restriction'} 
//                   onChange={handleAnswerChange} 
//                   className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600">
//                   <option value="15 days">15 days</option>
//                   <option value="30 days">30 days</option>
//                   <option value="60 days">60 days</option>
//                   <option value="No restriction">No restriction</option>
//                 </select>
//               </div>
//             </div>
//             <button 
//               className="bg-green-600 text-white px-6 py-2 rounded-full mt-8 hover:bg-green-700 transition" 
//               onClick={() => setPage("Evaluation")}>
//               Submit
//             </button>
//             <button 
//               className="bg-green-600 text-white px-6 py-2 rounded-full mt-8 hover:bg-green-700 transition" 
//               onClick={() => handleSubmit("evaluate")}>
//               Evaluate
//             </button>
//             <button 
//               className="bg-blue-600 text-white px-6 py-2 rounded-full mt-4 hover:bg-blue-700 transition" 
//               onClick={() => handleSubmit("top_10")}>
//               Top 10 Candidates
//             </button>
//             <button 
//               className="bg-purple-600 text-white px-6 py-2 rounded-full mt-4 hover:bg-purple-700 transition" 
//               onClick={() => handleSubmit("extract_details")}>
//               Extract Details
//             </button>
//           </div>
//         )} */}

//         {page === "Evaluation" && (
//           <div>
//             <h1 className="text-2xl font-bold text-center text-blue-600 mb-8">Evaluation</h1>
//             <div className="flex justify-around">
//               <button 
//                 className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition" 
//                 onClick={() => handleSubmit("evaluate")}>
//                 Evaluate Resume
//               </button>
//               <button 
//                 className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition" 
//                 onClick={() => handleSubmit("top10")}>
//                 Top 10 Candidates
//               </button>
//               <button 
//                 className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition" 
//                 onClick={() => handleSubmit("extract")}>
//                 Extract Details
//               </button>
//             </div>
//             <div className="mt-8">
//               <h2 className="text-xl font-semibold text-blue-600 mb-4">Results:</h2>
//               <pre className="bg-gray-100 p-4 rounded-md">{results}</pre>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ResumeParser;


//V3-----------------------Python backend integrated api code
// import React, { useState } from 'react';
// import axios from 'axios';

// const ResumeParser = () => {
//   const [page, setPage] = useState("Home");
//   const [uploadedFiles, setUploadedFiles] = useState([]);
//   const [jobDescription, setJobDescription] = useState("");
//   const [answers, setAnswers] = useState({});
//   const [results, setResults] = useState("");

//   const handleFileUpload = (e) => {
//     setUploadedFiles([...e.target.files]);
//   };

//   const handleInputChange = (e) => {
//     setJobDescription(e.target.value);
//   };

//   const handleAnswerChange = (e) => {
//     setAnswers({ ...answers, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (action) => {
//     const formData = new FormData();
//     uploadedFiles.forEach(file => formData.append('files', file));
//     formData.append('jobDescription', jobDescription);
//     formData.append('answers', JSON.stringify(answers));

//     try {
//       const response = await axios.post(`http://localhost:5001/${action}`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       setResults(response.data);
//     } catch (error) {
//       console.error("There was an error!", error);
//     }
//   };

//   return (
//     <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
//       <div className="w-full max-w-3xl bg-white shadow-lg rounded-lg p-8 mt-8">
//         {page === "Home" && (
//           <div>
//             <h1 className="text-3xl font-bold text-center text-blue-600 mb-8">Welcome to Yoddah AI ATS Resume Expert</h1>
//             <div className="flex justify-around">
//               <button 
//                 className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition" 
//                 onClick={() => setPage("Upload Resume")}>
//                 Bulk Upload Resume
//               </button>
//               <button 
//                 className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition" 
//                 onClick={() => alert("External DB Connection functionality to be implemented.")}>
//                 External DB Connection
//               </button>
//               <button 
//                 className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition" 
//                 onClick={() => alert("Manual Data Entry functionality to be implemented.")}>
//                 Manual Data Entry
//               </button>
//             </div>
//           </div>
//         )}

//         {page === "Upload Resume" && (
//           <div>
//             <h1 className="text-2xl font-bold text-center text-blue-600 mb-8">Upload Resumes</h1>
//             <input 
//               type="file"
//               name="resumes" 
//               multiple 
//               className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-600 hover:file:bg-blue-100" 
//               onChange={handleFileUpload} 
//             />
//             <button 
//               className="bg-green-600 text-white px-6 py-2 rounded-full mt-4 hover:bg-green-700 transition" 
//               onClick={() => uploadedFiles.length ? setPage("Job Description") : alert("Please upload at least one resume.")}>
//               Continue to JD
//             </button>
//           </div>
//         )}

//         {page === "Job Description" && (
//           <div>
//             <h1 className="text-2xl font-bold text-center text-blue-600 mb-8">Job Description</h1>
//             <textarea 
//               placeholder="Type the Job Description here" 
//               value={jobDescription} 
//               onChange={handleInputChange} 
//               className="w-full h-40 p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
//             ></textarea>
//             <button 
//               className="bg-green-600 text-white px-6 py-2 rounded-full mt-4 hover:bg-green-700 transition" 
//               onClick={() => jobDescription ? setPage("Evaluation") : alert("Please provide a job description.")}>
//               Continue to Evaluation
//             </button>
//           </div>
//         )}

//         {page === "Evaluation" && (
//           <div>
//             <h1 className="text-2xl font-bold text-center text-blue-600 mb-8">Evaluation</h1>
//             <div className="flex justify-around">
//               <button 
//                 className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition" 
//                 onClick={() => handleSubmit("evaluate_resumes")}>
//                 Evaluate Resume
//               </button>
//               <button 
//                 className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition" 
//                 onClick={() => handleSubmit("top10_candidates")}>
//                 Top 10 Candidates
//               </button>
//               <button 
//                 className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition" 
//                 onClick={() => handleSubmit("extract_resume_details")}>
//                 Extract Details
//               </button>
//             </div>
//             <div className="mt-8">
//               <h2 className="text-xl font-semibold text-blue-600 mb-4">Results:</h2>
//               <pre className="bg-gray-100 p-4 rounded-md">{results}</pre>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ResumeParser;

//=======================candidate details proper section====================
 /* {candidateDetails && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
      <div className="bg-white shadow-md rounded-md p-4">
        <h3 className="text-xl font-bold mb-2">Name</h3>
        <p className="flex-1">{candidateDetails.name}</p> <div className='flex-1'> </div>
      </div>
      <div className="bg-white shadow-md rounded-md p-4">
        <h3 className="text-xl font-bold mb-2">Matching Percentage</h3>
        <p className="flex-1">{candidateDetails.matchingPercentage}%</p><div className='flex-1'> </div>
      </div>
      <div className="bg-white shadow-md rounded-md p-4">
        <h3 className="text-xl font-bold mb-2">Phone</h3>
        <p className="flex-1">{candidateDetails.phone}</p><div className='flex-1'> </div>
      </div>
      <div className="bg-white shadow-md rounded-md p-4">
        <h3 className="text-xl font-bold mb-2">Email</h3>
        <p className="flex-1">{candidateDetails.email}</p><div className='flex-1'> </div>
      </div>
      <div className="bg-white shadow-md rounded-md p-4">
        <h3 className="text-xl font-bold mb-2">Skills</h3>
        <ul className="list-disc pl-5"> 
          {candidateDetails.skills.map((skill) => (
            <li key={skill}>{skill}</li>
          ))}
        </ul><div className='flex-1'> </div>
      </div>
      <div className="bg-white shadow-md rounded-md p-4">
        <h3 className="text-xl font-bold mb-2">Strengths</h3>
        <ul className="list-disc pl-5"> 
          {candidateDetails.strengths.map((strength) => (
            <li key={strength}>{strength}</li>
          ))}
        </ul>
      </div>
      <div className="bg-white shadow-md rounded-md p-4">
        <h3 className="text-xl font-bold mb-2">Weaknesses</h3> 
        <ul className="list-disc pl-5"> 
          {candidateDetails.weaknesses.map((weakness) => (
            <li key={weakness}>{weakness}</li>
          ))}
        </ul><div className='flex-1'> </div>
      </div>
    </div>
      )} */

//========new one with summary and later going to change it.------
// import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { AIChatSession } from '../../service/AIModel'; 
// import pdfToText from "react-pdftotext";
// import {toast} from 'react-toastify';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

// function ResumeParser() {
//   const [resumeContent, setResumeContent] = useState('');
//   const [jobDescription, setJobDescription] = useState('');
//   const [isLoading, setIsLoading] = useState(false); // Add loading state
//   const [candidateDetails, setCandidateDetails] = useState(null);
//   const navigate = useNavigate();

//   const prompt = `Extract candidate details from this resume: ${resumeContent} Considering the job description: ${jobDescription} and match the Resumecontent with Jobdescription and return it as Matching Percentage, Strengths in precise line and Weaknesses in precise lines, calculate the years of experience from candidate's experiences and return only Key top 10 skills, Find the Strengths and Weakness matching to Job description, and provide your suggesstions in Bullet Points with improvements points or interview points for recruiter as well . Return the following details in JSON format:{name: "Candidate Name", matchingPercentage: 85, phone: "1234567890",email: "candidate@example.com", currentLocation:"Eg. Bengaluru", skills: ["Skill 1", "Skill 2", "Skill 3"], education:["Education1", "Education2"], experienceYears: 5, workExperience: ["Experience1", "Experience2"], strengths: ["Strength 1", "Strength 2"],weaknesses: ["Weakness 1","Weakness2"], YoddahSuggestion:["suggestion 1", "suggestion 2", "suggestion 3"]}`; 

//   // Function to extract text from a PDF file
//   const extractTextFromPDF = (event)=> {
//     console.log("inside extract from pdf")
//     const file = event.target.files[0]
//     return pdfToText(file)
//         .then(text => {
//           setResumeContent(text);
//           console.log("Extracted Text in method extractTextFromPDF", text);
//         })
//         .catch(error => console.error("Failed to extract text from pdf"))
//   }

//    // Function to handle resume upload and text extraction
//    const handleResumeUpload = async (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       try {
//         toast.success("File Upload successful!");
//         const extractedText = extractTextFromPDF(event);
//         console.log("extracted Text : ", extractedText);
    
//       } catch (error) {
//         console.error('Error extracting text from PDF:', error);
//       }
//     }
//   };

//   const handleDownloadPDF = () => {
//     const input = document.getElementById('pdf-content');

//     html2canvas(input).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF('p', 'mm', 'a4');
//       const imgWidth = 210; // A4 width in mm
//       const pageHeight = 297; // A4 height in mm
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;
//       let heightLeft = imgHeight;
//       let position = 0;

//       pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
//       heightLeft -= pageHeight;

//       while (heightLeft >= 0) {
//         position = heightLeft - imgHeight;
//         pdf.addPage();
//         pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
//         heightLeft -= pageHeight;
//       }

//       pdf.save(`${candidateDetails.name}_Extracted_Report.pdf`); // Specify the name of the downloaded PDF file
//       toast.success(`${candidateDetails.name}'s Report Generated successful!`);
//     });
//   };

//   // Function to generate candidate details from AI
//   const generateCandidateDetails = async () => {
//     console.log('generateCandidateDetails function called!');
//     setIsLoading(true);

//     try {
//       prompt.replace('{resumeContent}', resumeContent);
//       const response = await AIChatSession.sendMessage(prompt);
//       console.log('API Response:', response); // Log the response 
//       const candidateData = JSON.parse(response.response.text());
//       setCandidateDetails(candidateData);
//     } catch (error) {
//       console.error('Error generating candidate details:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="container mx-auto p-4 bg-white">
//       <h1 className="text-3xl font-bold mb-4">Resume Parser</h1>

//       <div className="bg-white shadow-xl rounded-md p-4">
//         <h2 className="text-xl font-bold mb-2">Upload Resume</h2>
//         <input
//           type="file"
//           accept="application/pdf"
//           onChange={handleResumeUpload}
//           className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-600 hover:file:bg-blue-100" 
//         />
//       </div>

//       <div className="bg-white shadow-xl rounded-md p-4 mt-4">
//         <h2 className="text-xl font-bold mb-2">Job Description</h2>
//         <textarea
//           value={jobDescription}
//           onChange={(e) => setJobDescription(e.target.value)}
//           className="border border-gray-300 rounded-md p-2 w-full"
//           rows="5"
//           placeholder="Enter the job description..."
//         ></textarea>
//       </div>

//       <div className="bg-white shadow-xl rounded-md p-4 mt-4">
//         <button 
//           className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-800 transition" 
//           onClick={generateCandidateDetails}
//           disabled={isLoading || !resumeContent || !jobDescription}
//           // disabled={isLoading}
//           >
//           Generate Candidate Details
//         </button>
//         <button 
//           className="bg-green-500 text-white ml-4 px-6 py-2 rounded-full hover:bg-green-800 transition" 
//           onClick={handleDownloadPDF}
//           disabled={isLoading || !resumeContent || !jobDescription}
//           // disabled={isLoading}
//           >
//           Generate Candidate PDF Report
//         </button>
//       </div>
//       {/* Loading Screen */}
//     {isLoading && ( 
//       <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50"> 
//         <div className="bg-white p-4 rounded-md shadow-md">
//           <div className="flex items-center">
//             <svg
//               className="animate-spin h-5 w-5 mr-3"
//               viewBox="0 0 24 24"
//             >
//               <circle
//                 className="opacity-25"
//                 cx="12"
//                 cy="12"
//                 r="10"
//                 stroke="currentColor"
//                 strokeWidth="4"
//               ></circle>
//               <path
//                 className="opacity-75"
//                 fill="none"
//                 stroke="currentColor"
//                 strokeWidth="2"
//                 d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0C0 5.373 5.373 0 12 0v4m8 11.451c0 1.291-.873 2.403-2.04 2.857H7A2 2 0 015 18c-1.291 0-2.403-.873-2.857-2.04H3M11 7h2m-4 0h2"
//               ></path>
//             </svg>
//             <span className="text-green-500 font-bold">Yoddah AI Parsing...</span> 
//           </div>
//         </div>
//       </div>
//     )}

//       {candidateDetails && (
//          <div id="pdf-content" className="bg-white shadow-lg rounded-lg p-6 mt-4">
//          <div className="flex items-center mb-4">
//            <div className="ml-1">
//              <h2 className="text-xl font-bold text-gray-800">
//                {candidateDetails.name}
//              </h2>
//              {
            
//                candidateDetails.matchingPercentage>=70? 
//                (<p className=" text-xl font-bold text-green-500">{candidateDetails.matchingPercentage}% Match</p>)
//                :
//               (<p className=" text-xl font-bold text-orange-600">{candidateDetails.matchingPercentage}% Match</p>)
            
//             }
              
//            </div>
//          </div>
//          <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700'/>
//          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
//            <div>
//              <p className="font-bold text-gray-800">Email:</p>
//              <p className="text-gray-600">{candidateDetails.email}</p>
//            </div>
//            <div>
//              <p className="font-bold text-gray-800">Phone:</p>
//              <p className="text-gray-600">{candidateDetails.phone}</p>
//            </div>
//            <div>
//              <p className="font-bold text-gray-800">Current Location:</p>
//              <p className="text-gray-600">{candidateDetails.currentLocation}</p>
//            </div>
//          </div>
//             <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700'/>
   
//          <div className="mt-4">
//               <p className="font-bold text-gray-800">Skills:</p>
//               <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1">
//                   {candidateDetails.skills.map((skill, index) => (
//                     <div key={index} className="break-inside-avoid custom-bullet pl-5">
//                           <ul className="list-disc  text-gray-600">
//                               <li>{skill}</li>
//                           </ul>
//                       </div>
//                   ))}
//               </div>

        
//          </div>
//                   <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700'/>
//          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//          <div className="mt-4">
//            <p className="font-bold text-gray-800">Education:</p>
//              {candidateDetails.education.map((education, index) => (
//               <div key={index} className="break-inside-avoid custom-bullet pl-5">
//                 <ul className="list-disc pl-5 text-gray-600">
//                     <li key={education}>{education}</li>
//                 </ul>
//               </div>
//               ))}
//          </div>
//          <div className="mt-4">
//            <p className="font-bold text-gray-800">Work Experience:</p>
//              {candidateDetails.workExperience.map((exp, index) => (
//               <div key={index} className="break-inside-avoid custom-bullet pl-5">
//                 <ul className="list-disc pl-5 text-gray-600 custom-bullet">
//                     <li key={exp}>{exp}</li>
//                 </ul>
//               </div>
//              ))}
//          </div>
//          </div>
//               <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700'/>
//          <div className="mt-4">
//            <p className="font-bold text-gray-800">Years of Experience:</p>
//              {/* <p className="text-gray-600">{candidateDetails.experienceYears}</p> */}
//              {
            
//             candidateDetails.experienceYears>=7? 
//             (<p className=" text-xl font-bold text-green-500">{candidateDetails.experienceYears} years</p>)
//             :
//            (<p className=" text-xl font-bold text-orange-600">{candidateDetails.experienceYears} years</p>)
         
//          }
//          </div>
//               <hr className='h-px my-8 bg-gray-100 border-0 dark:bg-gray-700'/>
//          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        
   
//          <div className="mt-4">
//            <p className="font-bold text-gray-800">Strengths:</p>
//           {candidateDetails.strengths.map((strength, index) => (
//             <div key={index} className="break-inside-avoid custom-bullet pl-5">
//               <ul className="list-disc pl-5"> 
//                 <li key={strength}>{strength}</li>
//               </ul>
//             </div>
//           ))}
//          </div>
//          {/* {candidateDetails.weakness && ( */}
//           <div className="mt-4">
//             <p className="font-bold text-gray-800 ">Weaknesses:</p>
//             {candidateDetails.weaknesses.map((weakness, index) => (
//               <div key={index} className="break-inside-avoid custom-bullet pl-5">
//                 <ul className="list-disc pl-5"> 
//                   <li key={weakness}>{weakness}</li>
//                 </ul>
//               </div>
//             ))}
//          </div>
//          {/* )} */}
//          </div>
   
//             <hr className='h-px my-8 bg-gray-100 border-0 dark:bg-gray-700'/>
         
//          <div className="mt-4">
//            <p className="font-bold text-green-600">Yoddah AI Suggestion:</p>
//             {candidateDetails.YoddahSuggestion.map((YoddahSuggestion, index) => (
//               <div key={index} className="break-inside-avoid custom-bullet pl-5">
//                 <ul className="list-disc pl-5"> 
//                   <li key={YoddahSuggestion}>{YoddahSuggestion}</li>
//                 </ul>
//                 </div>
//             ))}
//            {/* <p className="text-gray-600">{candidateDetails.YoddahSuggestion}</p> */}
//          </div>
//        </div>
//       )}
//     </div>

    
//   );
// }

// export default ResumeParser;

//--------------------below works fine---------------------

// import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { AIChatSession } from '../../service/AIModel'; 
// import pdfToText from "react-pdftotext";
// import {toast} from 'react-toastify';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import { TbReload } from "react-icons/tb";

// function ResumeParser() {
//   const [resumeContent, setResumeContent] = useState(localStorage.getItem('resumeContent') || '');
//   const [jobDescription, setJobDescription] = useState(localStorage.getItem('jobDescription') || '');
//   const [isLoading, setIsLoading] = useState(false); 
//   const [candidateDetails, setCandidateDetails] = useState(JSON.parse(localStorage.getItem('candidateDetails')) || []); 
//   const navigate = useNavigate();

//   const prompt = `Extract candidate details from this resume: ${resumeContent} Considering the job description: ${jobDescription} and match the Resumecontent with Jobdescription and return it as Matching Percentage, Strengths in precise line and Weaknesses in precise lines, calculate the years of experience from candidate's experiences and return only Key top 10 skills, Find the Strengths and Weakness matching to Job description, and provide your suggesstions in Bullet Points with improvements points or interview points for recruiter as well . Return the following details in JSON format:{name: "Candidate Name", matchingPercentage: 85, phone: "1234567890",email: "candidate@example.com", currentLocation:"Eg. Bengaluru", skills: ["Skill 1", "Skill 2", "Skill 3"], education:["Education1", "Education2"], experienceYears: 5, workExperience: ["Experience1", "Experience2"], strengths: ["Strength 1", "Strength 2"],weaknesses: ["Weakness 1","Weakness2"], YoddahSuggestion:["suggestion 1", "suggestion 2", "suggestion 3"]}`; 

//   // Function to extract text from a PDF file
//   const extractTextFromPDF = (event)=> {
//     console.log("inside extract from pdf")
//     const file = event.target.files[0]
//     return pdfToText(file)
//         .then(text => {
//           setResumeContent(text);
//           localStorage.setItem('resumeContent', text); // Store in local storage
//           console.log("Extracted Text in method extractTextFromPDF", text);
//         })
//         .catch(error => console.error("Failed to extract text from pdf"))
//   }

//    // Function to handle resume upload and text extraction
//    const handleResumeUpload = async (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       try {
//         toast.success("File Upload successful!");
//         const extractedText = extractTextFromPDF(event);
//         console.log("extracted Text : ", extractedText);
    
//       } catch (error) {
//         console.error('Error extracting text from PDF:', error);
//       }
//     }
//   };

//   const handleDownloadPDF = () => {
//     const input = document.getElementById('pdf-content');

//     html2canvas(input).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF('p', 'mm', 'a4');
//       const imgWidth = 210; // A4 width in mm
//       const pageHeight = 297; // A4 height in mm
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;
//       let heightLeft = imgHeight;
//       let position = 0;

//       pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
//       heightLeft -= pageHeight;

//       while (heightLeft >= 0) {
//         position = heightLeft - imgHeight;
//         pdf.addPage();
//         pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
//         heightLeft -= pageHeight;
//       }

//       pdf.save(`${candidateDetails.name}_Extracted_Report.pdf`); // Specify the name of the downloaded PDF file
//       toast.success(`${candidateDetails.name}'s Report Generated successful!`);
//     });
//   };

//   // Function to generate candidate details from AI
//   const generateCandidateDetails = async () => {
//     console.log('generateCandidateDetails function called!');
//     setIsLoading(true);

//     try {
//       prompt.replace('{resumeContent}', resumeContent);
//       const response = await AIChatSession.sendMessage(prompt);
//       console.log('API Response:', response); // Log the response 
//       const candidateData = JSON.parse(response.response.text());
//       setCandidateDetails(candidateData);
//       localStorage.setItem('candidateDetails', JSON.stringify(candidateData));
//     } catch (error) {
//       console.error('Error generating candidate details:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const resetCandidateDetails = async () => {
//     console.log('resetCandidateDetails function called!');
    
//     try {
//       setIsLoading(false);
//       localStorage.removeItem('resumeContent');
//       localStorage.removeItem('jobDescription');
//       localStorage.removeItem('candidateDetails');
//       setResumeContent('');
//       setJobDescription('');
//       setCandidateDetails(null);
//       toast.success("Contents of the Page Cleared!")
//     } catch (error) {
//       console.error('Error Resetting candidate details:', error);
//     } 
//   };

//   return (
//     <div className="container mx-auto p-4 bg-white">
//       <h1 className="text-3xl font-bold mb-4">Resume Parser</h1>

//       <div className="bg-white shadow-xl rounded-md p-4">
     
//         <h2 className="text-xl font-bold mb-2">Upload Resume</h2>
//         <input
//           type="file"
//           accept="application/pdf"
//           onChange={handleResumeUpload}
//           className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-600 hover:file:bg-blue-100" 
//         />
         
//       </div>

//       <div className="bg-white shadow-xl rounded-md p-4 mt-4">
//         <h2 className="text-xl font-bold mb-2">Job Description</h2>
//         <textarea
//           value={jobDescription}
//           onChange={(e) => {
//             setJobDescription(e.target.value);
//             localStorage.setItem('jobDescription', e.target.value);
//           }}
//           className="border border-gray-300 rounded-md p-2 w-full"
//           rows="5"
//           placeholder="Enter the job description..."
//         ></textarea>
//       </div>

//       <div className="bg-white shadow-xl rounded-md p-4 mt-4">
//       <button 
//           className="bg-white-500 text-black ml-4 px-6 py-2 rounded-full hover:bg-blue-300 transition" 
//           onClick={resetCandidateDetails}
//           disabled={isLoading || !resumeContent || !jobDescription}
//           // disabled={isLoading}
//           ><TbReload size={24}/>
//         </button>
//         <button 
//           className="bg-blue-500 text-white ml-4 px-6 py-2 rounded-full hover:bg-blue-800 transition" 
//           onClick={generateCandidateDetails}
//           disabled={isLoading || !resumeContent || !jobDescription}
//           // disabled={isLoading}
//           >
//           Generate Candidate Details
//         </button>
//         <button 
//           className="bg-green-500 text-white ml-4 px-6 py-2 rounded-full hover:bg-green-800 transition" 
//           onClick={handleDownloadPDF}
//           disabled={isLoading || !resumeContent || !jobDescription}
//           // disabled={isLoading}
//           >
//           Generate Candidate PDF Report
//         </button>
       
//       </div>
//       {/* Loading Screen */}
//     {isLoading && ( 
//       <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50"> 
//         <div className="bg-white p-4 rounded-md shadow-md">
//           <div className="flex items-center">
//             <svg
//               className="animate-spin h-5 w-5 mr-3"
//               viewBox="0 0 24 24"
//             >
//               <circle
//                 className="opacity-25"
//                 cx="12"
//                 cy="12"
//                 r="10"
//                 stroke="currentColor"
//                 strokeWidth="4"
//               ></circle>
//               <path
//                 className="opacity-75"
//                 fill="none"
//                 stroke="currentColor"
//                 strokeWidth="2"
//                 d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0C0 5.373 5.373 0 12 0v4m8 11.451c0 1.291-.873 2.403-2.04 2.857H7A2 2 0 015 18c-1.291 0-2.403-.873-2.857-2.04H3M11 7h2m-4 0h2"
//               ></path>
//             </svg>
//             <span className="text-green-500 font-bold">Yoddah AI Parsing...</span> 
//           </div>
//         </div>
//       </div>
//     )}

//       {candidateDetails && (
//          <div id="pdf-content" className="bg-white shadow-lg rounded-lg p-6 mt-4">
//          <div className="flex items-center mb-4">
//            <div className="ml-1">
//              <h2 className="text-xl font-bold text-gray-800">
//                {candidateDetails.name}
//              </h2>
//              {
            
//                candidateDetails.matchingPercentage>=70? 
//                (<p className=" text-xl font-bold text-green-500">{candidateDetails.matchingPercentage}% Match</p>)
//                :
//               (<p className=" text-xl font-bold text-orange-600">{candidateDetails.matchingPercentage}% Match</p>)
            
//             }
              
//            </div>
//          </div>
//          <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700'/>
//          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
//            <div>
//              <p className="font-bold text-gray-800">Email:</p>
//              <p className="text-gray-600">{candidateDetails.email}</p>
//            </div>
//            <div>
//              <p className="font-bold text-gray-800">Phone:</p>
//              <p className="text-gray-600">{candidateDetails.phone}</p>
//            </div>
//            <div>
//              <p className="font-bold text-gray-800">Current Location:</p>
//              <p className="text-gray-600">{candidateDetails.currentLocation}</p>
//            </div>
//          </div>

//          <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700'/>
   
//          {candidateDetails && candidateDetails.skills && (
//             <div className="mt-4">
//                   <p className="font-bold text-gray-800">Skills:</p>
//                   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1">
//                       {candidateDetails.skills.map((skill, index) => (
//                         <div key={index} className="break-inside-avoid custom-bullet pl-5">
//                               <ul className="list-disc  text-gray-600">
//                                   <li>{skill}</li>
//                               </ul>
//                           </div>
//                       ))}
//                   </div>
//             </div>
//           )}

//          <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700'/>

//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//             {candidateDetails && candidateDetails.education && (
//               <div className="mt-4">
//                 <p className="font-bold text-gray-800">Education:</p>
//                   {candidateDetails.education.map((education, index) => (
//                     <div key={index} className="break-inside-avoid custom-bullet pl-5">
//                       <ul className="list-disc pl-5 text-gray-600">
//                           <li key={education}>{education}</li>
//                       </ul>
//                     </div>
//                     ))}
//               </div>
//             )}
            
//             {candidateDetails && candidateDetails.workExperience &&(
//               <div className="mt-4">
//                 <p className="font-bold text-gray-800">Work Experience:</p>
//                 {candidateDetails.workExperience.map((exp, index) => (
//                   <div key={index} className="break-inside-avoid custom-bullet pl-5">
//                     <ul className="list-disc pl-5 text-gray-600 custom-bullet">
//                         <li key={exp}>{exp}</li>
//                     </ul>
//                   </div>
//                 ))}
//               </div>
//             )}
            
//           </div>

//          <hr className='h-px my-8 bg-gray-100 border-0 dark:bg-gray-700'/>

//          <div className="mt-4">
//            <p className="font-bold text-gray-800">Years of Experience:</p>
//              {/* <p className="text-gray-600">{candidateDetails.experienceYears}</p> */}
//              {
            
//             candidateDetails.experienceYears>=7? 
//             (<p className=" text-xl font-bold text-green-500">{candidateDetails.experienceYears} years</p>)
//             :
//            (<p className=" text-xl font-bold text-orange-600">{candidateDetails.experienceYears} years</p>)
         
//          }
//          </div>

//          <hr className='h-px my-8 bg-gray-100 border-0 dark:bg-gray-700'/>

//          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//           {candidateDetails && candidateDetails.strengths  &&(
//             <div className="mt-4">
//               <p className="font-bold text-gray-800">Strengths:</p>
//               {candidateDetails.strengths.map((strength, index) => (
//                 <div key={index} className="break-inside-avoid custom-bullet pl-5">
//                   <ul className="list-disc pl-5"> 
//                     <li key={strength}>{strength}</li>
//                   </ul>
//                 </div>
//               ))}
//             </div>
//           )}
//          {candidateDetails && candidateDetails.weaknesses  &&(
//             <div className="mt-4">
//               <p className="font-bold text-gray-800 ">Weaknesses:</p>
//               {candidateDetails.weaknesses.map((weakness, index) => (
//                 <div key={index} className="break-inside-avoid custom-bullet pl-5">
//                   <ul className="list-disc pl-5"> 
//                     <li key={weakness}>{weakness}</li>
//                   </ul>
//                 </div>
//               ))}
//             </div>
//           )} 
//          </div>
   
//          <hr className='h-px my-8 bg-gray-100 border-0 dark:bg-gray-700'/>
         
//           {candidateDetails && candidateDetails.YoddahSuggestion && (
//             <div className="mt-4">
//               <p className="font-bold text-green-600">Yoddah AI Suggestion:</p>
//                 {candidateDetails.YoddahSuggestion.map((YoddahSuggestion, index) => (
//                   <div key={index} className="break-inside-avoid custom-bullet pl-5">
//                     <ul className="list-disc pl-5"> 
//                       <li key={YoddahSuggestion}>{YoddahSuggestion}</li>
//                     </ul>
//                     </div>
//                 ))}
//                 {/* <p className="text-gray-600">{candidateDetails.YoddahSuggestion}</p> */}
//             </div>
//           )}
//        </div>
//       )}
//     </div>

    
//   );
// }

// export default ResumeParser;


import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AIChatSession } from '../../service/AIModel'; 
import pdfToText from "react-pdftotext";
import {toast} from 'react-toastify';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { TbReload } from "react-icons/tb";
import axios from 'axios'; 

const ResumeParser = () => {
  const [resumeContent, setResumeContent] = useState(localStorage.getItem('resumeContent') || '');
  const [jobDescription, setJobDescription] = useState(localStorage.getItem('jobDescription') || '');
  const [isLoading, setIsLoading] = useState(false); 
  const [candidateDetails, setCandidateDetails] = useState(JSON.parse(localStorage.getItem('candidateDetails')) || []); 
  const navigate = useNavigate();

  const prompt = `Extract candidate details from this resume: ${resumeContent} Considering the job description: ${jobDescription} and match the Resumecontent with Jobdescription and return it as Matching Percentage, Strengths in precise line and Weaknesses in precise lines, calculate the years of experience from candidate's experiences and return only Key top 10 skills, Find the Strengths and Weakness matching to Job description, and provide your suggesstions in Bullet Points with improvements points or interview points for recruiter as well . Return the following details in JSON format:{name: "Candidate Name", matchingPercentage: 85, phone: "1234567890",email: "candidate@example.com", currentLocation:"Eg. Bengaluru", skills: ["Skill 1", "Skill 2", "Skill 3"], education:["Education1", "Education2"], experienceYears: 5, workExperience: ["Experience1", "Experience2"], strengths: ["Strength 1", "Strength 2"],weaknesses: ["Weakness 1","Weakness2"], YoddahSuggestion:["suggestion 1", "suggestion 2", "suggestion 3"]}; `

  // Function to extract text from a PDF file
  const extractTextFromPDF = (event)=> {
    console.log("inside extract from pdf")
    const file = event.target.files[0]
    return pdfToText(file)
        .then(text => {
          setResumeContent(text);
          localStorage.setItem('resumeContent', text); // Store in local storage
          console.log("Extracted Text in method extractTextFromPDF", text);
        })
        .catch(error => console.error("Failed to extract text from pdf"))
  }

   // Function to handle resume upload and text extraction
   const handleResumeUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // toast.success("File Upload successful!");
        // Create the FormData object 
      const formData = new FormData(); 

      // Append the file to the formData object
      formData.append('resume', file);


      
      const extractedText = extractTextFromPDF(event);
      console.log("extracted Text : ", extractedText);
      // Upload resume to S3 bucket
   

      // Send the file to your server-side API
      const response = await axios.post(`${process.env.REACT_APP_SERVER_API_URL}/api/upload-resume`,formData, file)
      // const response = await fetch(`${process.env.REACT_APP_SERVER_API_URL}/api/upload-resume`, {
      //   method: 'POST',
      //   body: formData,
      // });

      console.log(`Response Status: ${response.status}, Response Status Text: ${response.statusText}, Response Status Text Data: ${response.statusText.data}, response: ${response}`)

      if (response.statusText==="OK") {
        toast.success('Resume uploaded to Yoddah S3 Storage');
      } else if(response.status===250) {
        toast.info('Resume already exists in Yoddah S3 Storage!');
      } else {
        toast.error('Failed to upload resume to Yoddah S3 Storage.');
      }
        
      } catch (error) {
        console.error('Error extracting text from PDF:', error);
      }
    }
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById('pdf-content');

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${candidateDetails.name}_Extracted_Report.pdf`); // Specify the name of the downloaded PDF file
      toast.success(`${candidateDetails.name}'s Report Generated successful!`);
    });
  };

  // Function to generate candidate details from AI
  const generateCandidateDetails = async () => {
    console.log('generateCandidateDetails function called!');
    setIsLoading(true);

    try {
      prompt.replace('{resumeContent}', resumeContent);
      const response = await AIChatSession.sendMessage(prompt);
      console.log('API Response:', response); // Log the response 
      const candidateData = JSON.parse(response.response.text());
      setCandidateDetails(candidateData);
      localStorage.setItem('candidateDetails', JSON.stringify(candidateData));
      // Store candidate details in MongoDB
      await saveCandidateDetailsToMongoDB(candidateData); // You'll need to implement this function
    } catch (error) {
      console.error('Error generating candidate details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetCandidateDetails = async () => {
    console.log('resetCandidateDetails function called!');
    
    try {
      setIsLoading(false);
      localStorage.removeItem('resumeContent');
      localStorage.removeItem('jobDescription');
      localStorage.removeItem('candidateDetails');
      setResumeContent('');
      setJobDescription('');
      setCandidateDetails(null);
      toast.success("Contents of the Page Cleared!")
    } catch (error) {
      console.error('Error Resetting candidate details:', error);
    } 
  };

 



  // Function to save candidate details to MongoDB (checking for duplicates)
const saveCandidateDetailsToMongoDB = async (candidateData) => {
  try {
    // Check if candidate with same email exists
    const existingCandidate = await axios.get(
      `${process.env.REACT_APP_SERVER_API_URL}/api/parsed_candidates/email/${candidateData.email}`
    );

    if (existingCandidate.data) {
      toast.info('Candidate with this email already exists in the database.');
      return; // Do not save if duplicate
    }

    // If no duplicate found, save the candidate
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_API_URL}/api/parsed_candidates`,
      candidateData
    );

    if (response.status === 201) {
      toast.success('Candidate details saved to MongoDB!');
    } else {
      toast.error('Failed to save candidate details.');
    }
  } catch (error) {
    console.error('Error saving candidate details:', error);
    toast.error('Error saving candidate details.');
  }
};

  return (
    <div className="container mx-auto p-4 bg-white">
      <h1 className="text-3xl font-bold mb-4">Resume Parser</h1>

      <div className="bg-white shadow-xl rounded-md p-4">
     
        <h2 className="text-xl font-bold mb-2">Upload Resume</h2>
        <input
          type="file"
          accept="application/pdf"
          onChange={handleResumeUpload}
          className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-600 hover:file:bg-blue-100" 
        />
         
      </div>

      <div className="bg-white shadow-xl rounded-md p-4 mt-4">
        <h2 className="text-xl font-bold mb-2">Job Description</h2>
        <textarea
          value={jobDescription}
          onChange={(e) => {
            setJobDescription(e.target.value);
            localStorage.setItem('jobDescription', e.target.value);
          }}
          className="border border-gray-300 rounded-md p-2 w-full"
          rows="5"
          placeholder="Enter the job description..."
        ></textarea>
      </div>

      <div className="bg-white shadow-xl rounded-md p-4 mt-4">
      <button 
          className="bg-white-500 text-black ml-4 px-6 py-2 rounded-full hover:bg-blue-300 transition" 
          onClick={resetCandidateDetails}
          disabled={isLoading || !resumeContent || !jobDescription}
          // disabled={isLoading}
          ><TbReload size={24}/>
        </button>
        <button 
          className="bg-blue-500 text-white ml-4 px-6 py-2 rounded-full hover:bg-blue-800 transition" 
          onClick={generateCandidateDetails}
          disabled={isLoading || !resumeContent || !jobDescription}
          // disabled={isLoading}
          >
          Generate Candidate Details
        </button>
        <button 
          className="bg-green-500 text-white ml-4 px-6 py-2 rounded-full hover:bg-green-800 transition" 
          onClick={handleDownloadPDF}
          disabled={isLoading || !resumeContent || !jobDescription}
          // disabled={isLoading}
          >
          Generate Candidate PDF Report
        </button>
       
      </div>
      {/* Loading Screen */}
    {isLoading && ( 
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50"> 
        <div className="bg-white p-4 rounded-md shadow-md">
          <div className="flex items-center">
            <svg
              className="animate-spin h-5 w-5 mr-3"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0C0 5.373 5.373 0 12 0v4m8 11.451c0 1.291-.873 2.403-2.04 2.857H7A2 2 0 015 18c-1.291 0-2.403-.873-2.857-2.04H3M11 7h2m-4 0h2"
              ></path>
            </svg>
            <span className="text-green-500 font-bold">Yoddah AI Parsing...</span> 
          </div>
        </div>
      </div>
    )}

      {candidateDetails && (
         <div id="pdf-content" className="bg-white shadow-lg rounded-lg p-6 mt-4">
         <div className="flex items-center mb-4">
           <div className="ml-1">
             <h2 className="text-xl font-bold text-gray-800">
               {candidateDetails.name}
             </h2>
             {
            
               candidateDetails.matchingPercentage>=70? 
               (<p className=" text-xl font-bold text-green-500">{candidateDetails.matchingPercentage}% Match</p>)
               :
              (<p className=" text-xl font-bold text-orange-600">{candidateDetails.matchingPercentage}% Match</p>)
            
            }
              
           </div>
         </div>
         <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700'/>
         <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
           <div>
             <p className="font-bold text-gray-800">Email:</p>
             <p className="text-gray-600">{candidateDetails.email}</p>
           </div>
           <div>
             <p className="font-bold text-gray-800">Phone:</p>
             <p className="text-gray-600">{candidateDetails.phone}</p>
           </div>
           <div>
             <p className="font-bold text-gray-800">Current Location:</p>
             <p className="text-gray-600">{candidateDetails.currentLocation}</p>
           </div>
         </div>

         <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700'/>
   
         {candidateDetails && candidateDetails.skills && (
            <div className="mt-4">
                  <p className="font-bold text-gray-800">Skills:</p>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1">
                      {candidateDetails.skills.map((skill, index) => (
                        <div key={index} className="break-inside-avoid custom-bullet pl-5">
                              <ul className="list-disc  text-gray-600">
                                  <li>{skill}</li>
                              </ul>
                          </div>
                      ))}
                  </div>
            </div>
          )}

         <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700'/>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {candidateDetails && candidateDetails.education && (
              <div className="mt-4">
                <p className="font-bold text-gray-800">Education:</p>
                  {candidateDetails.education.map((education, index) => (
                    <div key={index} className="break-inside-avoid custom-bullet pl-5">
                      <ul className="list-disc pl-5 text-gray-600">
                          <li key={education}>{education}</li>
                      </ul>
                    </div>
                    ))}
              </div>
            )}
            
            {candidateDetails && candidateDetails.workExperience &&(
              <div className="mt-4">
                <p className="font-bold text-gray-800">Work Experience:</p>
                {candidateDetails.workExperience.map((exp, index) => (
                  <div key={index} className="break-inside-avoid custom-bullet pl-5">
                    <ul className="list-disc pl-5 text-gray-600 custom-bullet">
                        <li key={exp}>{exp}</li>
                    </ul>
                  </div>
                ))}
              </div>
            )}
            
          </div>

         <hr className='h-px my-8 bg-gray-100 border-0 dark:bg-gray-700'/>

         <div className="mt-4">
           <p className="font-bold text-gray-800">Years of Experience:</p>
             {/* <p className="text-gray-600">{candidateDetails.experienceYears}</p> */}
             {
            
            candidateDetails.experienceYears>=7? 
            (<p className=" text-xl font-bold text-green-500">{candidateDetails.experienceYears} years</p>)
            :
           (<p className=" text-xl font-bold text-orange-600">{candidateDetails.experienceYears} years</p>)
         
         }
         </div>

         <hr className='h-px my-8 bg-gray-100 border-0 dark:bg-gray-700'/>

         <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {candidateDetails && candidateDetails.strengths  &&(
            <div className="mt-4">
              <p className="font-bold text-gray-800">Strengths:</p>
              {candidateDetails.strengths.map((strength, index) => (
                <div key={index} className="break-inside-avoid custom-bullet pl-5">
                  <ul className="list-disc pl-5"> 
                    <li key={strength}>{strength}</li>
                  </ul>
                </div>
              ))}
            </div>
          )}
         {candidateDetails && candidateDetails.weaknesses  &&(
            <div className="mt-4">
              <p className="font-bold text-gray-800 ">Weaknesses:</p>
              {candidateDetails.weaknesses.map((weakness, index) => (
                <div key={index} className="break-inside-avoid custom-bullet pl-5">
                  <ul className="list-disc pl-5"> 
                    <li key={weakness}>{weakness}</li>
                  </ul>
                </div>
              ))}
            </div>
          )} 
         </div>
   
         <hr className='h-px my-8 bg-gray-100 border-0 dark:bg-gray-700'/>
         
          {candidateDetails && candidateDetails.YoddahSuggestion && (
            <div className="mt-4">
              <p className="font-bold text-green-600">Yoddah AI Suggestion:</p>
                {candidateDetails.YoddahSuggestion.map((YoddahSuggestion, index) => (
                  <div key={index} className="break-inside-avoid custom-bullet pl-5">
                    <ul className="list-disc pl-5"> 
                      <li key={YoddahSuggestion}>{YoddahSuggestion}</li>
                    </ul>
                    </div>
                ))}
                {/* <p className="text-gray-600">{candidateDetails.YoddahSuggestion}</p> */}
            </div>
          )}
       </div>
      )}
    </div>

    
  );
}

export default ResumeParser;