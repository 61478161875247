import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AIChatSession } from '../../service/AIModel'; 
import { useReactToPrint } from 'react-to-print'; 
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx'; 
import pdfToText from 'react-pdftotext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Document, Packer, Paragraph, TextRun, HeadingLevel, Table, TableRow, TableCell, ParagraphAlignment } from 'docx';
import {  read, utils } from 'xlsx';
import { TbReload } from "react-icons/tb";

function BulkParser() {
  const [resumeFiles, setResumeFiles] = useState([]);
  const [jobDescription, setJobDescription] = useState(localStorage.getItem('BulkJobDescription') || '');
  const [isLoading, setIsLoading] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState(JSON.parse(localStorage.getItem('BulkCandidateDetails')) || []);
  const [showFullDetails, setShowFullDetails] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  // Function to handle resume file uploads
  const handleResumeUpload = (event) => {
    const files = event.target.files;
    console.log('Files:',files)
    if (!Array.isArray(resumeFiles)) {
      console.error('resumeFiles is not an array');
      return;
    }
    const newFiles = [...resumeFiles];
    for (let i = 0; i < files.length; i++) {
      newFiles.push(files[i]);
    }
    setResumeFiles(newFiles);
    toast.success('Files Uploaded successfully!');
  };

  const extractTextFromPDF = (file) => {
    console.log('inside extract from pdf');
    return pdfToText(file)
      .then((text) => {
        localStorage.setItem('BulkResumeFiles',text)
        console.log('Extracted Text in method extractTextFromPDF', text);
        return text;
      })
      .catch((error) => console.error('Failed to extract text from pdf'));
  };

  // Function to extract text from a DOCX file
  const extractTextFromDOCX = (file) => {
    console.log('inside extract from docx');
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      try {
        const workbook = read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const extractedText = utils.sheet_to_json(worksheet, { header: 1 })
          .map((row) => row.join(' '))
          .join('\n');
        console.log('Extracted Text in method extractTextFromDOCX', extractedText);
        return extractedText;
      } catch (error) {
        console.error('Error extracting text from DOCX:', error);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  // Function to generate candidate details from AI
  const generateCandidateDetails = async () => {
    console.log('generateCandidateDetails function called!');
    setIsLoading(true);
    var final_resume_text = [];

    try {
      const extractedResumes = [];
      for (let i = 0; i < resumeFiles.length; i++) {
        const file = resumeFiles[i];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        let extractedText;

        if (fileExtension === 'pdf') {
          extractedText = await extractTextFromPDF(file);
          // final_resume_text.append(extractedText);
        } else if (fileExtension === 'docx') {
          extractedText = await extractTextFromDOCX(file);
          // final_resume_text.append(extractedText);
        } else {
          toast.error('Invalid file type. Please upload PDF or DOCX files.');
          continue;
        }

        extractedResumes.push(extractedText);
      }

      const promises = extractedResumes.map(async (resume, index) => {
        const prompt = `Extract candidate details from this resume: ${extractedResumes} Considering the job description: ${jobDescription} Return the following details in JSON format , return it as Matching Percentage, Strengths and Weaknesses, calculate the years of experience from candidate's experiences and return only Key top 10 skills . Return the following details in JSON format:{name: "Candidate Name", matchingPercentage: 85, phone: "1234567890",email: "candidate@example.com",skills: ["Skill 1", "Skill 2", "Skill 3"], education:["Education1", "Education2"], experienceYears: 5, workExperience: ["Experience1", "Experience2"], strengths: ["Strength 1", "Strength 2"],weaknesses: ["Weakness 1","Weakness2"]}`;
        const response = await AIChatSession.sendMessage(prompt);
        const candidateData = JSON.parse(response.response.text());
        console.log('candidate data:', candidateData);
        return candidateData;
      });

      const results = await Promise.all(promises);
      // Sort results by rank in descending order
      // const sortedResults = results.sort((a, b) => b.rank - a.rank);
      // setCandidateDetails(results.slice(0, 10)); // Get top 10 candidates
      setCandidateDetails(results); // Get top 10 candidates
      localStorage.setItem('BulkCandidateDetails', JSON.stringify(results)); // Store in local storage
    } catch (error) {
      console.error('Error generating candidate details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetCandidateDetails = async () => {
    console.log('resetCandidateDetails function called!');
    
    try {
      setIsLoading(false);
      setResumeFiles(null);
      setJobDescription('');
      setCandidateDetails(null);
      localStorage.removeItem('BulkResumeFiles');
      localStorage.removeItem('BulkJobDescription');
      localStorage.removeItem('BulkCandidateDetails');
      toast.success("Contents of the Page Cleared!")
    } catch (error) {
      console.error('Error Resetting candidate details:', error);
    } 
  };

  // Function to download the candidate details table as a CSV file
  const downloadCSV = () => {
    const worksheet = XLSX.utils.json_to_sheet(candidateDetails);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Candidates');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAs(
      new Blob([excelBuffer], { type: 'application/octet-stream' }),
      'candidates.xlsx'
    );
  };
  const handleShareEmail = () => {
    // Generate CSV data (you already have this logic in downloadCSV)
    const worksheet = XLSX.utils.json_to_sheet(candidateDetails);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Candidates');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const csvData = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Create a data URL for the CSV file
    const csvUrl = URL.createObjectURL(csvData);

    const tableHTML = `
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr>
          <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Rank</th>
          <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Name</th>
          <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Matching Percentage</th>
          <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Phone</th>
          <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Email</th>
          <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Skills</th>
          <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Strengths</th>
          <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Weaknesses</th>
        </tr>
      </thead>
      <tbody>
        ${candidateDetails
          .slice(0, 10)
          .map(
            (candidate, index) => `
            <tr>
              <td style="padding: 8px; border: 1px solid #ddd;">${candidate.rank}</td>
              <td style="padding: 8px; border: 1px solid #ddd;">${candidate.name}</td>
              <td style="padding: 8px; border: 1px solid #ddd;">${
                candidate.matchingPercentage
              }%</td>
              <td style="padding: 8px; border: 1px solid #ddd;">${
                candidate.phone
              }</td>
              <td style="padding: 8px; border: 1px solid #ddd;">${
                candidate.email
              }</td>
              <td style="padding: 8px; border: 1px solid #ddd;">${
                candidate.skills.join(', ')
              }</td>
              <td style="padding: 8px; border: 1px solid #ddd;">${
                candidate.strengths.join(', ')
              }</td>
              <td style="padding: 8px; border: 1px solid #ddd;">${
                candidate.weaknesses.join(', ')
              }</td>
            </tr>
          `
          )
          .join('')}
      </tbody>
    </table>
  `;
    // Open a new email window
    window.open(
      `mailto:?subject=Candidate Details&body=Please find the candidate details below:\n\n${csvUrl}`,
      '_blank'
    );
    // Open a new email window
    // window.open(
    //   `mailto:?subject=Candidate Details&body=Please find the candidate details below:\n\n${tableHTML}`,
    //   '_blank'
    // );
  };

  const handleShareEmail1 = () => {
    // Generate HTML table content (same as before)
    const tableHTML = `
      <table style="width: 100%; border-collapse: collapse;">
        <thead>
          <tr>
            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Rank</th>
            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Name</th>
            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Matching Percentage</th>
            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Phone</th>
            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Email</th>
            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Skills</th>
            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Strengths</th>
            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Weaknesses</th>
          </tr>
        </thead>
        <tbody>
          ${candidateDetails
            .slice(0, 10)
            .map(
              (candidate, index) => `
              <tr>
                <td style="padding: 8px; border: 1px solid #ddd;">${candidate.rank}</td>
                <td style="padding: 8px; border: 1px solid #ddd;">${candidate.name}</td>
                <td style="padding: 8px; border: 1px solid #ddd;">${
                  candidate.matchingPercentage
                }%</td>
                <td style="padding: 8px; border: 1px solid #ddd;">${
                  candidate.phone
                }</td>
                <td style="padding: 8px; border: 1px solid #ddd;">${
                  candidate.email
                }</td>
                <td style="padding: 8px; border: 1px solid #ddd;">${
                  candidate.skills.join(', ')
                }</td>
                <td style="padding: 8px; border: 1px solid #ddd;">${
                  candidate.strengths.join(', ')
                }</td>
                <td style="padding: 8px; border: 1px solid #ddd;">${
                  candidate.weaknesses.join(', ')
                }</td>
              </tr>
            `
            )
            .join('')}
        </tbody>
      </table>
    `;
  
    // Open a new email window with encoded URL
    window.open(
      `mailto:?subject=Candidate Details&body=${encodeURIComponent(
        `Please find the candidate details below:\n\n${tableHTML}`
      )}`,
      '_blank'
    );
  };



  const handleShowAllClick = (candidate) => {
    setSelectedCandidate(candidate);
    setShowFullDetails(true);
  };

  const handleCloseModal = () => {
    setShowFullDetails(false);
  };

  // Function to sort table data
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }

    const sortedData = [...candidateDetails].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });

    setCandidateDetails(sortedData);
  };

  return (
    <div className="container mx-auto p-4 bg-white">
      <h1 className="text-3xl font-bold mb-4">Bulk Resume Parser</h1>

      <div className="bg-white shadow-md rounded-md p-4">
        <h2 className="text-xl font-bold mb-2">Upload Resumes</h2>
        <input
          type="file"
          accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          onChange={handleResumeUpload}
          multiple
          className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-600 hover:file:bg-blue-100"
        />
      </div>

      <div className="bg-white shadow-md rounded-md p-4 mt-4">
        <h2 className="text-xl font-bold mb-2">Job Description</h2>
        <textarea
          value={jobDescription}
          onChange={(e) => {
            setJobDescription(e.target.value)
            localStorage.setItem('BulkJobDescription',jobDescription)
          }}
          className="border border-gray-300 rounded-md p-2 w-full"
          rows="5"
          placeholder="Enter the job description..."
        ></textarea>
      </div>

      <div className="bg-white shadow-md rounded-md p-4 mt-4">
      <button 
          className="bg-white-500 text-black ml-4 px-6 py-2 rounded-full hover:bg-blue-300 transition" 
          onClick={resetCandidateDetails}
          disabled={isLoading}
          // disabled={isLoading}
          ><TbReload size={24}/>
        </button>
        <button
          className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition"
          onClick={generateCandidateDetails}
          disabled={isLoading || !resumeFiles || !jobDescription}
        >
          Generate Candidate Details
        </button>
        {candidateDetails && (
          <>
            <button
              className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition mb-4 ml-4"
              onClick={downloadCSV}
            >
              Download CSV
            </button>
            <button
              className="bg-green-700 text-white px-6 py-2 rounded-full hover:bg-green-600 transition mb-4 ml-4"
              onClick={handleShareEmail}
            >
              Share via Mail
            </button>
          </>
        )}
      </div>

      {/* Loading Screen */}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-4 rounded-md shadow-md">
            <div className="flex items-center">
              <svg
                className="animate-spin h-5 w-5 mr-3"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0C0 5.373 5.373 0 12 0v4m8 11.451c0 1.291-.873 2.403-2.04 2.857H7A2 2 0 015 18c-1.291 0-2.403-.873-2.857-2.04H3M11 7h2m-4 0h2"
                ></path>
              </svg>
              <span className="text-green-500 font-bold">
                Yoddah AI Parsing...
              </span>
            </div>
          </div>
        </div>
      )}

      {candidateDetails && (
        <div className="mt-4">
          
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="p-4">
                    <div class="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label for="checkbox-all-search" class="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  {/* <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort('rank')}
                  >
                    Rank
                  </th> */}
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort('name')}
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort('matchingPercentage')}
                  >
                    Matching Percentage
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort('phone')}
                  >
                    Phone
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort('email')}
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort('skills')}
                  >
                    Skills
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort('strengths')}
                  >
                    Strengths
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort('weaknesses')}
                  >
                    Weaknesses
                  </th>
                </tr>
              </thead>
              <tbody>
                {candidateDetails.slice(0, 10).map((candidate, index) => (
                  <tr
                    key={index}
                    class="bg-white border-b dark:bg-gray-100 dark:border-gray-500 hover:bg-gray-50 dark:hover:bg-gray-200 dark:text-grey"
                  >
                    <td class="w-4 p-4">
                      <div class="flex items-center">
                        <input
                          id={`checkbox-table-search-${index + 1}`}
                          type="checkbox"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for={`checkbox-table-search-${index + 1}`}
                          class="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </td>
                    {/* <td class="px-6 py-4">
                      {candidate.index}
                    </td> */}
                    <th
                      scope="row"
                      class="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-grey-500"
                    >
                      <div class="ps-3">
                        <div class="text-base font-semibold text-black ">
                          {candidate.name}
                        </div>
                        <div class="font-normal text-black ">
                          {candidate.email}
                        </div>
                      </div>
                    </th>
                    <td class="px-6 py-4">
                      
                      {
                         candidate.matchingPercentage>=70? 
                          (<p className=" text-xl font-bold text-green-500">{candidate.matchingPercentage}% Match</p>)
                          :
                          (<p className=" text-xl font-bold text-orange-600">{candidate.matchingPercentage}% Match</p>)  
                      }
                    </td>
                    <td class="px-6 py-4 text-black">{candidate.phone}</td>
                    <td class="px-6 py-4 text-black">{candidate.email}</td>
                    <td class="px-6 py-4 relative text-black" onClick={() => handleShowAllClick(candidate)}>
                      <div className="truncate text-black">
                        {candidate.skills.slice(0, 1).join(', ')}
                        {candidate.skills.length > 1 && (
                          <span className="text-blue-700 font-bold"> ...view all</span>
                        )}
                      </div>
                    </td>
                    <td class="px-6 py-4" onClick={() => handleShowAllClick(candidate)}>
                      <div className="truncate text-black">
                        {candidate.strengths.slice(0, 1).join(', ')}
                        {candidate.strengths.length > 1 && (
                          <span className="text-blue-700 font-bold"> ...view all</span>
                        )}
                      </div>
                    </td>
                    <td class="px-6 py-4" onClick={() => handleShowAllClick(candidate)}>
                      <div className="truncate text-black">
                        {candidate.weaknesses.slice(0, 1).join(', ')}
                        {candidate.weaknesses.length > 1 && (
                          <span className="text-blue-700 font-bold"> ...view all</span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Modal for showing complete details */}
          <div
            id="show-all-details"
            tabindex="-1"
            aria-hidden="true"
            className={`fixed top-0 left-0 right-0 z-50 items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full ${
              showFullDetails ? 'block' : 'hidden'
            }`}
          >
            <div className="relative w-full max-w-2xl max-h-full">
              {/* Modal content */}
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-100">
                {/* Modal header */}
                <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-grey">
                    Candidate Details
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={handleCloseModal}
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* Modal body */}
                <div className="p-6 space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <p className="font-semibold text-gray-800">
                        Name:
                      </p>
                      <p className="text-gray-600">
                        {selectedCandidate?.name}
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold text-gray-800">
                        Matching Percentage:
                      </p>
                      <p className="text-gray-600">
                        {selectedCandidate?.matchingPercentage}%
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold text-gray-800">
                        Phone:
                      </p>
                      <p className="text-gray-600">
                        {selectedCandidate?.phone}
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold text-gray-800">
                        Email:
                      </p>
                      <p className="text-gray-600">
                        {selectedCandidate?.email}
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold text-gray-800">
                        Skills:
                      </p>
                      <ul className="list-disc pl-5 text-gray-600">
                        {selectedCandidate?.skills?.map((skill) => (
                          <li key={skill}>{skill}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <p className="font-semibold text-gray-800">
                        Strengths:
                      </p>
                      <ul className="list-disc pl-5 text-gray-600">
                        {selectedCandidate?.strengths?.map((strength) => (
                          <li key={strength}>{strength}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <p className="font-semibold text-gray-800">
                        Weaknesses:
                      </p>
                      <ul className="list-disc pl-5 text-gray-600">
                        {selectedCandidate?.weaknesses?.map((weakness) => (
                          <li key={weakness}>{weakness}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BulkParser;

// import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { AIChatSession } from '../../service/AIModel'; 
// import { useReactToPrint } from 'react-to-print'; 
// import { saveAs } from 'file-saver';
// import * as XLSX from 'xlsx'; 
// import pdfToText from 'react-pdftotext';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Document, Packer, Paragraph, TextRun, HeadingLevel, Table, TableRow, TableCell, ParagraphAlignment } from 'docx';
// import {  read, utils } from 'xlsx';
// import { TbReload } from "react-icons/tb";
// import axios from 'axios'; 
// import { S3Client, PutObjectCommand, GetObjectCommand } from '@aws-sdk/client-s3'; // Import S3 client

// function BulkParser() {
//   const [resumeFiles, setResumeFiles] = useState([]);
//   const [jobDescription, setJobDescription] = useState(localStorage.getItem('BulkJobDescription') || '');
//   const [isLoading, setIsLoading] = useState(false);
//   const [candidateDetails, setCandidateDetails] = useState(JSON.parse(localStorage.getItem('BulkCandidateDetails')) || []);
//   const [showFullDetails, setShowFullDetails] = useState(false);
//   const [selectedCandidate, setSelectedCandidate] = useState(null);
//   const [sortColumn, setSortColumn] = useState(null);
//   const [sortOrder, setSortOrder] = useState('asc');

//   // Function to handle resume file uploads
//   // const handleResumeUpload = (event) => {
//   //   const files = event.target.files;
//   //   console.log('Files:',files)
//   //   if (!Array.isArray(resumeFiles)) {
//   //     console.error('resumeFiles is not an array');
//   //     return;
//   //   }
//   //   const newFiles = [...resumeFiles];
//   //   for (let i = 0; i < files.length; i++) {
//   //     newFiles.push(files[i]);
//   //   }
//   //   setResumeFiles(newFiles);
//   //   toast.success('Files Uploaded successfully!');
//   // };
    

//    // Function to handle bulk upload
//    const handleBulkUpload = async () => {
//     try {
//       const formData = new FormData();
//       for (let i = 0; i < resumeFiles.length; i++) {
//         formData.append(`resume-${i}`, resumeFiles[i]); 
//       }

//       const response = await axios.post(`${process.env.REACT_APP_SERVER_API_URL}/api/bulk-upload`, formData);

//       if (response.status === 200) {
//         toast.success('Resumes uploaded successfully!');
//       } else {
//         toast.error('Failed to upload resumes.');
//       }
//     } catch (error) {
//       console.error('Error uploading resumes:', error);
//       toast.error('Error uploading resumes.');
//     }
//   };

//   const extractTextFromPDF = (file) => {
//     console.log('inside extract from pdf');
//     return pdfToText(file)
//       .then((text) => {
//         localStorage.setItem('BulkResumeFiles',text)
//         console.log('Extracted Text in method extractTextFromPDF', text);
//         return text;
//       })
//       .catch((error) => console.error('Failed to extract text from pdf'));
//   };

//   // Function to extract text from a DOCX file
//   const extractTextFromDOCX = (file) => {
//     console.log('inside extract from docx');
//     const reader = new FileReader();
//     reader.onload = async (e) => {
//       const data = e.target.result;
//       try {
//         const workbook = read(data, { type: 'array' });
//         const firstSheetName = workbook.SheetNames[0];
//         const worksheet = workbook.Sheets[firstSheetName];
//         const extractedText = utils.sheet_to_json(worksheet, { header: 1 })
//           .map((row) => row.join(' '))
//           .join('\n');
//         console.log('Extracted Text in method extractTextFromDOCX', extractedText);
//         return extractedText;
//       } catch (error) {
//         console.error('Error extracting text from DOCX:', error);
//       }
//     };
//     reader.readAsArrayBuffer(file);
//   };

//   // Function to generate candidate details from AI
//   const generateCandidateDetails = async () => {
//     console.log('generateCandidateDetails function called!');
//     setIsLoading(true);
//     var final_resume_text = [];

//     try {
//       const extractedResumes = [];
//       for (let i = 0; i < resumeFiles.length; i++) {
//         const file = resumeFiles[i];
//         const fileExtension = file.name.split('.').pop().toLowerCase();
//         let extractedText;

//         if (fileExtension === 'pdf') {
//           extractedText = await extractTextFromPDF(file);
//           // final_resume_text.append(extractedText);
//         } else if (fileExtension === 'docx') {
//           extractedText = await extractTextFromDOCX(file);
//           // final_resume_text.append(extractedText);
//         } else {
//           toast.error('Invalid file type. Please upload PDF or DOCX files.');
//           continue;
//         }

//         extractedResumes.push(extractedText);
//       }

//       const promises = extractedResumes.map(async (resume, index) => {
//         const prompt = `Extract candidate details from this resume: ${extractedResumes} Considering the job description: ${jobDescription} Return the following details in JSON format , return it as Matching Percentage, Strengths and Weaknesses, calculate the years of experience from candidate's experiences and return only Key top 10 skills . Return the following details in JSON format:{name: "Candidate Name", matchingPercentage: 85, phone: "1234567890",email: "candidate@example.com",skills: ["Skill 1", "Skill 2", "Skill 3"], education:["Education1", "Education2"], experienceYears: 5, workExperience: ["Experience1", "Experience2"], strengths: ["Strength 1", "Strength 2"],weaknesses: ["Weakness 1","Weakness2"]}`;
//         const response = await AIChatSession.sendMessage(prompt);
//         const candidateData = JSON.parse(response.response.text());
//         console.log('candidate data:', candidateData);
//         return candidateData;
//       });

//       const results = await Promise.all(promises);
//       // Sort results by rank in descending order
//       const sortedResults = results.sort((a, b) => b.rank - a.rank);
//       setCandidateDetails(sortedResults.slice(0, 10)); // Get top 10 candidates
//       localStorage.setItem('BulkCandidateDetails', JSON.stringify(sortedResults.slice(0, 10))); // Store in local storage
//     } catch (error) {
//       console.error('Error generating candidate details:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const resetCandidateDetails = async () => {
//     console.log('resetCandidateDetails function called!');
    
//     try {
//       setIsLoading(false);
//       setResumeFiles(null);
//       setJobDescription('');
//       setCandidateDetails(null);
//       localStorage.removeItem('BulkResumeFiles');
//       localStorage.removeItem('BulkJobDescription');
//       localStorage.removeItem('BulkCandidateDetails');
//       toast.success("Contents of the Page Cleared!")
//     } catch (error) {
//       console.error('Error Resetting candidate details:', error);
//     } 
//   };

//   // Function to download the candidate details table as a CSV file
//   const downloadCSV = () => {
//     const worksheet = XLSX.utils.json_to_sheet(candidateDetails);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Candidates');
//     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//     saveAs(
//       new Blob([excelBuffer], { type: 'application/octet-stream' }),
//       'candidates.xlsx'
//     );
//   };
//   const handleShareEmail = () => {
//     // Generate CSV data (you already have this logic in downloadCSV)
//     const worksheet = XLSX.utils.json_to_sheet(candidateDetails);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Candidates');
//     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//     const csvData = new Blob([excelBuffer], { type: 'application/octet-stream' });

//     // Create a data URL for the CSV file
//     const csvUrl = URL.createObjectURL(csvData);

//     const tableHTML = `
//     <table style="width: 100%; border-collapse: collapse;">
//       <thead>
//         <tr>
//           <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Rank</th>
//           <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Name</th>
//           <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Matching Percentage</th>
//           <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Phone</th>
//           <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Email</th>
//           <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Skills</th>
//           <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Strengths</th>
//           <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Weaknesses</th>
//         </tr>
//       </thead>
//       <tbody>
//         ${candidateDetails
//           .slice(0, 10)
//           .map(
//             (candidate, index) => `
//             <tr>
//               <td style="padding: 8px; border: 1px solid #ddd;">${candidate.rank}</td>
//               <td style="padding: 8px; border: 1px solid #ddd;">${candidate.name}</td>
//               <td style="padding: 8px; border: 1px solid #ddd;">${
//                 candidate.matchingPercentage
//               }%</td>
//               <td style="padding: 8px; border: 1px solid #ddd;">${
//                 candidate.phone
//               }</td>
//               <td style="padding: 8px; border: 1px solid #ddd;">${
//                 candidate.email
//               }</td>
//               <td style="padding: 8px; border: 1px solid #ddd;">${
//                 candidate.skills.join(', ')
//               }</td>
//               <td style="padding: 8px; border: 1px solid #ddd;">${
//                 candidate.strengths.join(', ')
//               }</td>
//               <td style="padding: 8px; border: 1px solid #ddd;">${
//                 candidate.weaknesses.join(', ')
//               }</td>
//             </tr>
//           `
//           )
//           .join('')}
//       </tbody>
//     </table>
//   `;
//     // Open a new email window
//     window.open(
//       `mailto:?subject=Candidate Details&body=Please find the candidate details below:\n\n${csvUrl}`,
//       '_blank'
//     );
//     // Open a new email window
//     // window.open(
//     //   `mailto:?subject=Candidate Details&body=Please find the candidate details below:\n\n${tableHTML}`,
//     //   '_blank'
//     // );
//   };

//   const handleShareEmail1 = () => {
//     // Generate HTML table content (same as before)
//     const tableHTML = `
//       <table style="width: 100%; border-collapse: collapse;">
//         <thead>
//           <tr>
//             <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Rank</th>
//             <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Name</th>
//             <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Matching Percentage</th>
//             <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Phone</th>
//             <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Email</th>
//             <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Skills</th>
//             <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Strengths</th>
//             <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Weaknesses</th>
//           </tr>
//         </thead>
//         <tbody>
//           ${candidateDetails
//             .slice(0, 10)
//             .map(
//               (candidate, index) => `
//               <tr>
//                 <td style="padding: 8px; border: 1px solid #ddd;">${candidate.rank}</td>
//                 <td style="padding: 8px; border: 1px solid #ddd;">${candidate.name}</td>
//                 <td style="padding: 8px; border: 1px solid #ddd;">${
//                   candidate.matchingPercentage
//                 }%</td>
//                 <td style="padding: 8px; border: 1px solid #ddd;">${
//                   candidate.phone
//                 }</td>
//                 <td style="padding: 8px; border: 1px solid #ddd;">${
//                   candidate.email
//                 }</td>
//                 <td style="padding: 8px; border: 1px solid #ddd;">${
//                   candidate.skills.join(', ')
//                 }</td>
//                 <td style="padding: 8px; border: 1px solid #ddd;">${
//                   candidate.strengths.join(', ')
//                 }</td>
//                 <td style="padding: 8px; border: 1px solid #ddd;">${
//                   candidate.weaknesses.join(', ')
//                 }</td>
//               </tr>
//             `
//             )
//             .join('')}
//         </tbody>
//       </table>
//     `;
  
//     // Open a new email window with encoded URL
//     window.open(
//       `mailto:?subject=Candidate Details&body=${encodeURIComponent(
//         `Please find the candidate details below:\n\n${tableHTML}`
//       )}`,
//       '_blank'
//     );
//   };



//   const handleShowAllClick = (candidate) => {
//     setSelectedCandidate(candidate);
//     setShowFullDetails(true);
//   };

//   const handleCloseModal = () => {
//     setShowFullDetails(false);
//   };

//   // Function to sort table data
//   const handleSort = (column) => {
//     if (sortColumn === column) {
//       setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
//     } else {
//       setSortColumn(column);
//       setSortOrder('asc');
//     }

//     const sortedData = [...candidateDetails].sort((a, b) => {
//       if (sortOrder === 'asc') {
//         return a[column] > b[column] ? 1 : -1;
//       } else {
//         return a[column] < b[column] ? 1 : -1;
//       }
//     });

//     setCandidateDetails(sortedData);
//   };

//   return (
//     <div className="container mx-auto p-4 bg-white">
//       <h1 className="text-3xl font-bold mb-4">Bulk Resume Parser</h1>

//       <div className="bg-white shadow-md rounded-md p-4">
//         <h2 className="text-xl font-bold mb-2">Upload Resumes</h2>
//         <input
//           type="file"
//           accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
//           onChange={handleBulkUpload}
//           multiple
//           className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-600 hover:file:bg-blue-100"
//         />
//       </div>

//       <div className="bg-white shadow-md rounded-md p-4 mt-4">
//         <h2 className="text-xl font-bold mb-2">Job Description</h2>
//         <textarea
//           value={jobDescription}
//           onChange={(e) => {
//             setJobDescription(e.target.value)
//             localStorage.setItem('BulkJobDescription',jobDescription)
//           }}
//           className="border border-gray-300 rounded-md p-2 w-full"
//           rows="5"
//           placeholder="Enter the job description..."
//         ></textarea>
//       </div>

//       <div className="bg-white shadow-md rounded-md p-4 mt-4">
//       <button 
//           className="bg-white-500 text-black ml-4 px-6 py-2 rounded-full hover:bg-blue-300 transition" 
//           onClick={resetCandidateDetails}
//           disabled={isLoading}
//           // disabled={isLoading}
//           ><TbReload size={24}/>
//         </button>
//         <button
//           className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition mb-4 ml-4"
//           onClick={generateCandidateDetails}
//           disabled={isLoading || !resumeFiles || !jobDescription}
//         >
//           Generate Candidate Details
//         </button>
//         {candidateDetails && (
//           <>
//             <button
//               className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition mb-4 ml-4"
//               onClick={downloadCSV}
//             >
//               Download CSV
//             </button>
//             <button
//               className="bg-green-700 text-white px-6 py-2 rounded-full hover:bg-green-600 transition mb-4 ml-4"
//               onClick={handleShareEmail}
//             >
//               Share via Mail
//             </button>
//           </>
//         )}
//       </div>

//       {/* Loading Screen */}
//       {isLoading && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
//           <div className="bg-white p-4 rounded-md shadow-md">
//             <div className="flex items-center">
//               <svg
//                 className="animate-spin h-5 w-5 mr-3"
//                 viewBox="0 0 24 24"
//               >
//                 <circle
//                   className="opacity-25"
//                   cx="12"
//                   cy="12"
//                   r="10"
//                   stroke="currentColor"
//                   strokeWidth="4"
//                 ></circle>
//                 <path
//                   className="opacity-75"
//                   fill="none"
//                   stroke="currentColor"
//                   strokeWidth="2"
//                   d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0C0 5.373 5.373 0 12 0v4m8 11.451c0 1.291-.873 2.403-2.04 2.857H7A2 2 0 015 18c-1.291 0-2.403-.873-2.857-2.04H3M11 7h2m-4 0h2"
//                 ></path>
//               </svg>
//               <span className="text-green-500 font-bold">
//                 Yoddah AI Parsing...
//               </span>
//             </div>
//           </div>
//         </div>
//       )}

//       {candidateDetails && (
//         <div className="mt-4">
          
//           <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
//             <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
//               <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
//                 <tr>
//                   <th scope="col" class="p-4">
//                     <div class="flex items-center">
//                       <input
//                         id="checkbox-all-search"
//                         type="checkbox"
//                         class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
//                       />
//                       <label for="checkbox-all-search" class="sr-only">
//                         checkbox
//                       </label>
//                     </div>
//                   </th>
//                   {/* <th
//                     scope="col"
//                     class="px-6 py-3 cursor-pointer"
//                     onClick={() => handleSort('rank')}
//                   >
//                     Rank
//                   </th> */}
//                   <th
//                     scope="col"
//                     class="px-6 py-3 cursor-pointer"
//                     onClick={() => handleSort('name')}
//                   >
//                     Name
//                   </th>
//                   <th
//                     scope="col"
//                     class="px-6 py-3 cursor-pointer"
//                     onClick={() => handleSort('matchingPercentage')}
//                   >
//                     Matching Percentage
//                   </th>
//                   <th
//                     scope="col"
//                     class="px-6 py-3 cursor-pointer"
//                     onClick={() => handleSort('phone')}
//                   >
//                     Phone
//                   </th>
//                   <th
//                     scope="col"
//                     class="px-6 py-3 cursor-pointer"
//                     onClick={() => handleSort('email')}
//                   >
//                     Email
//                   </th>
//                   <th
//                     scope="col"
//                     class="px-6 py-3 cursor-pointer"
//                     onClick={() => handleSort('skills')}
//                   >
//                     Skills
//                   </th>
//                   <th
//                     scope="col"
//                     class="px-6 py-3 cursor-pointer"
//                     onClick={() => handleSort('strengths')}
//                   >
//                     Strengths
//                   </th>
//                   <th
//                     scope="col"
//                     class="px-6 py-3 cursor-pointer"
//                     onClick={() => handleSort('weaknesses')}
//                   >
//                     Weaknesses
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {candidateDetails.slice(0, 10).map((candidate, index) => (
//                   <tr
//                     key={index}
//                     class="bg-white border-b dark:bg-gray-100 dark:border-gray-500 hover:bg-gray-50 dark:hover:bg-gray-200 dark:text-grey"
//                   >
//                     <td class="w-4 p-4">
//                       <div class="flex items-center">
//                         <input
//                           id={`checkbox-table-search-${index + 1}`}
//                           type="checkbox"
//                           class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
//                         />
//                         <label
//                           for={`checkbox-table-search-${index + 1}`}
//                           class="sr-only"
//                         >
//                           checkbox
//                         </label>
//                       </div>
//                     </td>
//                     {/* <td class="px-6 py-4">
//                       {candidate.index}
//                     </td> */}
//                     <th
//                       scope="row"
//                       class="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-grey-500"
//                     >
//                       <div class="ps-3">
//                         <div class="text-base font-semibold text-black ">
//                           {candidate.name}
//                         </div>
//                         <div class="font-normal text-black ">
//                           {candidate.email}
//                         </div>
//                       </div>
//                     </th>
//                     <td class="px-6 py-4">
                      
//                       {
//                          candidate.matchingPercentage>=70? 
//                           (<p className=" text-xl font-bold text-green-500">{candidate.matchingPercentage}% Match</p>)
//                           :
//                           (<p className=" text-xl font-bold text-orange-600">{candidate.matchingPercentage}% Match</p>)  
//                       }
//                     </td>
//                     <td class="px-6 py-4 text-black">{candidate.phone}</td>
//                     <td class="px-6 py-4 text-black">{candidate.email}</td>
//                     <td class="px-6 py-4 relative text-black" onClick={() => handleShowAllClick(candidate)}>
//                       <div className="truncate text-black">
//                         {candidate.skills.slice(0, 1).join(', ')}
//                         {candidate.skills.length > 1 && (
//                           <span className="text-blue-700 font-bold"> ...view all</span>
//                         )}
//                       </div>
//                     </td>
//                     <td class="px-6 py-4" onClick={() => handleShowAllClick(candidate)}>
//                       <div className="truncate text-black">
//                         {candidate.strengths.slice(0, 1).join(', ')}
//                         {candidate.strengths.length > 1 && (
//                           <span className="text-blue-700 font-bold"> ...view all</span>
//                         )}
//                       </div>
//                     </td>
//                     <td class="px-6 py-4" onClick={() => handleShowAllClick(candidate)}>
//                       <div className="truncate text-black">
//                         {candidate.weaknesses.slice(0, 1).join(', ')}
//                         {candidate.weaknesses.length > 1 && (
//                           <span className="text-blue-700 font-bold"> ...view all</span>
//                         )}
//                       </div>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//           {/* Modal for showing complete details */}
//           <div
//             id="show-all-details"
//             tabindex="-1"
//             aria-hidden="true"
//             className={`fixed top-0 left-0 right-0 z-50 items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full ${
//               showFullDetails ? 'block' : 'hidden'
//             }`}
//           >
//             <div className="relative w-full max-w-2xl max-h-full">
//               {/* Modal content */}
//               <div className="relative bg-white rounded-lg shadow dark:bg-gray-100">
//                 {/* Modal header */}
//                 <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
//                   <h3 className="text-xl font-semibold text-gray-900 dark:text-grey">
//                     Candidate Details
//                   </h3>
//                   <button
//                     type="button"
//                     className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
//                     onClick={handleCloseModal}
//                   >
//                     <svg
//                       className="w-3 h-3"
//                       aria-hidden="true"
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 14 14"
//                     >
//                       <path
//                         stroke="currentColor"
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                         stroke-width="2"
//                         d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
//                       />
//                     </svg>
//                     <span className="sr-only">Close modal</span>
//                   </button>
//                 </div>
//                 {/* Modal body */}
//                 <div className="p-6 space-y-6">
//                   <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                     <div>
//                       <p className="font-semibold text-gray-800">
//                         Name:
//                       </p>
//                       <p className="text-gray-600">
//                         {selectedCandidate?.name}
//                       </p>
//                     </div>
//                     <div>
//                       <p className="font-semibold text-gray-800">
//                         Matching Percentage:
//                       </p>
//                       <p className="text-gray-600">
//                         {selectedCandidate?.matchingPercentage}%
//                       </p>
//                     </div>
//                     <div>
//                       <p className="font-semibold text-gray-800">
//                         Phone:
//                       </p>
//                       <p className="text-gray-600">
//                         {selectedCandidate?.phone}
//                       </p>
//                     </div>
//                     <div>
//                       <p className="font-semibold text-gray-800">
//                         Email:
//                       </p>
//                       <p className="text-gray-600">
//                         {selectedCandidate?.email}
//                       </p>
//                     </div>
//                     <div>
//                       <p className="font-semibold text-gray-800">
//                         Skills:
//                       </p>
//                       <ul className="list-disc pl-5 text-gray-600">
//                         {selectedCandidate?.skills?.map((skill) => (
//                           <li key={skill}>{skill}</li>
//                         ))}
//                       </ul>
//                     </div>
//                     <div>
//                       <p className="font-semibold text-gray-800">
//                         Strengths:
//                       </p>
//                       <ul className="list-disc pl-5 text-gray-600">
//                         {selectedCandidate?.strengths?.map((strength) => (
//                           <li key={strength}>{strength}</li>
//                         ))}
//                       </ul>
//                     </div>
//                     <div>
//                       <p className="font-semibold text-gray-800">
//                         Weaknesses:
//                       </p>
//                       <ul className="list-disc pl-5 text-gray-600">
//                         {selectedCandidate?.weaknesses?.map((weakness) => (
//                           <li key={weakness}>{weakness}</li>
//                         ))}
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default BulkParser;