import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

function Profile() {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const token =  sessionStorage.getItem('authToken'); // Get the token from  sessionStorage
        if (!token) {
          // Redirect to login if no token
          navigate('/');
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_SERVER_API_URL}/api/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header
          },
        });        
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
        toast.error('Failed to fetch user data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (userData) {
      setUsername(userData.username);
      setEmail(userData.email);
    }
  }, [userData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  const handleUpdateProfile = async () => {
    setIsUpdating(true);
    setUpdateError(null);
    const token = sessionStorage.getItem('authToken');

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_API_URL}/api/users/me`,
        {
          username,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );

      if (response.status === 200) {
        toast.success('Profile updated successfully!');
        setUserData(response.data);
        setIsUpdating(false);
      } else {
        toast.error('Failed to update profile.');
        setIsUpdating(false);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setUpdateError(error.response?.data?.message || 'Failed to update profile.');
      setIsUpdating(false);
    }
  };

  const handlePasswordChange = async () => {
    setIsUpdating(true);
    setUpdateError(null);
    const token = sessionStorage.getItem('authToken');

    if (newPassword !== confirmPassword) {
      setUpdateError('Passwords do not match.');
      setIsUpdating(false);
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_API_URL}/api/users/me/password`,
        {
          oldPassword: password,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );

      if (response.status === 200) {
        toast.success('Password updated successfully!');
        setPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setIsUpdating(false);
      } else {
        toast.error('Failed to update password.');
        setIsUpdating(false);
      }
    } catch (error) {
      console.error('Error updating password:', error);
      setUpdateError(error.response?.data?.message || 'Failed to update password.');
      setIsUpdating(false);
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem('authToken');
    navigate('/');
  };

  if (isLoading) {
    return (
      <div className="container mx-auto p-4 bg-white">
        <div className="flex items-center justify-center h-screen">
          <div className="bg-white shadow-md rounded-md p-4">
            <div className="flex items-center">
              <svg
                className="animate-spin h-5 w-5 mr-3"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0C0 5.373 5.373 0 12 0v4m8 11.451c0 1.291-.873 2.403-2.04 2.857H7A2 2 0 015 18c-1.291 0-2.403-.873-2.857-2.04H3M11 7h2m-4 0h2"
                ></path>
              </svg>
              <span className="text-green-500 font-bold">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 bg-white">
      <h1 className="text-3xl font-bold mb-4 text-gray-800">Profile</h1>

      <div className="bg-white shadow-md rounded-md p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900  ">
              Username:
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={handleInputChange}
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5     dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              disabled={isUpdating}
            />
          </div>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900  ">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleInputChange}
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5     dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              disabled={isUpdating}
            />
          </div>
        </div>

        <div className="mt-4">
          <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">
            Current Password:
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handleInputChange}
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>

        <div className="mt-4">
          <label htmlFor="newPassword" className="block mb-2 text-sm font-medium text-gray-900">
            New Password:
          </label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            value={newPassword}
            onChange={handleInputChange}
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <div className="mt-4">
          <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-gray-900 ">
            Confirm Password:
          </label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleInputChange}
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          {updateError && (
            <p className="mt-2 text-red-500 text-sm">{updateError}</p>
          )}
        </div>

        <div className="mt-4 flex gap-4">
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleUpdateProfile}
            disabled={isUpdating}
          >
            {isUpdating ? (
              <span className="animate-spin rounded-full h-5 w-5 border-t-2 border-r-2 border-blue-500" />
            ) : (
              'Update Profile'
            )}
          </button>
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handlePasswordChange}
            disabled={isUpdating}
          >
            {isUpdating ? (
              <span className="animate-spin rounded-full h-5 w-5 border-t-2 border-r-2 border-blue-500" />
            ) : (
              'Change Password'
            )}
          </button>
          <button
            type="button"
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
);
}

export default Profile