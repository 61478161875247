// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Modal from '../Modal';
// // import AIModal from './AIModal'; // Import the AIModal component
// // import Loading from './Loading';

// function Veteran() {
//   const [veterans, setVeterans] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [showModal, setShowModal] = useState(false);
//   const [showAIModal, setShowAIModal] = useState(false);
//   const [selectedVeteran, setSelectedVeteran] = useState(null);
//   const [isDeleting, setIsDeleting] = useState(false);
//   const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
//   const [isGeneratingResume, setIsGeneratingResume] = useState(false); // State for loading while generating resume
//   const [resumeContent, setResumeContent] = useState(''); // State for the generated resume
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchVeterans = async () => {
//       try {
//         const response = await fetch('http://localhost:1000/veterans');
//         if (response.ok) {
//           const data = await response.json();
//           setVeterans(data);
//         } else {
//           console.error('Error fetching veterans:', response.status);
//         }
//       } catch (error) {
//         console.error('Error fetching veterans:', error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchVeterans();
//   }, []);

//   const handleAddVeteran = () => {
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   const handleSaveVeteran = async (newVeteran) => {
//     try {
//       const response = await fetch('http://localhost:1000/veterans', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(newVeteran),
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setVeterans([...veterans, data]);
//         setShowModal(false);
//       } else {
//         console.error('Error saving veteran:', response.status);
//       }
//     } catch (error) {
//       console.error('Error saving veteran:', error);
//     }
//   };

//   const handleSelectVeteran = (veteran) => {
//     setSelectedVeteran(veteran);
//   };

//   const handleGenerateResume = async () => {
//     if (selectedVeteran) {
//       setIsGeneratingResume(true); 

//       try {
//         // 1. Construct the prompt using selectedVeteran data
//         const prompt = `
//           Generate a professional resume for:

//           Name: ${selectedVeteran.fullName}
//           DOB: ${new Date(selectedVeteran.dateOfBirth).toLocaleDateString()}
//           Age: ${calculateAge(selectedVeteran.dateOfBirth)} 
//           Mobile: ${selectedVeteran.mobileNumber}
//           Email: ${selectedVeteran.emailId}
//           Gender: ${selectedVeteran.gender}
//           Current Location: ${selectedVeteran.preferredJobLocation}
//           Work Summary: ${generateWorkSummary(selectedVeteran)}
//           Education: 
//             - ${selectedVeteran.highestQualification} from ${selectedVeteran.schoolCollegeUniversity}
//           Experience:
//             - ${selectedVeteran.experience1 ? selectedVeteran.experience1 : ''}
//             - ${selectedVeteran.experience2 ? selectedVeteran.experience2 : ''}
//             - ${selectedVeteran.experience3 ? selectedVeteran.experience3 : ''}
//           Skills:
//             - ${selectedVeteran.industrySkills.join(', ')}
//             - ${selectedVeteran.primarySkills.join(', ')}
//             - ${selectedVeteran.secondarySkills.join(', ')}
//           Certifications:
//             - ${selectedVeteran.trainingsCertifications.join(', ')}
//           Language Known:
//             - ${selectedVeteran.languagesKnown}

//           **Format:** 
//           - Use bullet points for education, experience, skills, certifications, and language known.
//           - Create a brief work summary paragraph based on the experiences. 
//           - Follow a standard professional resume format.
//         `;

//         // Call the send function from AIModel.js
//         const response = await send(prompt);

//         if (response.content) {
//           setResumeContent(response.content); // Extract the generated content
//         } else {
//           console.error('Error generating resume:', response);
//         }
//       } catch (error) {
//         console.error('Error generating resume:', error);
//       } finally {
//         setIsGeneratingResume(false);
//       }
//     }
//   };
//   const handleEditVeteran = (veteran) => {
//     setSelectedVeteran(veteran);
//     setShowModal(true);
//   };

//   const handleDeleteVeteran = async () => {
//     try {
//       setIsDeleting(true); // Show loading indicator

//       // Use a DELETE request with the selected veteran's ID 
//       const response = await fetch(`/api/veterans/${selectedVeteran._id}`, {
//         method: 'DELETE',
//       });

//       if (response.ok) {
//         setVeterans(veterans.filter((vet) => vet._id !== selectedVeteran._id));
//         setSelectedVeteran(null);
//         setDeleteConfirmationModalOpen(false); // Close the confirmation modal
//       } else {
//         console.error('Error deleting veteran:', response.status);
//       }
//     } catch (error) {
//       console.error('Error deleting veteran:', error);
//     } finally {
//       setIsDeleting(false);
//     }
//   };

//   const handleDeleteConfirmation = () => {
//     setDeleteConfirmationModalOpen(true);
//   };

//   const handleCancelDelete = () => {
//     setDeleteConfirmationModalOpen(false);
//   };

//   const calculateAge = (dateOfBirth) => {
//     const today = new Date();
//     const birthDate = new Date(dateOfBirth);
//     const age = today.getFullYear() - birthDate.getFullYear();
//     return age;
//   };

//   const generateWorkSummary = (veteran) => {
//     // You need to write the logic to construct a work summary based on 
//     // experience1, experience2, and experience3. 
//     // For example:
//     const experiences = [veteran.experience1, veteran.experience2, veteran.experience3].filter(
//       (exp) => exp.trim() !== ''
//     );
//     if (experiences.length > 0) {
//       return experiences.join(' ');
//     } else {
//       return 'No work experience provided.';
//     }
//   };

//   return (
//     <div className="container mx-auto p-4">
//       <h1 className="text-3xl font-bold mb-4">Veteran Data</h1>

//       <button onClick={handleAddVeteran} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4">
//         + Add Manual Veteran Data Input
//       </button>

//       {/* Button to generate resume */}
//       {selectedVeteran && (
//         <button onClick={handleGenerateResume} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 ml-4">
//           Generate AI Resume for Veteran
//         </button>
//       )}

//       <Modal show={showModal} onClose={handleCloseModal} onSave={handleSaveVeteran} />

//       {isLoading ? (
//         <div className="text-center">Loading...</div>
//       ) : (
//         <div className="overflow-x-auto">
//           <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
//             <thead>
//               <tr>
//                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                   Select
//                 </th>
//                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                   Sno
//                 </th>
//                 {Object.keys(veterans[0] || {}).map((field) => (
//                   <th
//                     key={field}
//                     className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                   >
//                     {field}
//                   </th>
//                 ))}
//                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                   Actions
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {veterans.map((veteran, index) => (
//                 <tr key={veteran._id}>
//                   <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
//                     <input 
//                       type="checkbox" 
//                       checked={selectedVeteran === veteran} 
//                       onChange={() => handleSelectVeteran(veteran)} 
//                       className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
//                     />
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
//                     {index + 1}
//                   </td>
//                   {Object.values(veteran).map((value, valueIndex) => (
//                     <td key={valueIndex} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                       {value}
//                     </td>
//                   ))}
//                   <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
//                     <button onClick={() => handleEditVeteran(veteran)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
//                       Edit
//                     </button>
//                     <button onClick={handleDeleteConfirmation} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2">
//                       Delete
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}

//       {/* Delete Confirmation Modal */}
//       <Modal
//         show={deleteConfirmationModalOpen}
//         onClose={handleCancelDelete}
//         onSave={handleDeleteVeteran}
//         title="Delete Veteran"
//         body="Are you sure you want to delete this veteran?"
//         confirmButtonText="Delete"
//       /> 
      
//       {/* Loading Screen while deleting */}
//       {isDeleting && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
//           <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-b-transparent animate-spin"></div>
//         </div>
//       )}

      

//       {/* Display generated resume */}
//       {resumeContent && (
//         <div className="bg-white shadow-md rounded-md p-4 mt-4">
//           <h2 className="text-xl font-bold mb-2">Generated Resume</h2>
//           <pre className="whitespace-pre-wrap">{resumeContent}</pre> 
//         </div>
//       )}

//       {/* Loading screen while generating resume */}
//       {isGeneratingResume && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
//           <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-b-transparent animate-spin"></div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Veteran;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../Modal';
// import Loading from './Loading';
import { send, AIChatSession } from '../../service/AIModel'; // Import send function from AIModel.js 

function Veteran({ isSidebarOpen }) {
  const [veterans, setVeterans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedVeteran, setSelectedVeteran] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); 
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false); 
  const [isGeneratingResume, setIsGeneratingResume] = useState(false); 
  const [resumeContent, setResumeContent] = useState(''); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVeterans = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_API_URL}/veterans`);
        if (response.ok) {
          const data = await response.json();
          setVeterans(data);
        } else {
          console.error('Error fetching veterans:', response.status);
        }
      } catch (error) {
        console.error('Error fetching veterans:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVeterans();
  }, []);

  const handleAddVeteran = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveVeteran = async (newVeteran) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_API_URL}/veterans`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newVeteran),
      });

      if (response.ok) {
        const data = await response.json();
        setVeterans([...veterans, data]);
        setShowModal(false);
      } else {
        console.error('Error saving veteran:', response.status);
      }
    } catch (error) {
      console.error('Error saving veteran:', error);
    }
  };

  const handleSelectVeteran = (veteran) => {
    setSelectedVeteran(veteran);
  };

  const handleGenerateResume = async () => {
    if (selectedVeteran) {
      setIsGeneratingResume(true); 

      try {
        // 1. Construct the prompt using selectedVeteran data
        const prompt = `
          Generate a professional resume for:

          Name: ${selectedVeteran.fullName}
          DOB: ${new Date(selectedVeteran.dateOfBirth).toLocaleDateString()}
          Age: ${calculateAge(selectedVeteran.dateOfBirth)} 
          Mobile: ${selectedVeteran.mobileNumber}
          Email: ${selectedVeteran.emailId}
          Gender: ${selectedVeteran.gender}
          Current Location: ${selectedVeteran.preferredJobLocation}
          Work Summary: ${generateWorkSummary(selectedVeteran)}
          Education: 
            - ${selectedVeteran.highestQualification} from ${selectedVeteran.schoolCollegeUniversity}
          Experience:
            - ${selectedVeteran.experience1 ? selectedVeteran.experience1 : ''}
            - ${selectedVeteran.experience2 ? selectedVeteran.experience2 : ''}
            - ${selectedVeteran.experience3 ? selectedVeteran.experience3 : ''}
          Skills:
            - ${selectedVeteran.industrySkills.join(', ')}
            - ${selectedVeteran.primarySkills.join(', ')}
            - ${selectedVeteran.secondarySkills.join(', ')}
          Certifications:
            - ${selectedVeteran.trainingsCertifications.join(', ')}
          Language Known:
            - ${selectedVeteran.languagesKnown}

          **Format:** 
          - Use bullet points for education, experience, skills, certifications, and language known.
          - Create a brief work summary paragraph based on the experiences. 
          - Follow a standard professional resume format.
        `;

        // Call the send function from AIModel.js
        const response = await AIChatSession.sendMessage(prompt);

        if (response.content) {
            console.log("Resume Content:",response.content)
          setResumeContent(response.content); // Extract the generated content
        } else {
          console.error('Error generating resume:', response);
        }
      } catch (error) {
        console.error('Error generating resume:', error);
      } finally {
        setIsGeneratingResume(false);
      }
    }
  };

  const handleEditVeteran = (veteran) => {
    setSelectedVeteran(veteran);
    setShowModal(true);
  };

  const handleDeleteVeteran = async () => {
    try {
      setIsDeleting(true); // Show loading indicator

      // Use a DELETE request with the selected veteran's ID 
      const response = await fetch(`htp://localhost:1000/veterans/${selectedVeteran._id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setVeterans(veterans.filter((vet) => vet._id !== selectedVeteran._id));
        setSelectedVeteran(null);
        setDeleteConfirmationModalOpen(false); // Close the confirmation modal
      } else {
        console.error('Error deleting veteran:', response.status);
      }
    } catch (error) {
      console.error('Error deleting veteran:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteConfirmation = () => {
    setDeleteConfirmationModalOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationModalOpen(false);
  };

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    const age = today.getFullYear() - birthDate.getFullYear();
    return age;
  };

  const generateWorkSummary = (veteran) => {
    // You need to write the logic to construct a work summary based on 
    // experience1, experience2, and experience3. 
    // For example:
    const experiences = [veteran.experience1, veteran.experience2, veteran.experience3].filter(
      (exp) => exp.trim() !== ''
    );
    if (experiences.length > 0) {
      return experiences.join(' ');
    } else {
      return 'No work experience provided.';
    }
  };

  return (
    <div className={`container mx-auto p-4 bg-white  ${
      isSidebarOpen ? " ml-0 " : " ml-0"
     }`}>
      <h1 className="text-3xl font-bold mb-4">Veteran Data</h1>

      <button onClick={handleAddVeteran} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4">
        + Add Manual Veteran Data Input
      </button>

      {/* Button to generate resume */}
      {selectedVeteran && (
        <button onClick={handleGenerateResume} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 ml-4">
          Generate AI Resume for Veteran
        </button>
      )}

      <Modal show={showModal} onClose={handleCloseModal} onSave={handleSaveVeteran} />

      {isLoading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Select
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Sno
                </th>
                {Object.keys(veterans[0] || {}).map((field) => (
                  <th
                    key={field}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {field}
                  </th>
                ))}
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {veterans.map((veteran, index) => (
                <tr key={veteran._id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <input 
                      type="checkbox" 
                      checked={selectedVeteran === veteran} 
                      onChange={() => handleSelectVeteran(veteran)} 
                      className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {index + 1}
                  </td>
                  {Object.values(veteran).map((value, valueIndex) => (
                    <td key={valueIndex} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {value}
                    </td>
                  ))}
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <button onClick={() => handleEditVeteran(veteran)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      Edit
                    </button>
                    <button onClick={handleDeleteConfirmation} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
           {/* Display generated resume */}
      {resumeContent && (
        <div className="bg-white shadow-md rounded-md p-4 mt-4">
          <h2 className="text-xl font-bold mb-2">Generated Resume</h2>
          <pre className="whitespace-pre-wrap">{resumeContent}</pre> 
        </div>
      )}
        </div>
      )}

      {/* Delete Confirmation Modal */}
      <Modal
        show={deleteConfirmationModalOpen}
        onClose={handleCancelDelete}
        onSave={handleDeleteVeteran}
        title="Delete Veteran"
        body="Are you sure you want to delete this veteran?"
        confirmButtonText="Delete"
      /> 
      
      {/* Loading Screen while deleting */}
      {isDeleting && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-b-transparent animate-spin"></div>
        </div>
      )}

      

     

      {/* Loading screen while generating resume */}
      {isGeneratingResume && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-b-transparent animate-spin"></div>
        </div>
      )}
    </div>
  );
}

export default Veteran;