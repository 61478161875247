import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import FormSection from './FormSection';
import ResumePreview from './ResumePreview';
import { ResumeInfoContext } from './ResumeInfoContext';
import { Button } from './ui/button';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {toast} from 'react-toastify';

function ResumeBuilder() {
    const { resumeId } = useParams();
    const [resumeInfo, setResumeInfo] = useState();
    const resumeRef = useRef(); // Reference to the ResumePreview section

    useEffect(() => {
        GetResumeInfo();
    }, []);

    const GetResumeInfo = () => {
        console.log("inside the GetResumeInfo")
       
    };

    const handleDownloadPDF = () => {
        const input = document.getElementById('resume-content');
    
        html2canvas(input).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 297; // A4 height in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = 0;
    
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
    
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
    
          pdf.save(`${resumeInfo.firstName}Resume.pdf`); // Specify the name of the downloaded PDF file
          toast.success(`${resumeInfo.firstName}'s Resume Generation successful!`);
        });
      };
    

    const downloadResumeAsPDF = () => {
        const input = resumeRef.current;
        html2canvas(input).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            pdf.save(`${resumeInfo.firstName}_${resumeInfo.lastName}_Resume.pdf`);
        });
    };

    

    return (
        <ResumeInfoContext.Provider value={{ resumeInfo, setResumeInfo }}>
            {/* <div className='flex px-10 my-10 bg-white '>
              
            </div> */}
            {/* <div className='grid grid-cols-1 md:grid-cols-2 p-10 gap-10'> */}
            <div  className='grid grid-cols-1 md:grid-cols-2 p-10 gap-5 w-full bg-white'>
                {/* Form Section */}
                <div className='w-50'>
                <Button onClick={handleDownloadPDF} className="mt-4 bg-green-600 text-white">
                    Download Resume as PDF
                </Button>
                    <FormSection />
                </div>
                {/* Preview Section */}
                <div id="resume-content" ref={resumeRef} className='bg-white '>
                    <ResumePreview />
                </div>

                    
                
            </div>
        </ResumeInfoContext.Provider>
    );
}

export default ResumeBuilder;

