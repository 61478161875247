
import { GoogleGenerativeAI } from "@google/generative-ai";

// const apiKey = `${process.env.GOOGLE_GENAI_API_KEY}`; 
const apiKey = "AIzaSyA8g93McjT0dB_HxTapYTDzAvM4vczlRfw"; 
const genAI = new GoogleGenerativeAI(apiKey);

const model = genAI.getGenerativeModel({
  model: "gemini-1.5-flash-001",
});

const generationConfig = {
  temperature: 1,
  topP: 0.95,
  topK: 64,
  maxOutputTokens: 30192,
  responseMimeType: "application/json",
};

export const AIChatSession = model.startChat({
  generationConfig,
  history: [],
});

export const send = async (prompt) => {
  try {
    const response = await model.sendMessage(prompt, AIChatSession); 
    return response;
  } catch (error) {
    console.error("Error sending prompt:", error);
    throw error;
  }
};