// import { Input } from './ui/input'
// import React, { useContext, useEffect, useState } from 'react'
// import { Rating } from '@smastrom/react-rating'

// import '@smastrom/react-rating/style.css'
// import { Button } from './ui/button'
// import { LoaderCircle } from 'lucide-react'
// import { ResumeInfoContext } from './ResumeInfoContext'
// // import GlobalApi from './../../../../../service/GlobalApi'
// import { useParams } from 'react-router-dom'
// function Skills() {

//     const [skillsList,setSkillsList]=useState(localStorage.getItem("ResumeInfo_Skills") || [{
//         name:'',
//         rating:0
//     }])
//     const {resumeId}=useParams();

//     const [loading,setLoading]=useState(false);
//     const {resumeInfo,setResumeInfo}=useContext(ResumeInfoContext);
   
//     // useEffect(()=>{
//     //     resumeInfo&&setSkillsList(resumeInfo?.skills)
//     //   },[resumeInfo])

//     useEffect(()=>{
//     if(resumeInfo?.skills && resumeInfo.skills.length > 0){
//         setSkillsList(resumeInfo?.skills)
//     }
//     },[resumeInfo]) 
   
//     const handleChange=(index,name,value)=>{
//         const newEntries=skillsList.slice();
      
//         newEntries[index][name]=value;
//         setSkillsList(newEntries);
//     }

//     const AddNewSkills=()=>{
//         setSkillsList([...skillsList,{
//             name:'',
//         rating:0 
//         }])
//     }
//     const RemoveSkills=()=>{
//         setSkillsList(skillsList=>skillsList.slice(0,-1))
//     }

//     const onSave=()=>{

//         setLoading(true);
//         localStorage.setItem("ResumeInfo_Skills", skillsList)
//         const data={
//             data:{
//                 skills:skillsList.map(({ id, ...rest }) => rest)
//             }
//         }

//         // GlobalApi.UpdateResumeDetail(resumeId,data)
//         // .then(resp=>{
//         //     console.log(resp);
//         //     setLoading(false);
//         //     toast('Details updated !')
//         // },(error)=>{
//         //     setLoading(false);
//         //     toast('Server Error, Try again!')
//         // })
//     }

//     useEffect(()=>{
//         setResumeInfo({
//             ...resumeInfo,
//             skills:skillsList
//         })
//     },[skillsList])
//   return (
//     <div className='p-5 shadow-lg rounded-lg border-t-primary border-t-4 mt-10'>
//     <h2 className='font-bold text-lg'>Skills</h2>
//     <p>Add Your top professional key skills</p>

//     <div>
//         {skillsList.map((item,index)=>(
//             <div className='flex justify-between mb-2 border rounded-lg p-3 '>
//                 <div>
//                     <label className='text-xs'>Name</label>
//                     <Input className="w-full"
//                     defaultValue={item.name}
//                     onChange={(e)=>handleChange(index,'name',e.target.value)} />
//                 </div>
//                 <Rating style={{ maxWidth: 120 }} value={item.rating} 
//                 onChange={(v)=>handleChange(index,'rating',v)}/>

//             </div>
//         ))}
//     </div>
//     <div className='flex justify-between'>
//             <div className='flex gap-2'>
//             <Button variant="outline" onClick={AddNewSkills} className="text-primary"> + Add More Skill</Button>
//             <Button variant="outline" onClick={RemoveSkills} className="text-primary"> - Remove</Button>

//             </div>
//             <Button disabled={loading} onClick={()=>onSave()}>
//             {loading?<LoaderCircle className='animate-spin' />:'Save'}    
//             </Button>
//         </div>
//     </div>
//   )
// }

// export default Skills


import { Button } from './ui/button'
import { Input } from './ui/input'
import React, { useContext, useEffect, useState } from 'react'
import RichTextEditor from './RichTextEditor'
import { ResumeInfoContext } from './ResumeInfoContext'
import { useParams } from 'react-router-dom'
import { LoaderCircle } from 'lucide-react'
import { toast } from "react-toastify";





function Skills() {
    const [Skills,setSkillsList]=useState([]);
    const {resumeInfo,setResumeInfo}=useContext(ResumeInfoContext);
    const [loading,setLoading]=useState(false);


    useEffect(() => {
        if (resumeInfo?.Skills && resumeInfo.Skills.length > 0) {
          setSkillsList(resumeInfo.Skills);
        }
       
      }, []);

    const handleChange=(index,event)=>{
        const newEntries=Skills.slice();
        const {name,value}=event.target;
        newEntries[index][name]=value;
        console.log(newEntries)
        setSkillsList(newEntries);
        setResumeInfo({
            ...resumeInfo,
            Skills:Skills
        });
    }

    const AddNewSkill=()=>{
    
        setSkillsList([...Skills,{
            title:'',
            Skills:'',
        }])
    }

    const RemoveSkill=()=>{
        setSkillsList(Skills=>Skills.slice(0,-1))
    }

    const handleRichTextEditor=(e,name,index)=>{
        const newEntries=Skills.slice();
        newEntries[index][name]=e.target.value;
       
        setSkillsList(newEntries);

        setResumeInfo({
            ...resumeInfo,
            Skills:Skills
        });
        console.log("Skills list entries:", Skills)
    }

   


    const onSave=()=>{
        setLoading(true)
         console.log('Skills List',Skills)

    }
  return (
    <div>
        <div className='p-5 shadow-lg rounded-lg border-t-primary border-t-4 mt-10'>
        <h2 className='font-bold text-lg'>Skills</h2>
        <p>Add Your Job Title</p>
        <div>
            {Skills.map((item,index)=>(
                <div key={index}>
                    <div className='grid grid-cols-2 gap-3 border p-3 my-5 rounded-lg'>
                        <div>
                            <label className='text-xs'>Position Title</label>
                            <Input name="title" 
                            onChange={(event)=>handleChange(index,event)}
                            defaultValue={item?.title}
                            />
                        </div>
                        
                        <div className='col-span-2'>
                          
                           <RichTextEditor
                            index={index}
                            defaultValue={item?.Skills}
                            onRichTextEditorChange={(event)=>handleRichTextEditor(event,'Skills',index)}
                            promptTemplate='Please provide the output for the following input - position title: {positionTitle},  provide the Results in 6 Bullet points for skills in 1 or 2 words only. Return the following Skills in JSON format:{skills: ["Skill 1", "Skill 2", "Skill 3"]} . Note : Should not have any word like summary or experience level or json format.'
                            contextType="Skills"
                            />

                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div className='flex justify-between'>
            <div className='flex gap-2'>
            <Button variant="outline" onClick={AddNewSkill} className="text-primary"> + Add More Skills</Button>
            <Button variant="outline" onClick={RemoveSkill} className="text-primary"> - Remove</Button>

            </div>
            <Button disabled={loading} onClick={()=>onSave()}>
            {loading?<LoaderCircle className='animate-spin' />:'Save'}    
            </Button>
        </div>
        </div>
    </div>
  )
}

export default Skills


