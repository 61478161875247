import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.css"; 

// Components
import Login from "./components/Login";
import Signup from "./components/Signup";
import Dashboard from "./components/Dashboard";
import ResumeParser from "./components/ResumeParser/ResumeParser";
import BulkResumeParser from "./components/ResumeParser/BulkResumeParser";
import BulkParser from "./components/ResumeParser/BulkParser";
import ResumeBuilder from "./components/ResumeBuilder/ResumeBuilder";
import Profile from "./components/Profile/Profile";
import Candidates from "./components/Candidates/Candidates";
import Veterans from "./components/Veterans/Veterans";
import CandidateReport from "./components/Report/CandidateReport";
import AnnualReport from "./components/Report/AnnualReport";
import CandidateTable from "./components/Table/CandidateTable";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState(null); 
  const [username, setUsername] = useState(null); 
  const navigate = useNavigate(); 

  // Check for token on page load
  useEffect(() => {
    const storedToken = sessionStorage.getItem("authToken");
    const storedUserType = sessionStorage.getItem("userType");

    if (storedToken && storedUserType) {
      setIsLoggedIn(true);
      setUserType(storedUserType);
    }
  }, []);
 

  const handleLogin = ({token, userType, email, username}) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoggedIn(true);
      setUserType(userType);
      setUsername(username);
      sessionStorage.setItem("authToken", token);
      sessionStorage.setItem("userType", userType);
      sessionStorage.setItem("username", username);
      sessionStorage.setItem("email", email);
      setIsLoading(false);
    }, 3000); // 3000ms = 3 seconds
    navigate("/dashboard");
  };
    

  const handleLogout = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoggedIn(false);
      setUserType(null);
      setUsername(null);
      sessionStorage.removeItem("authToken");
      sessionStorage.removeItem("userType");
      sessionStorage.removeItem("username");
      localStorage.clear();
      sessionStorage.clear();
      setIsLoading(false);
    }, 3000); // 3000ms = 3 seconds
    navigate("/"); // Redirect to login
  };

  

  return (
    <div className="bg-white">
      <Routes> 
          <Route path="/" element={<Login onLogin={handleLogin} />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/dashboard"
            element={
              isLoggedIn ? (
                  <Dashboard userType={userType} username = {username} onLogout={handleLogout} />
              ) : (
                <Login onLogin={handleLogin} />
              )
            }
           
          />
           <Route path="/resume-parser" element={<ResumeParser />} />
           <Route path="/bulk-resume-parser" element={<BulkResumeParser />} />
           <Route path="/bulk-parser" element={<BulkParser />} />
          <Route path="/resume-builder" element={<ResumeBuilder />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/candidates" element={<Candidates />} />
          <Route path="/veterans" element={<Veterans />} /> 
          <Route path="/candidate-report" element={<CandidateReport />} /> 
          <Route path="/candidate-table" element={<CandidateTable />} /> 
          <Route path="/annual-report" element={<AnnualReport />} /> 
      </Routes>

      {/* Loading Screen */}
    {isLoading && ( 
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50"> 
        <div className="bg-white p-4 rounded-md shadow-md">
          <div className="flex items-center">
            <svg
              className="animate-spin h-5 w-5 mr-3"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0C0 5.373 5.373 0 12 0v4m8 11.451c0 1.291-.873 2.403-2.04 2.857H7A2 2 0 015 18c-1.291 0-2.403-.873-2.857-2.04H3M11 7h2m-4 0h2"
              ></path>
            </svg>
            {isLoggedIn ? (
              <span className="text-green-500 font-bold">Yoddah AI Logging-off...</span>
            ) : (
              <span className="text-green-500 font-bold">Yoddah AI Logging-in...</span>
            )}
            {/* <span className="text-green-500 font-bold">Yoddah AI Logging-off...</span>  */}
          </div>
        </div>
      </div>
    )}

    </div>
  );
}

export default App;