import React from 'react'

function SkillsPreview({resumeInfo}) {
    console.log("Resumeinfo for Skills preview: ", resumeInfo)
    const getSummaryValues = () => {
        var data = [resumeInfo.Skills.Skills];
        const jsonParsedData = JSON.parse(data);
        return jsonParsedData.map(item => item.summary);
        };

  return (
    <div className='my-6'>
    <h2 className='text-center font-bold text-sm mb-2'
    style={{
        color:"Black"
    }}
    >Skills</h2>
    <hr style={{
        borderColor:"Black"
    }} />

    {/* <div className='grid grid-cols-2 gap-3 my-4'> */}
    <div className='grid grid-cols-1 '>
            

            {resumeInfo && resumeInfo.Skills && ( 
                        <div className="mt-4">
                            {/* <p className="font-bold text-gray-800">Skills:</p> */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1">
                                {resumeInfo.Skills.map((Skills, index) => (
                                    <div className="break-inside-avoid custom-bullet pl-5">
                                        <ul key={index} className="list-disc text-xs  text-gray-600">
                                            <li dangerouslySetInnerHTML={{ __html: Skills.Skills }} ></li>
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

        {/* <div  className='my-5'>
            <h2 className='text-sm font-bold'
            style={{
                color: resumeInfo?.themeColor
            }}>{resumeInfo.Skills.title}</h2>
 
            <div className='text-xs my-2 justify-evenly' dangerouslySetInnerHTML={{ __html: resumeInfo.Skills.Skills }} />
        </div> */}
    </div>
    </div>
  )
}

export default SkillsPreview
//---------------above is working--------------------------


// import React from 'react';

// function SkillsPreview({ resumeInfo }) {
//     // const sortedExperience = resumeInfo?.Experience?.slice().sort((a, b) => {
//     //     const dateA = new Date(a.currentlyWorking ? new Date() : a.endDate);
//     //     const dateB = new Date(b.currentlyWorking ? new Date() : b.endDate);
//     //     return dateB - dateA;
//     // });
//     var data = resumeInfo.Skills.Skills;
//     const getSummaryValues = (data) => {
//         return data.map(item => item.summary);
//       };
      

//     return (
//         <div className='my-2'>
//             <h2 className='text-center font-bold text-sm mb-2'
//                 style={{
//                     color: resumeInfo?.themeColor
//                 }}
//             >Skills</h2>
//             <hr style={{
//                 // borderColor: resumeInfo?.themeColor
//                  borderColor: "black"
//             }} />

//             {/* {sortedExperience?.map((experience, index) => (
//                 <div key={index} className='my-5'>
//                     <h2 className='text-sm font-bold'
//                         style={{
//                             color: resumeInfo?.themeColor
//                         }}>{experience?.title}</h2>
//                     <h2 className='text-xs flex justify-between italic'>{experience?.companyName},{' '}
//                         {experience?.city},{' '}
//                         {experience?.state}
//                         <span>{experience?.startDate} To {experience?.currentlyWorking ? 'Present' : experience.endDate} </span>
//                     </h2>
//                     <div className='text-xs my-2 justify-evenly' dangerouslySetInnerHTML={{ __html: experience?.workSummary }} />
//                 </div>
//             ))} */}

            
//                 <div  className='my-5'>
//                     <h2 className='text-sm font-bold'
//                         style={{
//                             color: resumeInfo?.themeColor
//                         }}>{resumeInfo?.Skills?.title}</h2>
                    
//                     <div className='text-xs my-2 justify-evenly' dangerouslySetInnerHTML={{ __html: getSummaryValues(resumeInfo?.Skills?.Skills) }} />
//                 </div>
            
//         </div>
//     );
// }

// export default SkillsPreview;
