import React, { useState, useContext } from "react";import AnnualReport from "./AnnualReport";
import CandidateReport from "./CandidateReport";
import DailyReport from "./DailyReport"
import QuarterlyReport from "./QuarterlyReport";
import HalfYearlyReport from "./HalfYearlyReport";
import {
  MdHome,
  MdOutlineDescription,
  MdOutlineBuild,
  MdOutlineAccountCircle,
  MdDataArray,
  MdDescription,
} from "react-icons/md";

const Report = ({isSidebarOpen}) => {
  // const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [currentView, setCurrentView] = useState("annual-report"); // State to manage current view
  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      
      <div className={`bg-white shadow-sm text-black fixed top-0  h-full flex flex-col transition-all duration-300 ease-in-out   
      ${
          isSidebarOpen ? "w-64 left-64" : "w-64 left-20" 
        }`}
      
      >
      {/* <div className={`bg-gray-200 shadow-sm text-black w-64 left-64 fixed top-0  h-full flex flex-col transition-all duration-300 ease-in-out `}
        style={{ backgroundImage: 'linear-gradient(90deg, rgba(255,253,159,1) 21%, rgba(255,217,183,1) 100%, rgba(255,255,255,1) 100%)'  }}
      > */}
      <ul className="space-y-2 flex-1 px-4 overflow-y-auto">
      <div className="p-2">
              <h2 className="text-amber-600 text-xl font-bold mb-2">
                AI Based  
              </h2>
              <h2 className="text-black text-xl font-bold mb-4">
                Yoddah Report
              </h2>
            </div>
        <li><button
          onClick={() => handleViewChange("annual-report")}
          className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
            currentView === "annual-report" ? "bg-green-300" : ""
          }`}
        >
          <MdDescription size={30} className="hover:animate-pulse" />
          <span className="ml-2">Annual Report</span>
        </button></li>
        <li><button
          onClick={() => handleViewChange("candidate-report")}
          className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
            currentView === "candidate-report" ? "bg-green-300" : ""
          }`}
        >
          <MdDescription size={30} className="hover:animate-pulse" />
          <span className="ml-2">Candidate Report</span>
        </button></li>
        <li><button
          onClick={() => handleViewChange("quarterly-report")}
          className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
            currentView === "quarterly-report" ? "bg-green-300" : ""
          }`}
        >
          <MdDescription size={30} className="hover:animate-pulse" />
          <span className="ml-2">Quarterly Report</span>
        </button></li>
        <li><button
          onClick={() => handleViewChange("daily-report")}
          className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
            currentView === "daily-report" ? "bg-green-300" : ""
          }`}
        >
          <MdDescription size={30} className="hover:animate-pulse" />
          <span className="ml-2">Daily Report</span>
        </button></li>
        <li><button
          onClick={() => handleViewChange("halfyearly-report")}
          className={`block text-black  hover:text-green-800 rounded-md px-3 py-2 flex items-center active:animate-ping ${
            currentView === "halfyearly-report" ? "bg-green-300" : ""
          }`}
        >
          <MdDescription size={30} className="hover:animate-pulse" />
          <span className="ml-2">Halfyearly Report</span>
        </button></li>
       </ul>
       
      </div>


      <div
     className={`flex-1  p-5 transition-all duration-300 ease-in-out bg-white  ${
       isSidebarOpen ? "ml-64 max-w-full" : "ml-64 max-w-full"
     }`}
   >
     {currentView === "annual-report" &&  <AnnualReport/>}
     {currentView === "candidate-report" &&  <CandidateReport/>}
     {currentView === "quarterly-report" &&  <QuarterlyReport/>}
     {currentView === "daily-report" &&  <DailyReport/>}
     {currentView === "halfyearly-report" &&  <HalfYearlyReport/>}
    
      </div>
    </div>
  );
};

export default Report;
