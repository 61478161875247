import React from 'react'

function SummaryPreview({resumeInfo}) {
  return (
  <>
    <p className='text-xs text-justify'>
        {resumeInfo?.summary}
    </p>
    
  </>
  )
}

export default SummaryPreview