// import React, { useState, useEffect } from 'react';
// import { AIChatSession } from '../../service/AIModel';
// import { useReactToPrint } from 'react-to-print'; // Import the library for printing
// import { saveAs } from 'file-saver'; // Import the library for saving files
// import * as XLSX from 'xlsx'; // Import XLSX for CSV generation
// import pdfToText from "react-pdftotext";
// import {toast} from 'react-toastify';

// function BulkResumeParser() {
//   const [resumeFiles, setResumeFiles] = useState([]);
//   const [jobDescription, setJobDescription] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [candidateDetails, setCandidateDetails] = useState([]);

//   // Function to handle resume file uploads
//   const handleResumeUpload = (event) => {
//     const files = event.target.files;
//     const newFiles = [...resumeFiles];
//     for (let i = 0; i < files.length; i++) {
//       newFiles.push(files[i]);
//     }
//     setResumeFiles(newFiles);
//     toast.success("Files Uploaded successfully!")
//   };

  

//   const extractTextFromPDF = (file)=> {
//     console.log("inside extract from pdf")
//     return pdfToText(file)
//         .then(text => {
 
//           console.log("Extracted Text in method extractTextFromPDF", text);
//         })
//         .catch(error => console.error("Failed to extract text from pdf"))
//   }

//   // Function to generate candidate details from AI
//   const generateCandidateDetails = async () => {
//     console.log('generateCandidateDetails function called!');
//     setIsLoading(true);

//     try {
//       const extractedResumes = [];
//       for (let i = 0; i < resumeFiles.length; i++) {
//         const file = resumeFiles[i];
//         const extractedText = await extractTextFromPDF(file);
//         extractedResumes.push(extractedText);
//       }

//       const promises = extractedResumes.map(async (resume) => {
//         const prompt = `Extract candidate details from this resume: ${resume} Considering the job description: ${jobDescription} Return the following details in JSON format: { rank: 1, // Assign a rank based on matching percentage name: "Candidate Name", matchingPercentage: 85, phone: "123-456-7890", email: "candidate@example.com", skills: ["Skill 1", "Skill 2", "Skill 3"], strengths: ["Strength 1", "Strength 2"], weaknesses: ["Weakness 1"] }`;
//         const response = await AIChatSession.sendMessage(prompt);
//         const candidateData = JSON.parse(response.response.text());
//         console.log("candidate data:", candidateData);
//         return candidateData;
//       });

//       const results = await Promise.all(promises);
//       // Sort results by rank in ascending order
//       const sortedResults = results.sort((a, b) => a.rank - b.rank);
//       setCandidateDetails(sortedResults);
//     } catch (error) {
//       console.error('Error generating candidate details:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   // Function to download the candidate details table as a CSV file
//   const downloadCSV = () => {
//     const worksheet = XLSX.utils.json_to_sheet(candidateDetails);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Candidates');
//     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//     saveAs(
//       new Blob([excelBuffer], { type: 'application/octet-stream' }),
//       'candidates.xlsx'
//     );
//   };

//   return (
//     <div className="container mx-auto p-4">
//       <h1 className="text-3xl font-bold mb-4">Bulk Resume Parser</h1>

//       <div className="bg-white shadow-md rounded-md p-4">
//         <h2 className="text-xl font-bold mb-2">Upload Resumes</h2>
//         <input
//           type="file"
//           accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
//           onChange={handleResumeUpload}
//           multiple
//           className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-600 hover:file:bg-blue-100"
//         />
//       </div>

//       <div className="bg-white shadow-md rounded-md p-4 mt-4">
//         <h2 className="text-xl font-bold mb-2">Job Description</h2>
//         <textarea
//           value={jobDescription}
//           onChange={(e) => setJobDescription(e.target.value)}
//           className="border border-gray-300 rounded-md p-2 w-full"
//           rows="5"
//           placeholder="Enter the job description..."
//         ></textarea>
//       </div>

//       <div className="bg-white shadow-md rounded-md p-4 mt-4">
//         <button
//           className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition"
//           onClick={generateCandidateDetails}
//           disabled={isLoading || !resumeFiles.length || !jobDescription}
//         >
//           Generate Candidate Details
//         </button>
//       </div>

//       {/* Loading Screen */}
//       {isLoading && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
//           <div className="bg-white p-4 rounded-md shadow-md">
//             <div className="flex items-center">
//               <svg
//                 className="animate-spin h-5 w-5 mr-3"
//                 viewBox="0 0 24 24"
//               >
//                 <circle
//                   className="opacity-25"
//                   cx="12"
//                   cy="12"
//                   r="10"
//                   stroke="currentColor"
//                   strokeWidth="4"
//                 ></circle>
//                 <path
//                   className="opacity-75"
//                   fill="none"
//                   stroke="currentColor"
//                   strokeWidth="2"
//                   d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0C0 5.373 5.373 0 12 0v4m8 11.451c0 1.291-.873 2.403-2.04 2.857H7A2 2 0 015 18c-1.291 0-2.403-.873-2.857-2.04H3M11 7h2m-4 0h2"
//                 ></path>
//               </svg>
//               <span className="text-green-500 font-bold">
//                 Yoddah AI Parsing...
//               </span>
//             </div>
//           </div>
//         </div>
//       )}

//       {candidateDetails.length > 0 && (
//         <div className="mt-4">
//           <button
//             className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition mb-4"
//             onClick={downloadCSV}
//           >
//             Download CSV
//           </button>

//           <table className="w-full table-auto">
//             <thead>
//               <tr>
//                 <th className="px-4 py-2">Rank</th>
//                 <th className="px-4 py-2">Name</th>
//                 <th className="px-4 py-2">Matching Percentage</th>
//                 <th className="px-4 py-2">Phone</th>
//                 <th className="px-4 py-2">Email</th>
//                 <th className="px-4 py-2">Skills</th>
//                 <th className="px-4 py-2">Strengths</th>
//                 <th className="px-4 py-2">Weaknesses</th>
//               </tr>
//             </thead>
//             <tbody>
//               {candidateDetails.map((candidate, index) => (
//                 <tr key={index}>
//                   <td className="border px-4 py-2">{candidate.rank}</td>
//                   <td className="border px-4 py-2">{candidate.name}</td>
//                   <td className="border px-4 py-2">
//                     {candidate.matchingPercentage}%
//                   </td>
//                   <td className="border px-4 py-2">{candidate.phone}</td>
//                   <td className="border px-4 py-2">{candidate.email}</td>
//                   <td className="border px-4 py-2">
//                     {candidate.skills.join(', ')}
//                   </td>
//                   <td className="border px-4 py-2">
//                     {candidate.strengths.join(', ')}
//                   </td>
//                   <td className="border px-4 py-2">
//                     {candidate.weaknesses.join(', ')}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}
//     </div>
//   );
// }

// export default BulkResumeParser;

//partially working bulk parsing code-----
// import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { AIChatSession } from '../../service/AIModel';
// import { useReactToPrint } from 'react-to-print'; // Import the library for printing
// import { saveAs } from 'file-saver'; // Import the library for saving files
// import * as XLSX from 'xlsx'; // Import XLSX for CSV generation
// import pdfToText from 'react-pdftotext';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import PizZip from "pizzip";
// import { DOMParser } from "@xmldom/xmldom";

// function str2xml(str) {
//     if (str.charCodeAt(0) === 65279) {
//       // BOM sequence
//       str = str.substr(1);
//     }
//     return new DOMParser().parseFromString(str, "text/xml");
//   }
  
//   // Get paragraphs as javascript array
//   function getParagraphs(content) {
//     const zip = new PizZip(content);
//     const xml = str2xml(zip.files["word/document.xml"].asText());
//     const paragraphsXml = xml.getElementsByTagName("w:p");
//     const paragraphs = [];
  
//     for (let i = 0, len = paragraphsXml.length; i < len; i++) {
//       let fullText = "";
//       const textsXml = paragraphsXml[i].getElementsByTagName("w:t");
//       for (let j = 0, len2 = textsXml.length; j < len2; j++) {
//         const textXml = textsXml[j];
//         if (textXml.childNodes) {
//           fullText += textXml.childNodes[0].nodeValue;
//         }
//       }
//       if (fullText) {
//         paragraphs.push(fullText);
//       }
//     }
//     return paragraphs;
//   }

 
  


// function BulkResumeParser() {
//     const [paragraphs, setParagraphs] = useState([]);
//     const [resumeFiles, setResumeFiles] = useState([]);
//     const [jobDescription, setJobDescription] = useState('');
//     const [isLoading, setIsLoading] = useState(false);
//     const [candidateDetails, setCandidateDetails] = useState([]);
    

//     const onFileUpload = (event) => {
//         const reader = new FileReader();
//         let file = event.target.files[0];
    
//         reader.onload = (e) => {
//           const content = e.target.result;
//           const paragraphs = getParagraphs(content);
//           setParagraphs(paragraphs);
//         };
    
//         reader.onerror = (err) => console.error(err);
    
//         reader.readAsBinaryString(file);
//       };

//   // Function to handle resume file uploads
//     const handleResumeUpload = (event) => {
//     const files = event.target.files;
//     // (files.type === "docx")?{

//     // }:{

//     // }
//     const newFiles = [...resumeFiles];
//     for (let i = 0; i < files.length; i++) {
//       newFiles.push(files[i]);
//     }
//     setResumeFiles(newFiles);
//     toast.success('Files Uploaded successfully!');
//   };

//   const extractTextFromPDF = (file) => {
//     console.log('inside extract from pdf');
//     return pdfToText(file)
//       .then((text) => {
//         console.log('Extracted Text in method extractTextFromPDF', text);
//         return text;
//       })
//       .catch((error) => console.error('Failed to extract text from pdf'));
//   };

//   // Function to generate candidate details from AI
//   const generateCandidateDetails = async () => {
//     console.log('generateCandidateDetails function called!');
//     setIsLoading(true);

//     try {
//       const extractedResumes = [];
//       for (let i = 0; i < resumeFiles.length; i++) {
//         const file = resumeFiles[i];
//         const extractedText = await extractTextFromPDF(file);
//         extractedResumes.push(extractedText);
//       }

//       const promises = extractedResumes.map(async (resume, index) => {
//         const prompt = `Extract candidate details from this resume: ${resume} Considering the job description: ${jobDescription} Return the following details in JSON format: { rank: ${index + 1}, // Assign a rank based on matching percentage name: "Candidate Name", matchingPercentage: 85, phone: "123-456-7890", email: "candidate@example.com", skills: ["Skill 1", "Skill 2", "Skill 3"], strengths: ["Strength 1", "Strength 2"], weaknesses: ["Weakness 1"] }`;
//         const response = await AIChatSession.sendMessage(prompt);
//         const candidateData = JSON.parse(response.response.text());
//         console.log('candidate data:', candidateData);
//         return candidateData;
//       });

//       const results = await Promise.all(promises);
//       // Sort results by rank in ascending order
//       const sortedResults = results.sort((a, b) => a.rank - b.rank);
//       setCandidateDetails(sortedResults.slice(0, 10)); // Get top 10 candidates
//     } catch (error) {
//       console.error('Error generating candidate details:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   // Function to download the candidate details table as a CSV file
//   const downloadCSV = () => {
//     const worksheet = XLSX.utils.json_to_sheet(candidateDetails);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Candidates');
//     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//     saveAs(
//       new Blob([excelBuffer], { type: 'application/octet-stream' }),
//       'candidates.xlsx'
//     );
//   };

//   return (
//     <div className="container mx-auto p-4">
//       <h1 className="text-3xl font-bold mb-4">Bulk Resume Parser</h1>

//       <div className="bg-white shadow-md rounded-md p-4">
//         <h2 className="text-xl font-bold mb-2">Upload Resumes</h2>
//         <input
//           type="file"
//           accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
//           onChange={handleResumeUpload}
//           multiple
//           className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-600 hover:file:bg-blue-100"
//         />
//       </div>

//       <div className="bg-white shadow-md rounded-md p-4 mt-4">
//         <h2 className="text-xl font-bold mb-2">Job Description</h2>
//         <textarea
//           value={jobDescription}
//           onChange={(e) => setJobDescription(e.target.value)}
//           className="border border-gray-300 rounded-md p-2 w-full"
//           rows="5"
//           placeholder="Enter the job description..."
//         ></textarea>
//       </div>

//       <div className="bg-white shadow-md rounded-md p-4 mt-4">
//         <button
//           className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition"
//           onClick={generateCandidateDetails}
//           disabled={isLoading || !resumeFiles.length || !jobDescription}
//         >
//           Generate Candidate Details
//         </button>
//       </div>

//       {/* Loading Screen */}
//       {isLoading && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
//           <div className="bg-white p-4 rounded-md shadow-md">
//             <div className="flex items-center">
//               <svg
//                 className="animate-spin h-5 w-5 mr-3"
//                 viewBox="0 0 24 24"
//               >
//                 <circle
//                   className="opacity-25"
//                   cx="12"
//                   cy="12"
//                   r="10"
//                   stroke="currentColor"
//                   strokeWidth="4"
//                 ></circle>
//                 <path
//                   className="opacity-75"
//                   fill="none"
//                   stroke="currentColor"
//                   strokeWidth="2"
//                   d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0C0 5.373 5.373 0 12 0v4m8 11.451c0 1.291-.873 2.403-2.04 2.857H7A2 2 0 015 18c-1.291 0-2.403-.873-2.857-2.04H3M11 7h2m-4 0h2"
//                 ></path>
//               </svg>
//               <span className="text-green-500 font-bold">
//                 Yoddah AI Parsing...
//               </span>
//             </div>
//           </div>
//         </div>
//       )}

//       {candidateDetails.length > 0 && (
//         <div className="mt-4">
//           <button
//             className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition mb-4"
//             onClick={downloadCSV}
//           >
//             Download CSV
//           </button>

//           <table className="w-full table-auto">
//             <thead>
//               <tr>
//                 <th className="px-4 py-2">Rank</th>
//                 <th className="px-4 py-2">Name</th>
//                 <th className="px-4 py-2">Matching Percentage</th>
//                 <th className="px-4 py-2">Phone</th>
//                 <th className="px-4 py-2">Email</th>
//                 <th className="px-4 py-2">Skills</th>
//                 <th className="px-4 py-2">Strengths</th>
//                 <th className="px-4 py-2">Weaknesses</th>
//               </tr>
//             </thead>
//             <tbody>
//               {candidateDetails.map((candidate, index) => (
//                 <tr key={index}>
//                   <td className="border px-4 py-2">{candidate.rank}</td>
//                   <td className="border px-4 py-2">{candidate.name}</td>
//                   <td className="border px-4 py-2">
//                     {candidate.matchingPercentage}%
//                   </td>
//                   <td className="border px-4 py-2">{candidate.phone}</td>
//                   <td className="border px-4 py-2">{candidate.email}</td>
//                   <td className="border px-4 py-2">
//                     {candidate.skills.join(', ')}
//                   </td>
//                   <td className="border px-4 py-2">
//                     {candidate.strengths.join(', ')}
//                   </td>
//                   <td className="border px-4 py-2">
//                     {candidate.weaknesses.join(', ')}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}
//     </div>
//   );
// }

// export default BulkResumeParser;


//trying partial v1---bulk
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AIChatSession } from '../../service/AIModel';
import { useReactToPrint } from 'react-to-print'; // Import the library for printing
import { saveAs } from 'file-saver'; // Import the library for saving files
import * as XLSX from 'xlsx'; // Import XLSX for CSV generation
import pdfToText from 'react-pdftotext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BulkResumeParser() {
  const [resumeFiles, setResumeFiles] = useState([]);
  const [jobDescription, setJobDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState([]);
  const [showFullDetails, setShowFullDetails] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  // Function to handle resume file uploads
  const handleResumeUpload = (event) => {
    const files = event.target.files;
    const newFiles = [...resumeFiles];
    for (let i = 0; i < files.length; i++) {
      newFiles.push(files[i]);
    }
    setResumeFiles(newFiles);
    toast.success('Files Uploaded successfully!');
  };

  const extractTextFromPDF = (file) => {
    console.log('inside extract from pdf');
    return pdfToText(file)
      .then((text) => {
        console.log('Extracted Text in method extractTextFromPDF', text);
        return text;
      })
      .catch((error) => console.error('Failed to extract text from pdf'));
  };

  // Function to generate candidate details from AI
  const generateCandidateDetails = async () => {
    console.log('generateCandidateDetails function called!');
    setIsLoading(true);

    try {
      const extractedResumes = [];
      for (let i = 0; i < resumeFiles.length; i++) {
        const file = resumeFiles[i];
        const extractedText = await extractTextFromPDF(file);
        extractedResumes.push(extractedText);
      }

      const promises = extractedResumes.map(async (resume, index) => {
        const prompt = `Extract candidate details from this resume: ${resume} Considering the job description: ${jobDescription} Return the following details in JSON format: { rank: ${index + 1}, // Assign a rank based on matching percentage name: "Candidate Name", matchingPercentage: 85, phone: "123-456-7890", email: "candidate@example.com", skills: ["Skill 1", "Skill 2", "Skill 3"], strengths: ["Strength 1", "Strength 2"], weaknesses: ["Weakness 1"] }`;
        const response = await AIChatSession.sendMessage(prompt);
        const candidateData = JSON.parse(response.response.text());
        console.log('candidate data:', candidateData);
        return candidateData;
      });

      const results = await Promise.all(promises);
      // Sort results by rank in ascending order
      const sortedResults = results.sort((a, b) => a.rank - b.rank);
      setCandidateDetails(sortedResults.slice(0, 10)); // Get top 10 candidates
    } catch (error) {
      console.error('Error generating candidate details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to download the candidate details table as a CSV file
  const downloadCSV = () => {
    const worksheet = XLSX.utils.json_to_sheet(candidateDetails);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Candidates');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAs(
      new Blob([excelBuffer], { type: 'application/octet-stream' }),
      'candidates.xlsx'
    );
  };

  const handleShowAllClick = (candidate) => {
    setSelectedCandidate(candidate);
    setShowFullDetails(true);
  };

  const handleCloseModal = () => {
    setShowFullDetails(false);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Bulk Resume Parser</h1>

      <div className="bg-white shadow-md rounded-md p-4">
        <h2 className="text-xl font-bold mb-2">Upload Resumes</h2>
        <input
          type="file"
          accept="application/pdf, application/docx, .docx, .pdf"
          onChange={handleResumeUpload}
          multiple
          className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-600 hover:file:bg-blue-100"
        />
      </div>

      <div className="bg-white shadow-md rounded-md p-4 mt-4">
        <h2 className="text-xl font-bold mb-2">Job Description</h2>
        <textarea
          value={jobDescription}
          onChange={(e) => setJobDescription(e.target.value)}
          className="border border-gray-300 rounded-md p-2 w-full"
          rows="5"
          placeholder="Enter the job description..."
        ></textarea>
      </div>

      <div className="bg-white shadow-md rounded-md p-4 mt-4">
        <button
          className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition"
          onClick={generateCandidateDetails}
          disabled={isLoading || !resumeFiles.length || !jobDescription}
        >
          Generate Candidate Details
        </button>
      </div>

      {/* Loading Screen */}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-4 rounded-md shadow-md">
            <div className="flex items-center">
              <svg
                className="animate-spin h-5 w-5 mr-3"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0C0 5.373 5.373 0 12 0v4m8 11.451c0 1.291-.873 2.403-2.04 2.857H7A2 2 0 015 18c-1.291 0-2.403-.873-2.857-2.04H3M11 7h2m-4 0h2"
                ></path>
              </svg>
              <span className="text-green-500 font-bold">
                Yoddah AI Parsing...
              </span>
            </div>
          </div>
        </div>
      )}

      {candidateDetails && (
        <div className="mt-4">
          <button
            className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition mb-4"
            onClick={downloadCSV}
          >
            Download CSV
          </button>

          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left rtl:text-right text-grey-100 dark:text-gray-700">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-100 dark:text-gray-700">
                <tr>
                  <th scope="col" class="p-4">
                    <div class="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label for="checkbox-all-search" class="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Matching Percentage
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Phone
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Skills
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Strengths
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Weaknesses
                  </th>
                </tr>
              </thead>
              <tbody>
                {candidateDetails.slice(0, 10).map((candidate, index) => (
                  <tr
                    key={index}
                    class="bg-white border-b dark:bg-gray-100 dark:border-gray-500 hover:bg-gray-50 dark:hover:bg-gray-200 dark:text-grey"
                  >
                    <td class="w-4 p-4">
                      <div class="flex items-center">
                        <input
                          id={`checkbox-table-search-${index + 1}`}
                          type="checkbox"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for={`checkbox-table-search-${index + 1}`}
                          class="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </td>
                    <th
                      scope="row"
                      class="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-grey-500"
                    >

                      <div class="ps-3">
                        <div class="text-base font-semibold text-grey-900 ">
                          {candidateDetails.name}
                        </div>
                        <div class="font-normal text-gray-900 ">
                          {candidateDetails.email}
                        </div>
                      </div>
                    </th>
                    <td class="px-6 py-4">
                      {candidateDetails.matchingPercentage}%
                    </td>
                    <td class="px-6 py-4">{candidateDetails.phone}</td>
                    <td class="px-6 py-4">{candidateDetails.email}</td>
                    <td class="px-6 py-4 relative" onClick={() => handleShowAllClick(candidateDetails)}>
                      <div className="truncate">
                        {candidateDetails.skills.slice(0, 3).join(', ')}
                        {candidateDetails.skills.length > 3 && (
                          <span className="text-gray-500 font-bold"> ...</span>
                        )}
                      </div>
                    </td>
                    <td class="px-6 py-4" onClick={() => handleShowAllClick(candidateDetails)}>
                      <div className="truncate">
                        {candidateDetails.strengths.slice(0, 3).join(', ')}
                        {candidateDetails.strengths.length > 3 && (
                          <span className="text-gray-500 font-bold"> ...</span>
                        )}
                      </div>
                    </td>
                    <td class="px-6 py-4" onClick={() => handleShowAllClick(candidateDetails)}>
                      <div className="truncate">
                        {candidateDetails.weaknesses.slice(0, 3).join(', ')}
                        {candidateDetails.weaknesses.length > 3 && (
                          <span className="text-gray-500 font-bold"> ...</span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Modal for showing complete details */}
          <div
            id="show-all-details"
            tabindex="-1"
            aria-hidden="true"
            className={`fixed top-0 left-0 right-0 z-50 items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full ${
              showFullDetails ? 'block' : 'hidden'
            }`}
          >
            <div className="relative w-full max-w-2xl max-h-full">
              {/* Modal content */}
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-100">
                {/* Modal header */}
                <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-grey">
                    Candidate Details
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={handleCloseModal}
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* Modal body */}
                <div className="p-6 space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <p className="font-semibold text-gray-800">
                        Name:
                      </p>
                      <p className="text-gray-600">
                        {selectedCandidate?.name}
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold text-gray-800">
                        Matching Percentage:
                      </p>
                      <p className="text-gray-600">
                        {selectedCandidate?.matchingPercentage}%
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold text-gray-800">
                        Phone:
                      </p>
                      <p className="text-gray-600">
                        {selectedCandidate?.phone}
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold text-gray-800">
                        Email:
                      </p>
                      <p className="text-gray-600">
                        {selectedCandidate?.email}
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold text-gray-800">
                        Skills:
                      </p>
                      <ul className="list-disc pl-5 text-gray-600">
                        {selectedCandidate?.skills?.map((skill) => (
                          <li key={skill}>{skill}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <p className="font-semibold text-gray-800">
                        Strengths:
                      </p>
                      <ul className="list-disc pl-5 text-gray-600">
                        {selectedCandidate?.strengths?.map((strength) => (
                          <li key={strength}>{strength}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <p className="font-semibold text-gray-800">
                        Weaknesses:
                      </p>
                      <ul className="list-disc pl-5 text-gray-600">
                        {selectedCandidate?.weaknesses?.map((weakness) => (
                          <li key={weakness}>{weakness}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BulkResumeParser;