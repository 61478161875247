// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Chart from 'chart.js/auto';
// import { Bar, Line, Pie } from 'react-chartjs-2';
// import { AIChatSession } from '../../service/AIModel';

// const CandidateReport = () => {
//   const [reportData, setReportData] = useState(null);
//   const [reportPromptData, setReportPromptData] = useState("");
//   const [insights, setInsights] = useState({ salesTrendInsights: [], reasonsForSalesChange: [] });
//   const [loading, setLoading] = useState(true);
//   const [insightsData, setInsightsData] = useState([])

//  const generateInsights = async () => {
//    try {
//      setLoading(true);
//      console.log('generateInsights function called!');
//      const PROMPT = `Based on the following data, ${reportPromptData} - provide in paragraph - what was sales trend insights and why did sales change?`;
//      console.log("promt before sending: ", PROMPT)
//      const response = await AIChatSession.sendMessage(PROMPT);
//     console.log('API Response:', response); // Log the response
//     const aiResponse = JSON.parse(response.response.text());
//     setInsightsData(aiResponse);
//     localStorage.setItem('aiInsights_response', JSON.stringify(aiResponse));
//     console.log("insights Data: ", insightsData)

//   } catch (error) {
//     console.error('Error generating AI Insights:', error);
//   }finally{
//     setLoading(false);
//   }

//  }

//   useEffect( () => {
//     // Fetch report data from the backend
//     axios.get(`${process.env.REACT_APP_SERVER_API_URL}/api/report-data`)
//       .then( async (response) => {
//         const repData = JSON.stringify(response.data);
//         setReportData(response.data);
//         setReportPromptData(repData);
//         console.log('Report Data: ',reportData);
//         console.log('Report string Data: ',reportPromptData);
//         // const PROMPT = `Based on the following data, provide sales trend insights and why did sales change? in bullet points  for the report: ${reportData}`;
//         // const aiResponse = await AIChatSession.sendMessage(prompt);
//         // console.log('AI Response:', aiResponse); // Log the response
//         // const aiInsightsData = JSON.parse(aiResponse.response.text());
//         // setInsightsData(aiInsightsData);
//         // localStorage.setItem('aiInsightsData', JSON.stringify(aiInsightsData));

//         // return aiInsightsData
//         return axios.post(`${process.env.REACT_APP_SERVER_API_URL}/api/generate-insights`, { data: response.data });
//       })
//       .then((response) => {
//         const iData = response.data.insights || { salesTrendInsights: [], reasonsForSalesChange: [] };
//         setInsights(iData);
//         setInsightsData(iData)
//         console.log('Fetched insights:', insightsData); // Log the fetched insights
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error('Error fetching report data:', error);
//         setLoading(false);
//       });
//   }, []);

//   if (loading) {
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-green-500">

//         </div>
//         <span className="fixed animate-bounce font-bold text-xs">Yoddah AI Generating...</span>
//       </div>
//     );
//   }

//   return (
//     <div className="p-8 bg-white min-h-screen">
//       <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Annual Report</h1>

//        <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
//         <h2 className="text-xl font-semibold mb-4 text-gray-700">Yoddah AI-Generated Insights</h2>
//            <button
//             className="bg-blue-500 text-white ml-4 px-6 py-2 rounded-full hover:bg-blue-800 transition"
//             onClick={generateInsights}
//             >
//             Generate Insights
//           </button></div>

//        <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
//              { insightsData.salesTrendInsights && (
//               <div className="mt-4">
//                 <p className="font-bold text-gray-800">salesTrendInsights:</p>
//                   {insightsData.salesTrendInsights.map((i, index) => (
//                     <div key={index} className="break-inside-avoid custom-bullet pl-5">
//                       <ul className="list-disc pl-5 text-gray-600">
//                           <li key={i}>{i}</li>
//                       </ul>
//                     </div>
//                     ))}
//               </div>
//             )}
//             </div>
//             <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
//               <p>{insights}</p>
//             </div>

//       {reportData && (
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//           <div className="bg-white p-6 rounded-lg shadow-lg">
//             <h3 className="text-lg font-semibold mb-4 text-gray-700">Sales Overview</h3>
//             <Bar data={reportData.salesOverview} />
//           </div>

//           <div className="bg-white p-6 rounded-lg shadow-lg">
//             <h3 className="text-lg font-semibold mb-4 text-gray-700">Revenue Growth</h3>
//             <Line data={reportData.revenueGrowth} />
//           </div>

//           <div className="bg-white p-6 rounded-lg shadow-lg">
//             <h3 className="text-lg font-semibold mb-4 text-gray-700">Market Share</h3>
//             <Pie data={reportData.marketShare} />
//           </div>

//           <div className="bg-white p-6 rounded-lg shadow-lg">
//             <h3 className="text-lg font-semibold mb-4 text-gray-700">Customer Satisfaction</h3>
//             <Bar data={reportData.customerSatisfaction} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CandidateReport;

// import React, { useEffect, useState } from 'react';
// import { Bar, Pie, Line } from 'react-chartjs-2';
// import 'chart.js/auto';  // Import chart.js for charts rendering
// import axios from 'axios';

// const CandidateReport = () => {
//   const [candidates, setCandidates] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     // Fetch candidate data from API
//     const fetchCandidates = async () => {
//       try {
//           setLoading(true)
//           const response = await axios.get(`${process.env.REACT_APP_SERVER_API_URL}/api/candidates`);  // Replace with your API endpoint
//           setCandidates(response.data);
//       } catch (error) {
//         console.error('Error fetching candidate data:', error);
//       }finally{
//         setLoading(false)
//       }
//     };

//     fetchCandidates();
//   }, []);

//   const prepareDataForCharts = () => {
//     const locations = {};
//     const skills = {};
//     const educationLevels = {};

//     candidates.forEach((candidate) => {
//       // Group by location
//       if (locations[candidate.Current_Location]) {
//         locations[candidate.Current_Location] += 1;
//       } else {
//         locations[candidate.Current_Location] = 1;
//       }

//       // Group by skills
//       candidate.Current_Skills.forEach((skill) => {
//         if (skills[skill]) {
//           skills[skill] += 1;
//         } else {
//           skills[skill] = 1;
//         }
//       });

//       // Group by education level
//       if (educationLevels[candidate.Education]) {
//         educationLevels[candidate.Education] += 1;
//       } else {
//         educationLevels[candidate.Education] = 1;
//       }
//     });

//     return { locations, skills, educationLevels };
//   };

//   const { locations, skills, educationLevels } = prepareDataForCharts();

//   const locationChartData = {
//     labels: Object.keys(locations),
//     datasets: [
//       {
//         label: 'Number of Candidates',
//         data: Object.values(locations),
//         backgroundColor: 'rgba(75,192,192,0.6)',
//         borderColor: 'rgba(75,192,192,1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   const skillChartData = {
//     labels: Object.keys(skills),
//     datasets: [
//       {
//         label: 'Number of Candidates with Skill',
//         data: Object.values(skills),
//         backgroundColor: 'rgba(153,102,255,0.6)',
//         borderColor: 'rgba(153,102,255,1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   const educationChartData = {
//     labels: Object.keys(educationLevels),
//     datasets: [
//       {
//         label: 'Number of Candidates by Education Level',
//         data: Object.values(educationLevels),
//         backgroundColor: 'rgba(255,159,64,0.6)',
//         borderColor: 'rgba(255,159,64,1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   if (loading) {
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-green-500">

//         </div>
//         <span className="fixed animate-bounce font-bold text-xs">Yoddah AI Generating...</span>
//       </div>
//     );
//   }

//   return (
//     <div className="container mx-auto p-6">
//       <h2 className="text-2xl font-bold mb-4">Candidate Report</h2>

//       <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//           <div className="bg-white p-6 rounded-lg shadow-lg gradient-shadow">
//             <h3 className="text-lg font-semibold mb-4 text-gray-700">Candidates by Location</h3>
//             <Bar data={locationChartData} options={{ responsive: true }} />
//           </div>
//           <div className="bg-white p-6 rounded-lg shadow-lg gradient-shadow">
//             <h3 className="text-lg font-semibold mb-4 text-gray-700">Candidates by Skills</h3>
//             <Pie className="mt-2" data={skillChartData} options={{ responsive: true }} />
//             </div>
//           <div className="bg-white p-6 rounded-lg shadow-lg gradient-shadow">
//             <h3 className="text-lg font-semibold mb-4 text-gray-700">Candidates by Education Level</h3>
//             <Line data={educationChartData} options={{ responsive: true }} />
//             </div>
//       </div>
//       {/* <div className="mb-8">
//         <h3 className="text-xl font-semibold mb-2">Candidates by Location</h3>
//         <Bar data={locationChartData} options={{ responsive: true }} />
//       </div>

//       <div className="mb-8">
//         <h3 className="text-xl font-semibold mb-2">Candidates by Skills</h3>
//         <Pie data={skillChartData} options={{ responsive: true }} />
//       </div>

//       <div className="mb-8">
//         <h3 className="text-xl font-semibold mb-2">Candidates by Education Level</h3>
//         <Line data={educationChartData} options={{ responsive: true }} />
//       </div> */}
//     </div>
//   );
// };

// export default CandidateReport;

import React, { useEffect, useState } from "react";
import { Bar, Pie, Line, Scatter } from "react-chartjs-2";
import "chart.js/auto";
import axios from "axios";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { toast } from "react-toastify";

const CandidateReport = () => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleClick = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById("report-content");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`Candidates_Report.pdf`); // Specify the name of the downloaded PDF file
      toast.success(`Candidates_Report Generation successful!`);
    });
  };

  useEffect(() => {
    // Fetch candidate data from API
    const fetchCandidates = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_API_URL}/api/candidates`
        ); // Replace with your API endpoint
        setCandidates(response.data);
      } catch (error) {
        console.error("Error fetching candidate data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCandidates();
  }, []);

  const prepareDataForCharts = () => {
    const locations = {};
    const skills = {};
    const educationLevels = {};
    const positions = {};
    const experienceCTC = [];

    candidates.forEach((candidate) => {
      // Group by location
      if (locations[candidate.Current_Location]) {
        locations[candidate.Current_Location] += 1;
      } else {
        locations[candidate.Current_Location] = 1;
      }

      // Group by skills
      candidate.Current_Skills.forEach((skill) => {
        if (skills[skill]) {
          skills[skill] += 1;
        } else {
          skills[skill] = 1;
        }
      });

      // Group by education level
      if (educationLevels[candidate.Education]) {
        educationLevels[candidate.Education] += 1;
      } else {
        educationLevels[candidate.Education] = 1;
      }

      // Group by position
      if (positions[candidate.Position_applied_for]) {
        positions[candidate.Position_applied_for] += 1;
      } else {
        positions[candidate.Position_applied_for] = 1;
      }

      // Experience vs CTC
      experienceCTC.push({
        x: candidate.Rel_Exp,
        y: candidate.Total_CTC,
      });
    });

    return { locations, skills, educationLevels, positions, experienceCTC };
  };

  const { locations, skills, educationLevels, positions, experienceCTC } =
    prepareDataForCharts();

  const locationChartData = {
    labels: Object.keys(locations),
    datasets: [
      {
        label: "Number of Candidates",
        data: Object.values(locations),
        backgroundColor: "rgba(75,192,192,0.6)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const skillChartData = {
    labels: Object.keys(skills),
    datasets: [
      {
        label: "Number of Candidates with Skill",
        data: Object.values(skills),
        backgroundColor: "rgba(153,102,255,0.6)",
        borderColor: "rgba(153,102,255,1)",
        borderWidth: 1,
      },
    ],
  };

  const educationChartData = {
    labels: Object.keys(educationLevels),
    datasets: [
      {
        label: "Number of Candidates by Education Level",
        data: Object.values(educationLevels),
        backgroundColor: "rgba(255,159,64,0.6)",
        borderColor: "rgba(255,159,64,1)",
        borderWidth: 1,
      },
    ],
  };

  const positionChartData = {
    labels: Object.keys(positions),
    datasets: [
      {
        label: "Number of Candidates by Position",
        data: Object.values(positions),
        backgroundColor: "rgba(255,99,132,0.6)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
      },
    ],
  };

  const ctcComparisonData = {
    labels: candidates.map((candidate) => candidate.Candidates_Name),
    datasets: [
      {
        label: "Current CTC",
        data: candidates.map((candidate) => candidate.Current_CTC_fixed),
        backgroundColor: "rgba(75,192,192,0.6)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
      {
        label: "Expected CTC",
        data: candidates.map((candidate) => candidate.Exp_CTC),
        backgroundColor: "rgba(153,102,255,0.6)",
        borderColor: "rgba(153,102,255,1)",
        borderWidth: 1,
      },
    ],
  };

  const experienceCTCChartData = {
    datasets: [
      {
        label: "Experience vs CTC",
        data: experienceCTC,
        backgroundColor: "rgba(255,159,64,0.6)",
        borderColor: "rgba(255,159,64,1)",
        borderWidth: 1,
        pointRadius: 5,
      },
    ],
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-green-500"></div>
        <span className="fixed animate-bounce font-bold text-xs">
          Yoddah AI Generating...
        </span>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          href="/"
          onClick={(event) => handleClick(event, "/dashboard")}
        >
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/"
          onClick={(event) => handleClick(event, "/candidate-table")}
        >
          Candidate Table
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/reports"
          onClick={(event) => handleClick(event, "/candidate-report")}
        >
          Candidate Report
        </Link>
      </Breadcrumbs>
      <button
        onClick={handleDownloadPDF}
        className="mt-4 bg-green-600 text-white"
      >
        Download Report
      </button>
      <div id="report-content">
        <h2 className="text-3xl text-center font-bold mb-4">
          Candidate Report
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">
              Candidates by Location
            </h3>
            <Bar data={locationChartData} options={{ responsive: true }} />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">
              Candidates by Skills
            </h3>
            <Pie data={skillChartData} options={{ responsive: true }} />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">
              Candidates by Education Level
            </h3>
            <Line data={educationChartData} options={{ responsive: true }} />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">
              Candidates by Position
            </h3>
            <Bar data={positionChartData} options={{ responsive: true }} />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">
              CTC Comparison
            </h3>
            <Bar data={ctcComparisonData} options={{ responsive: true }} />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">
              Experience vs CTC
            </h3>
            <Scatter
              data={experienceCTCChartData}
              options={{ responsive: true }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateReport;
