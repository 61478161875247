// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css'; 

// function Candidates() {
//   const [candidates, setCandidates] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [showAddUserModal, setShowAddUserModal] = useState(false);
//   const [candidateData, setCandidateData] = useState({
//     name: '',
//     age: '',
//     experience: '',
//     skills: [],
//     veteran: false,
//     currentCTC: '',
//     expectedCTC: '',
//     education: '',
//     location: '',
//     willingToRelocate: false,
//     maritalStatus: '',
//     gender: '',
//   });
//   const navigate = useNavigate();

//   const handleInputChange = (event) => {
//     const { name, value, type, checked } = event.target;
//     setCandidateData((prevData) => ({
//       ...prevData,
//       [name]: type === 'checkbox' ? checked : value,
//     }));
//   };

//   const handleAddSkill = () => {
//     setCandidateData((prevData) => ({
//       ...prevData,
//       skills: [...prevData.skills, ''], // Add an empty skill field
//     }));
//   };

//   const handleRemoveSkill = (index) => {
//     setCandidateData((prevData) => ({
//       ...prevData,
//       skills: prevData.skills.filter((_, i) => i !== index),
//     }));
//   };

//   const handleAddUser = async (event) => {
//     event.preventDefault();

//     try {
//       const token = sessionStorage.getItem('authToken');
//       if (!token) {
//         navigate('/'); // Redirect to login if no token
//         return;
//       }

//       // Get the recruiter's email (you'll need to implement this logic)
//       const recruiterEmail = 'your_recruiter_email@example.com'; // Replace with the logic to get the recruiter's email from the session

//       const response = await fetch('http://localhost:1000/candidates', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           ...candidateData,
//           recruiterEmail: recruiterEmail, // Add the recruiter's email to the candidate data
//         }),
//       });

//       if (response.ok) {
//         const data = await response.json();
//         toast.success('Candidate added successfully!');
//         fetchCandidates();
//         setShowAddUserModal(false);
//         setCandidateData({
//           name: '',
//           age: '',
//           experience: '',
//           skills: [],
//           veteran: false,
//           currentCTC: '',
//           expectedCTC: '',
//           education: '',
//           location: '',
//           willingToRelocate: false,
//           maritalStatus: '',
//           gender: '',
//         });
//       } else {
//         const errorData = await response.json();
//         toast.error(errorData.message);
//       }
//     } catch (error) {
//       console.error('Error adding candidate:', error);
//       toast.error('Error adding candidate.');
//     }
//   };

//   const fetchCandidates = async () => {
//     try {
//       const token = sessionStorage.getItem('authToken');
//       if (!token) {
//         navigate('/');
//         return;
//       }

//       // Get the recruiter's email (you'll need to implement this logic)
//       const recruiterEmail = 'your_recruiter_email@example.com'; // Replace with the logic to get the recruiter's email from the session

//       const response = await fetch(`http://localhost:1000/candidates/${recruiterEmail}`, {
//         headers: {
//         //   Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setCandidates(data);
//       } else {
//         console.error('Error fetching candidates:', response.status);
//       }
//     } catch (error) {
//       console.error('Error fetching candidates:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchCandidates();
//   }, []);

//   return (
//     <div className="container mx-auto p-4">
//       <h1 className="text-3xl font-bold mb-4">Candidates</h1>

//       <div className="flex justify-end mb-4">
//         <button
//           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//           onClick={() => setShowAddUserModal(true)}
//         >
//           + Add Candidate
//         </button>
//       </div>

//       {/* Add User Modal */}
//       {showAddUserModal && (
//         <div
//           className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50"
//           onClick={() => setShowAddUserModal(false)}
//         >
//           <div
//             className="bg-white p-6 rounded-lg shadow-md w-full max-w-md"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <h2 className="text-2xl font-bold mb-4 ">Add New Candidate</h2>
//             <form onSubmit={handleAddUser}>
//               <div className="mb-4">
//                 <label
//                   htmlFor="name"
//                   className="block text-gray-700 font-bold mb-2"
//                 >
//                   Name
//                 </label>
//                 <input
//                   type="text"
//                   id="name"
//                   name="name"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={candidateData.name}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//               <div className="mb-4">
//                 <label
//                   htmlFor="age"
//                   className="block text-gray-700 font-bold mb-2"
//                 >
//                   Age
//                 </label>
//                 <input
//                   type="number"
//                   id="age"
//                   name="age"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={candidateData.age}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//               <div className="mb-4">
//                 <label
//                   htmlFor="experience"
//                   className="block text-gray-700 font-bold mb-2"
//                 >
//                   Experience (Years)
//                 </label>
//                 <input
//                   type="number"
//                   id="experience"
//                   name="experience"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={candidateData.experience}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//               <div className="mb-4">
//                 <label
//                   htmlFor="skills"
//                   className="block text-gray-700 font-bold mb-2"
//                 >
//                   Skills
//                 </label>
//                 <div className="flex flex-col space-y-2">
//                   {candidateData.skills.map((skill, index) => (
//                     <div key={index} className="flex items-center">
//                       <input
//                         type="text"
//                         name="skills"
//                         className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                         value={skill}
//                         onChange={(e) => {
//                           const newSkills = [...candidateData.skills];
//                           newSkills[index] = e.target.value;
//                           setCandidateData({ ...candidateData, skills: newSkills });
//                         }}
//                         required
//                       />
//                       {candidateData.skills.length > 1 && (
//                         <button
//                           className="ml-2 px-2 py-1 rounded bg-red-500 text-white hover:bg-red-600"
//                           type="button"
//                           onClick={() => handleRemoveSkill(index)}
//                         >
//                           Remove
//                         </button>
//                       )}
//                     </div>
//                   ))}
//                   <button
//                     className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//                     type="button"
//                     onClick={handleAddSkill}
//                   >
//                     Add Skill
//                   </button>
//                 </div>
//               </div>
//               <div className="mb-4">
//                 <label
//                   htmlFor="veteran"
//                   className="block text-gray-700 font-bold mb-2"
//                 >
//                   Veteran
//                 </label>
//                 <input
//                   type="checkbox"
//                   id="veteran"
//                   name="veteran"
//                   className="form-checkbox h-5 w-5 text-gray-900"
//                   checked={candidateData.veteran}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-4">
//                 <label
//                   htmlFor="currentCTC"
//                   className="block text-gray-700 font-bold mb-2"
//                 >
//                   Current CTC (Annual)
//                 </label>
//                 <input
//                   type="number"
//                   id="currentCTC"
//                   name="currentCTC"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={candidateData.currentCTC}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//               <div className="mb-4">
//                 <label
//                   htmlFor="expectedCTC"
//                   className="block text-gray-700 font-bold mb-2"
//                 >
//                   Expected CTC (Annual)
//                 </label>
//                 <input
//                   type="number"
//                   id="expectedCTC"
//                   name="expectedCTC"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={candidateData.expectedCTC}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//               <div className="mb-4">
//                 <label
//                   htmlFor="education"
//                   className="block text-gray-700 font-bold mb-2"
//                 >
//                   Education
//                 </label>
//                 <input
//                   type="text"
//                   id="education"
//                   name="education"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={candidateData.education}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//               <div className="mb-4">
//                 <label
//                   htmlFor="location"
//                   className="block text-gray-700 font-bold mb-2"
//                 >
//                   Location
//                 </label>
//                 <input
//                   type="text"
//                   id="location"
//                   name="location"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={candidateData.location}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//               <div className="mb-4">
//                 <label
//                   htmlFor="willingToRelocate"
//                   className="block text-gray-700 font-bold mb-2"
//                 >
//                   Willing to Relocate
//                 </label>
//                 <input
//                   type="checkbox"
//                   id="willingToRelocate"
//                   name="willingToRelocate"
//                   className="form-checkbox h-5 w-5 text-gray-900"
//                   checked={candidateData.willingToRelocate}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-4">
//                 <label
//                   htmlFor="maritalStatus"
//                   className="block text-gray-700 font-bold mb-2"
//                 >
//                   Marital Status
//                 </label>
//                 <select
//                   id="maritalStatus"
//                   name="maritalStatus"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={candidateData.maritalStatus}
//                   onChange={handleInputChange}
//                   required
//                 >
//                   <option value="">Select Marital Status</option>
//                   <option value="Single">Single</option>
//                   <option value="Married">Married</option>
//                   <option value="Other">Other</option>
//                 </select>
//               </div>
//               <div className="mb-4">
//                 <label
//                   htmlFor="gender"
//                   className="block text-gray-700 font-bold mb-2"
//                 >
//                   Gender
//                 </label>
//                 <select
//                   id="gender"
//                   name="gender"
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={candidateData.gender}
//                   onChange={handleInputChange}
//                   required
//                 >
//                   <option value="">Select Gender</option>
//                   <option value="Male">Male</option>
//                   <option value="Female">Female</option>
//                   <option value="Other">Other</option>
//                 </select>
//               </div>

//               <div className="flex items-center justify-end">
//                 <button
//                   className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded"
//                   type="button"
//                   onClick={() => setShowAddUserModal(false)}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4"
//                   type="submit"
//                 >
//                   Add Candidate
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       )}

//       {/* Candidate Table */}
//       <div className="mt-4">
//         <table className="w-full table-auto">
//           <thead>
//             <tr>
//               <th
//                 scope="col"
//                 className="px-4 py-3 text-left"
//               >
//                 Name
//               </th>
//               <th
//                 scope="col"
//                 className="px-4 py-3 text-left"
//               >
//                 Age
//               </th>
//               <th
//                 scope="col"
//                 className="px-4 py-3 text-left"
//               >
//                 Experience (Years)
//               </th>
//               <th
//                 scope="col"
//                 className="px-4 py-3 text-left"
//               >
//                 Skills
//               </th>
//               <th
//                 scope="col"
//                 className="px-4 py-3 text-left"
//               >
//                 Current CTC
//               </th>
//               <th
//                 scope="col"
//                 className="px-4 py-3 text-left"
//               >
//                 Expected CTC
//               </th>
//               <th
//                 scope="col"
//                 className="px-4 py-3 text-left"
//               >
//                 Education
//               </th>
//               <th
//                 scope="col"
//                 className="px-4 py-3 text-left"
//               >
//                 Location
//               </th>
//               <th
//                 scope="col"
//                 className="px-4 py-3 text-left"
//               >
//                 Willing to Relocate
//               </th>
//               <th
//                 scope="col"
//                 className="px-4 py-3 text-left"
//               >
//                 Marital Status
//               </th>
//               <th
//                 scope="col"
//                 className="px-4 py-3 text-left"
//               >
//                 Gender
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {candidates.length === 0 && isLoading === false ? (
//               <tr>
//                 <td colSpan={11} className="text-center py-4 text-gray-500">
//                   No Candidates Found yet
//                 </td>
//               </tr>
//             ) : (
//               candidates.map((candidate, index) => (
//                 <tr key={index} className="border-b hover:bg-gray-100">
//                   <td className="px-4 py-2">
//                     {candidate.name}
//                   </td>
//                   <td className="px-4 py-2">
//                     {candidate.age}
//                   </td>
//                   <td className="px-4 py-2">
//                     {candidate.experience}
//                   </td>
//                   <td className="px-4 py-2">
//                     {candidate.skills.join(', ')}
//                   </td>
//                   <td className="px-4 py-2">
//                     {candidate.currentCTC}
//                   </td>
//                   <td className="px-4 py-2">
//                     {candidate.expectedCTC}
//                   </td>
//                   <td className="px-4 py-2">
//                     {candidate.education}
//                   </td>
//                   <td className="px-4 py-2">
//                     {candidate.location}
//                   </td>
//                   <td className="px-4 py-2">
//                     {candidate.willingToRelocate ? 'Yes' : 'No'}
//                   </td>
//                   <td className="px-4 py-2">
//                     {candidate.maritalStatus}
//                   </td>
//                   <td className="px-4 py-2">
//                     {candidate.gender}
//                   </td>
//                 </tr>
//               ))
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default Candidates;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

function Candidates() {
  const [candidates, setCandidates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [candidateData, setCandidateData] = useState({
    name: '',
    age: '',
    experience: '',
    skills: [],
    veteran: false,
    currentCTC: '',
    expectedCTC: '',
    education: '',
    location: '',
    willingToRelocate: false,
    maritalStatus: '',
    gender: '',
  });
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setCandidateData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleAddSkill = () => {
    setCandidateData((prevData) => ({
      ...prevData,
      skills: [...prevData.skills, ''], // Add an empty skill field
    }));
  };

  const handleRemoveSkill = (index) => {
    setCandidateData((prevData) => ({
      ...prevData,
      skills: prevData.skills.filter((_, i) => i !== index),
    }));
  };

  const handleAddUser = async (event) => {
    event.preventDefault();

    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        navigate('/'); // Redirect to login if no token
        return;
      }

      // Get the recruiter's email (you'll need to implement this logic)
      const recruiterEmail1 = 'your_recruiter_email@example.com'; // Replace with the logic to get the recruiter's email from the session
      const recruiterEmail = sessionStorage.getItem('email');// Replace with the logic to get the recruiter's email from the session

      const response = await fetch(`${process.env.REACT_APP_SERVER_API_URL}/candidates`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...candidateData,
          recruiterEmail: recruiterEmail, // Add the recruiter's email to the candidate data
        }),
      });

      if (response.ok) {
        const data = await response.json();
        toast.success('Candidate added successfully!');
        fetchCandidates();
        setShowAddUserModal(false);
        setCandidateData({
          name: '',
          age: '',
          experience: '',
          skills: [],
          veteran: false,
          currentCTC: '',
          expectedCTC: '',
          education: '',
          location: '',
          willingToRelocate: false,
          maritalStatus: '',
          gender: '',
        });
      } else {
        const errorData = await response.json();
        toast.error(errorData.message);
      }
    } catch (error) {
      console.error('Error adding candidate:', error);
      toast.error('Error adding candidate.');
    }
  };

  const fetchCandidates = async () => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        navigate('/');
        return;
      }

      // Get the recruiter's email (you'll need to implement this logic)
      const recruiterEmail1 = 'your_recruiter_email@example.com'; // Replace with the logic to get the recruiter's email from the session
      const recruiterEmail = sessionStorage.getItem('username');// Replace with the logic to get the recruiter's email from the session

      const response = await fetch(`${process.env.REACT_APP_SERVER_API_URL}/candidates/${recruiterEmail}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCandidates(data);
      } else {
        console.error('Error fetching candidates:', response.status);
      }
    } catch (error) {
      console.error('Error fetching candidates:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCandidates();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Candidates</h1>

      <div className="flex justify-end mb-4">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => setShowAddUserModal(true)}
        >
          + Add Candidate
        </button>
      </div>

      {/* Add User Modal */}
      {showAddUserModal && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50"
          onClick={() => setShowAddUserModal(false)}
        >
          <div
            className="bg-white p-6 rounded-lg shadow-md h-15 w-full max-w-md"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-2xl font-bold mb-4 ">Add New Candidate</h2>
            {/* <form onSubmit={handleAddUser}>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={candidateData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="age"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Age
                </label>
                <input
                  type="number"
                  id="age"
                  name="age"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={candidateData.age}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="experience"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Experience (Years)
                </label>
                <input
                  type="number"
                  id="experience"
                  name="experience"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={candidateData.experience}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="skills"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Skills
                </label>
                <div className="flex flex-col space-y-2">
                  {candidateData.skills.map((skill, index) => (
                    <div key={index} className="flex items-center">
                      <input
                        type="text"
                        name="skills"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        value={skill}
                        onChange={(e) => {
                          const newSkills = [...candidateData.skills];
                          newSkills[index] = e.target.value;
                          setCandidateData({ ...candidateData, skills: newSkills });
                        }}
                        required
                      />
                      {candidateData.skills.length > 1 && (
                        <button
                          className="ml-2 px-2 py-1 rounded bg-red-500 text-white hover:bg-red-600"
                          type="button"
                          onClick={() => handleRemoveSkill(index)}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  ))}
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={handleAddSkill}
                  >
                    Add Skill
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="veteran"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Veteran
                </label>
                <input
                  type="checkbox"
                  id="veteran"
                  name="veteran"
                  className="form-checkbox h-5 w-5 text-gray-900"
                  checked={candidateData.veteran}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="currentCTC"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Current CTC (Annual)
                </label>
                <input
                  type="number"
                  id="currentCTC"
                  name="currentCTC"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={candidateData.currentCTC}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="expectedCTC"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Expected CTC (Annual)
                </label>
                <input
                  type="number"
                  id="expectedCTC"
                  name="expectedCTC"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={candidateData.expectedCTC}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="education"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Education
                </label>
                <input
                  type="text"
                  id="education"
                  name="education"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={candidateData.education}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="location"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Location
                </label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={candidateData.location}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="willingToRelocate"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Willing to Relocate
                </label>
                <input
                  type="checkbox"
                  id="willingToRelocate"
                  name="willingToRelocate"
                  className="form-checkbox h-5 w-5 text-gray-900"
                  checked={candidateData.willingToRelocate}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="maritalStatus"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Marital Status
                </label>
                <select
                  id="maritalStatus"
                  name="maritalStatus"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={candidateData.maritalStatus}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Marital Status</option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="gender"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Gender
                </label>
                <select
                  id="gender"
                  name="gender"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={candidateData.gender}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="flex items-center justify-end">
                <button
                  className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded"
                  type="button"
                  onClick={() => setShowAddUserModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4"
                  type="submit"
                >
                  Add Candidate
                </button>
              </div>
            </form> */}
            <form onSubmit={handleAddUser}>
              <div className="grid grid-cols-3 md:grid-cols-2 gap-1"> {/* Use a grid layout */}
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={candidateData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="age"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Age
                  </label>
                  <input
                    type="number"
                    id="age"
                    name="age"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={candidateData.age}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="experience"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Experience (Years)
                  </label>
                  <input
                    type="number"
                    id="experience"
                    name="experience"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={candidateData.experience}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="skills"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Skills
                  </label>
                  <div className="flex flex-col space-y-2">
                    {candidateData.skills.map((skill, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          type="text"
                          name="skills"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={skill}
                          onChange={(e) => {
                            const newSkills = [...candidateData.skills];
                            newSkills[index] = e.target.value;
                            setCandidateData({ ...candidateData, skills: newSkills });
                          }}
                          required
                        />
                        {candidateData.skills.length > 1 && (
                          <button
                            className="ml-2 px-2 py-1 rounded bg-red-500 text-white hover:bg-red-600"
                            type="button"
                            onClick={() => handleRemoveSkill(index)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      type="button"
                      onClick={handleAddSkill}
                    >
                      Add Skill
                    </button>
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="veteran"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Veteran
                  </label>
                  <input
                    type="checkbox"
                    id="veteran"
                    name="veteran"
                    className="form-checkbox h-5 w-5 text-gray-900"
                    checked={candidateData.veteran}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="currentCTC"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Current CTC (Annual)
                  </label>
                  <input
                    type="number"
                    id="currentCTC"
                    name="currentCTC"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={candidateData.currentCTC}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="expectedCTC"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Expected CTC (Annual)
                  </label>
                  <input
                    type="number"
                    id="expectedCTC"
                    name="expectedCTC"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={candidateData.expectedCTC}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="education"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Education
                  </label>
                  <input
                    type="text"
                    id="education"
                    name="education"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={candidateData.education}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="location"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Location
                  </label>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={candidateData.location}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="willingToRelocate"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Willing to Relocate
                  </label>
                  <input
                    type="checkbox"
                    id="willingToRelocate"
                    name="willingToRelocate"
                    className="form-checkbox h-5 w-5 text-gray-900"
                    checked={candidateData.willingToRelocate}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="maritalStatus"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Marital Status
                  </label>
                  <select
                    id="maritalStatus"
                    name="maritalStatus"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={candidateData.maritalStatus}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Marital Status</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="gender"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Gender
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={candidateData.gender}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className="flex items-center justify-end">
                <button
                  className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded"
                  type="button"
                  onClick={() => setShowAddUserModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4"
                  type="submit"
                >
                  Add Candidate
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Candidate Table */}
      <div className="mt-4">
        <table className="w-full table-auto">
          <thead className="bg-slate-300">
            <tr>
              <th
                scope="col"
                className="px-4 py-3 text-left"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left"
              >
                Age
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left"
              >
                Experience (Years)
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left"
              >
                Skills
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left"
              >
                Current CTC
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left"
              >
                Expected CTC
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left"
              >
                Education
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left"
              >
                Location
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left"
              >
                Willing to Relocate
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left"
              >
                Marital Status
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left"
              >
                Gender
              </th>
            </tr>
          </thead>
          <tbody>
            {candidates.length === 0 && isLoading === false ? (
              <tr>
                <td colSpan={11} className="text-center py-4 text-gray-500">
                  No Candidates Found yet
                </td>
              </tr>
            ) : (
              candidates.map((candidate, index) => (
                <tr key={index} className="border-b hover:bg-gray-100">
                  <td className="px-4 py-2">
                    {candidate.name}
                  </td>
                  <td className="px-4 py-2">
                    {candidate.age}
                  </td>
                  <td className="px-4 py-2">
                    {candidate.experience}
                  </td>
                  <td className="px-4 py-2">
                    {candidate.skills.join(', ')}
                  </td>
                  <td className="px-4 py-2">
                    {candidate.currentCTC}
                  </td>
                  <td className="px-4 py-2">
                    {candidate.expectedCTC}
                  </td>
                  <td className="px-4 py-2">
                    {candidate.education}
                  </td>
                  <td className="px-4 py-2">
                    {candidate.location}
                  </td>
                  <td className="px-4 py-2">
                    {candidate.willingToRelocate ? 'Yes' : 'No'}
                  </td>
                  <td className="px-4 py-2">
                    {candidate.maritalStatus}
                  </td>
                  <td className="px-4 py-2">
                    {candidate.gender}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Candidates;
