import React, { useEffect, useState, useRef } from 'react';
import PersonalDetail from './PersonalDetail'
import { Button } from './ui/button'
import { ArrowLeft, ArrowRight, Home, LayoutGrid } from 'lucide-react'
import Summary from './Summary';
import Experience from './Experience';
import Education from './Education';
import Skills from './Skills';
import { Link, Navigate, useParams } from 'react-router-dom';
// import ThemeColor from './ThemeColor';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ResumeInfoContext } from './ResumeInfoContext';
import {toast} from 'react-toastify';


function FormSection() {
  const [activeFormIndex,setActiveFormIndex]=useState(1);
  const [enableNext,setEnableNext]=useState(true);
  const {resumeId}=useParams();
  const [resumeInfo, setResumeInfo] = useState();
  const resumeRef = useRef(); // Reference to the ResumePreview section

  const handleDownloadPDF = () => {
    const input = document.getElementById('resume-content');

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${resumeInfo.firstName}Resume.pdf`); // Specify the name of the downloaded PDF file
      toast.success(`${resumeInfo.firstName}'s Resume Generation successful!`);
    });
  };

  const downloadResumeAsPDF = () => {
    const input = resumeRef.current;
    html2canvas(input).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
  
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
  
        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }
        pdf.save(`${resumeInfo.firstName}_${resumeInfo.lastName}_Resume.pdf`);
    });
  };

  return (
    <div>
        <div className='flex justify-between items-center'>
          <div className='flex gap-5'>
          {/* {activeFormIndex != 6 ?
          (
            <Button onClick={downloadResumeAsPDF} className="mt-4 bg-green-600 text-white">
                    Download Resume as PDF
                </Button>
          ):null} */}
          {/* <ThemeColor/> */}
         
          </div>
          <div className='flex gap-2'>
            {activeFormIndex>1&&
              <Button size="sm"  
                      className="bg-green-400 hover:bg-green-600 active:animate-ping"
                      onClick={()=>setActiveFormIndex(activeFormIndex-1)}> 
                <ArrowLeft/> 
              </Button> 
            }
            {activeFormIndex<6 &&
              <Button 
              // disabled={!enableNext}
                className="flex gap-2 bg-green-400 hover:bg-green-600 active:animate-ping" size="sm"
                onClick={()=>setActiveFormIndex(activeFormIndex+1)}
                > Next 
                <ArrowRight/> 
              </Button>
            }
          </div>
        </div>
          {activeFormIndex==1?  
          <PersonalDetail enabledNext={(v)=>setEnableNext(v)} />
          :activeFormIndex==2?
          <Summary  enabledNext={(v)=>setEnableNext(v)} />
          :activeFormIndex==3?
          <Experience />  
          :activeFormIndex==4?
          <Education/>
          :activeFormIndex==5?
          <Skills/>
          :activeFormIndex==6?
          (<div >
            <Button onClick={downloadResumeAsPDF} className="mt-4 bg-green-600 hover:bg-green-400 text-white items-center">
              Download Resume as PDF
            </Button>
          </div>     
          )         
        :null

        
          }
        

    </div>
  )
}

export default FormSection